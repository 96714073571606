import { useState } from 'react';
import { Copy, ExternalLink } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { cn } from '@/lib/utils';

export const CopyableField = ({
  text,
  title,
  className,
  formatText,
}: {
  text: string;
  title: string;
  className?: string;
  formatText?: (text: string) => string;
}) => {
  const [copied, setCopied] = useState(false);

  const getIsValidUrl = () => {
    try {
      new URL(text);
      return true;
    } catch {
      return false;
    }
  };
  const isValidUrl = getIsValidUrl();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleOpenLink = () => {
    window.open(text, '_blank');
  };

  return (
    <div className={cn('relative w-full', className)}>
      {title && (
        <div className="mb-1 font-medium text-muted-foreground">{title}</div>
      )}
      <div className="flex w-full items-center rounded-lg border border-gray-200 bg-gray-50">
        <div
          className={cn(
            'flex-1 overflow-x-hidden text-ellipsis whitespace-nowrap p-2 pr-10 text-sm',
            isValidUrl && 'pr-20'
          )}
        >
          {formatText ? formatText(text) : text}
        </div>
        <div className="absolute right-0 flex h-full items-center gap-1 pr-2">
          {isValidUrl && (
            <button
              onClick={handleOpenLink}
              className="rounded-md p-2 transition-colors hover:bg-gray-200"
              title="Open link"
            >
              <ExternalLink className="h-4 w-4 text-gray-600" />
            </button>
          )}
          <Tooltip open={copied}>
            <TooltipTrigger asChild>
              <button
                onClick={handleCopy}
                className="rounded-md p-2 transition-colors hover:bg-gray-200"
                title={copied ? 'Copied!' : 'Copy'}
              >
                <Copy className="h-4 w-4 text-gray-600" />
              </button>
            </TooltipTrigger>
            <TooltipContent>Copied!</TooltipContent>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
