import { FormMessage } from '@/components/ui/form';
import {
  SMS_CHARACTER_LIMIT_ERROR_MESSAGE,
  SMS_CHARACTER_PREVENT_INPUT_ERROR_MESSAGE,
  SMS_CHARACTER_PREVENT_INPUT_LIMIT,
  SMS_CHARACTER_WARNING_LIMIT,
} from './constants';

const SMSCharacterControlFields = ({
  characterCount,
}: {
  characterCount: number;
}) => {
  return (
    <div className="flex justify-between">
      {characterCount === SMS_CHARACTER_PREVENT_INPUT_LIMIT ? (
        <p className="text-xs text-destructive">
          {SMS_CHARACTER_PREVENT_INPUT_ERROR_MESSAGE}
        </p>
      ) : characterCount >= SMS_CHARACTER_WARNING_LIMIT ? (
        <p className="text-xs">{SMS_CHARACTER_LIMIT_ERROR_MESSAGE}</p>
      ) : (
        <FormMessage />
      )}
    </div>
  );
};

export default SMSCharacterControlFields;
