import { ChevronLeft, Mic, Plus } from 'lucide-react';
import PhoneContainer from '@/assets/svg/phone.svg?react';
import VetonLogo from '@/assets/svg/logo-small.svg?react';

const SMSPreview = ({ message }: { message: string }) => {
  return (
    <div className="flex w-full justify-center">
      <div className="relative h-[500px] w-[237px] overflow-hidden">
        <PhoneContainer className="absolute inset-0 z-20 h-full w-full" />
        <div className="absolute bottom-4 left-2 right-2 top-0 z-10 flex flex-col bg-white">
          <span
            className="absolute left-6 top-[13px] z-20 font-semibold"
            style={{
              fontSize: 9,
            }}
          >
            12:20
          </span>
          <div className="relative flex items-center rounded-t-3xl border-b border-gray-200 bg-gray-50 px-4 py-2 pt-9">
            <div className="absolute left-2 top-1/2 flex -translate-y-1/2">
              <ChevronLeft className="size-5 text-blue-500" />
            </div>
            <div className="flex flex-1 items-center justify-center">
              <div className="flex flex-col items-center">
                <VetonLogo className="size-6" />
                <p
                  className="mt-1"
                  style={{
                    fontSize: 8,
                  }}
                >
                  veton.ai
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 space-y-2 px-3">
            <div className="text-center">
              <span
                className="text-gray-500"
                style={{
                  fontSize: 8,
                }}
              >
                Today 9:41 AM
              </span>
            </div>

            {message.length > 0 && (
              <div className="flex max-w-[80%] items-end gap-2 rounded-lg bg-gray-200 px-2 py-2">
                <p
                  className="w-full whitespace-pre-wrap break-words text-black"
                  style={{
                    fontSize: 10,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 px-3 pb-2">
            <span className="flex size-5 items-center justify-center rounded-full bg-gray-200">
              <Plus className="size-3 text-gray-500" strokeWidth={2.5} />
            </span>
            <div className="relative flex-1 rounded-full border px-2 py-1">
              <p
                className="text-gray-300"
                style={{
                  fontSize: 10,
                }}
              >
                Text Message - SMS
              </p>
              <Mic className="absolute right-2 top-1/2 h-3 w-3 -translate-y-1/2 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSPreview;
