

import { Button, ButtonProps } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,

  DialogTrigger,
} from "@/components/ui/dialog"

import { useState } from "react"

import { ATSItems } from "./ATSItems";


type TProps = {
  variant: ButtonProps["variant"]
}
export function ConnectionDialog({ variant }: TProps) {
  const [open, setOpen] = useState(false);


  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {/* <Button variant="outline">Edit Profile</Button> */}
        <Button
          variant={variant}
          className='mt-4'
          isLoading={false}
          // variant={"outline"}
          onClick={() => {
            console.log("connect trigger")
          }}
        >
          {/* {selectedOrganization?.integrationName ? `Disconnect ${selectedOrganization?.integrationName || "ATS"}` : "Connect to your ATS"} */}
          Connect to your ATS

        </Button>
      </DialogTrigger>
      <DialogContent className="">
        <ATSItems />
      </DialogContent>
    </Dialog>
  )
}



