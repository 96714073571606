import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { AlertTriangle, CheckCircle, Info } from 'lucide-react';
import { CheatDetection } from '@/fetchers/useApplication';
import { cheatDetectionVerdict } from './constants';
import { BetaBadge } from '@/components/ui/beta-badge';
// import { analysisData } from './cheat_detection_mock';

type TProps = {
  analysisData: CheatDetection & { candidateName?: string; position?: string };
}
const AIDetectionDashboard = ({ analysisData }: TProps) => {
  // Helper function to get icon based on rating
  const getRatingIcon = (rating: string) => {
    switch (rating) {
      case 'Not Likely':
        return <CheckCircle className="h-4 w-4 text-green-600" />;
      case 'Likely':
        return <Info className="h-4 w-4 text-yellow-600" />;
      case 'Highly Likely':
        return <AlertTriangle className="h-4 w-4 text-red-600" />;
      default:
        return <Info className="h-4 w-4" />;
    }
  };

  // Helper function to get badge variant based on rating
  const getRatingVariant = (rating: string) => {
    switch (rating) {
      case 'Not Likely':
        return 'outline';
      case 'Likely':
        return 'secondary';
      case 'Highly Likely':
        return 'destructive';
      default:
        return 'secondary';
    }
  };

  return (
    <div className="mx-auto max-w-3xl space-y-4 p-4">


      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-xl font-bold">{analysisData.candidateName}</h1>
          <p className="text-sm text-gray-500">{analysisData.position}</p>
        </div>
        <div className='flex items-center gap-2'>
          <BetaBadge className='' />
          <Badge variant={getRatingVariant(analysisData.overallRating)}>
            {getRatingIcon(analysisData.overallRating)}
            <span className="ml-1">
              AI Generated: {cheatDetectionVerdict[analysisData.overallRating]}
            </span>
          </Badge>
        </div>
      </div>

      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-sm font-medium text-gray-500">
            Verdict
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p>{analysisData.summary}</p>
        </CardContent>
      </Card>

      <Tabs defaultValue="categories">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="categories">Response Analysis</TabsTrigger>
          <TabsTrigger value="patterns">Pattern Detection</TabsTrigger>
        </TabsList>

        <TabsContent value="categories" className="mt-4 space-y-4">
          {/* @ts-ignore */}
          {analysisData.categories.map((category, idx) => (
            <Card key={idx}>
              <CardHeader className="flex flex-row items-center justify-between pb-2">
                <CardTitle className="text-md">{category.name}</CardTitle>
                <Badge variant={getRatingVariant(category.rating)}>
                  {category.rating}
                </Badge>
              </CardHeader>
              <CardContent className="space-y-3">
                {/* @ts-ignore */}
                {category.examples.map((example, i) => (
                  <div key={i} className="space-y-2">
                    <p className="text-sm font-medium">{example.question}</p>
                    <div className="rounded-md bg-gray-50 p-3 text-sm">
                      {example.response}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {/* @ts-ignore */}
                      {example.flags.map((flag, fi) => (
                        <Badge key={fi} variant="outline" className="text-xs">
                          {flag}
                        </Badge>
                      ))}
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
          ))}
        </TabsContent>

        <TabsContent value="patterns" className="mt-4">
          <Card>
            <CardHeader className="pb-2">
              <CardTitle className="text-md">AI Pattern Analysis</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {/* @ts-ignore */}
                {analysisData.patternAnalysis.map((pattern, idx) => (
                  <div
                    key={idx}
                    className="border-b pb-3 last:border-b-0 last:pb-0"
                  >
                    <div className="flex justify-between">
                      <p className="font-medium">{pattern.pattern}</p>
                      <Badge
                        variant={
                          pattern.sentiment === 'Positive'
                            ? 'success' // Green for positive
                            : pattern.sentiment === 'Negative'
                              ? 'destructive' // Red for negative
                              : 'secondary' // Gray for neutral
                        }
                        className="self-center"
                      >
                        {pattern.frequency}
                      </Badge>
                    </div>
                    <ul className="mt-2 text-sm text-gray-600">
                      {/* @ts-ignore */}
                      {pattern.examples.map((example, i) => (
                        <li key={i} className="ml-4 list-disc">
                          {example}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AIDetectionDashboard;
