import { CreateParentOrganizationForm } from '@/components/core/parent-organization/create-parent-organization-form'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/admin-panel/create-organization')({
  component: () => <AdminCreateOrganizationPage />
})

function AdminCreateOrganizationPage() {
  // const { organizationId } = Route.useParams()



  return (
    <div>
      <CreateParentOrganizationForm />
    </div>
  )
}