import { useState, useEffect } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  UserHiringPosition,
  useUserHiringPositions,
  useUpdateUserHiringPositions
} from '@/fetchers/useUserHiringPositions';
import { Loader } from '@/components/core/loader';
// import { FormDescription } from '@/components/ui/form';
import { toast } from 'sonner';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useQueryClient } from '@tanstack/react-query';
import StickyActionBar from '../sticky-action-bar';

export function OrganizationPositionNotifications({
  organizationId
}: {
  organizationId: string
}) {
  const { data: hiringPositions, isLoading, refetch } = useUserHiringPositions(organizationId);
  const [selectedPositions, setSelectedPositions] = useState<Record<number, boolean>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const updatePositions = useUpdateUserHiringPositions();
  const queryClient = useQueryClient();

  // Initialize selected positions from the data
  useEffect(() => {
    if (hiringPositions) {
      const initialState: Record<number, boolean> = {};
      hiringPositions.forEach(position => {
        initialState[position.id] = position.enabled;
      });
      setSelectedPositions(initialState);
      setIsDirty(false);
    }
  }, [hiringPositions]);

  const handleTogglePosition = (position: UserHiringPosition) => {
    setSelectedPositions(prev => {
      const newState = {
        ...prev,
        [position.id]: !prev[position.id]
      };

      // Check if any position state differs from its initial state
      const hasChanges = hiringPositions?.some(p =>
        newState[p.id] !== p.enabled
      ) || false;

      setIsDirty(hasChanges);
      return newState;
    });
  };

  const handleSelectAll = () => {
    if (!hiringPositions) return;

    const allSelected: Record<number, boolean> = {};
    hiringPositions.forEach(position => {
      allSelected[position.id] = true;
    });

    setSelectedPositions(allSelected);

    // Check if this is a change from the initial state
    const hasChanges = hiringPositions.some(p => !p.enabled);
    setIsDirty(hasChanges);
  };

  const handleDeselectAll = () => {
    if (!hiringPositions) return;

    const allDeselected: Record<number, boolean> = {};
    hiringPositions.forEach(position => {
      allDeselected[position.id] = false;
    });

    setSelectedPositions(allDeselected);

    // Check if this is a change from the initial state
    const hasChanges = hiringPositions.some(p => p.enabled);
    setIsDirty(hasChanges);
  };

  const handleSaveChanges = async () => {
    if (!hiringPositions) return;
    setIsSaving(true);

    try {
      // Create an array of position actions
      const positionActions = hiringPositions.map(position => {
        const isCurrentlySelected = selectedPositions[position.id] || false;
        const wasInitiallySelected = position.enabled;

        // Only include positions where the state has changed
        if (isCurrentlySelected !== wasInitiallySelected) {
          return {
            positionId: position.id,
            action: isCurrentlySelected ? 'add' : 'remove'
          };
        }
        return null;
      }).filter(Boolean); // Remove null values

      // Only make the API call if there are changes
      if (positionActions.length > 0) {
        // @ts-ignore
        const { error } = await updatePositions(organizationId, positionActions);

        if (error) {
          toast.error(`Failed to update positions: ${error}`);
          return;
        }

        toast.success('Position notifications updated successfully');
        // Invalidate the query to refresh the data
        queryClient.invalidateQueries({ queryKey: ['user-hiring-positions', organizationId] });
        refetch();
        setIsDirty(false);
      } else {
        toast.info('No changes to save');
      }
    } catch (error) {
      toast.error('An error occurred while updating positions');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-screen">
        <Loader variant='transparent' />
      </div>
    );
  }

  if (!hiringPositions || hiringPositions.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <p className="text-sm text-muted-foreground">
            There are no positions available in this organization.
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6 pb-20">
      <Card>
        <CardHeader className="pb-3">
          <div className="flex items-center justify-between">
            <CardTitle>Position Notifications</CardTitle>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={handleSelectAll}
                type="button"
                disabled={isSaving}
              >
                Select All
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={handleDeselectAll}
                type="button"
                disabled={isSaving}
              >
                Deselect All
              </Button>
            </div>
          </div>
          {/* <FormDescription>
            Choose which positions you want to receive notifications for as a hiring manager.
          </FormDescription> */}
        </CardHeader>
        <CardContent>
          <div className="rounded-md border max-h-[65vh] overflow-y-auto">
            {hiringPositions.map((position, index) => (
              <div key={position.id}>
                <div className="flex items-center justify-between p-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={`position-${position.id}`}
                      checked={selectedPositions[position.id] || false}
                      onCheckedChange={() => handleTogglePosition(position)}
                      disabled={isSaving}
                    />
                    <label
                      htmlFor={`position-${position.id}`}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {position.title}
                    </label>
                  </div>
                </div>
                {index < hiringPositions.length - 1 && <Separator />}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <StickyActionBar
        isSaveDisabled={!isDirty}
        onSave={handleSaveChanges}
        isSaveLoading={isSaving}
      />
    </div>
  );
} 