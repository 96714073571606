/* eslint-disable @typescript-eslint/no-explicit-any */
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export function useExport() {
  const exportToExcel = (data: any, fileName: string) => {
    // Use string for fileName
    // Define column order
    const columnOrder = [
      'Application ID',
      'Interview ID',
      'Name',
      'Email',
      'Phone',
      'Applied Date',
      'Interview Sent Date',
      'Last Reminder Sent Date',
      'Interview Completed Date',
      'Resume Analysis Verdict',
      'Interview Verdict',
      'Interview Started At',
      'Interview Completed At',
      'Interview Analysis',
      'Resume Analysis',
      'Candidate Details URL',
      'Archived',
    ];

    // Set column widths
    const columnWidths = {
      'Application ID': 15,
      'Interview ID': 15,
      Name: 20,
      Email: 25,
      Phone: 15,
      'Applied Date': 20,
      'Resume Analysis Verdict': 30,
      'Interview Verdict': 30,

      'Interview Sent Date': 30,
      'Last Reminder Sent Date': 30,
      'Interview Started At': 30,
      'Interview Completed Date': 30,

      'Interview Analysis': 35,
      'Resume Analysis': 35,
      'Candidate Details URL': 30,
      Archived: 10,
    };

    // Reorder data according to columnOrder
    const sortedData = data.map((row: any) => {
      const newRow: { [key: string]: any } = {}; // Add index signature
      columnOrder.forEach((key) => {
        newRow[key] = row[key] ?? ''; // Use nullish coalescing for undefined/null
      });
      return newRow;
    });

    // Create worksheet using json_to_sheet with header option
    // Note: json_to_sheet uses the keys of the first object if header is not specified.
    // Since sortedData objects have keys in columnOrder, this works.
    // Or explicitly pass header: columnOrder
    const worksheet = XLSX.utils.json_to_sheet(sortedData, {
      header: columnOrder,
    });

    // Apply column widths
    worksheet['!cols'] = columnOrder.map((header) => ({
      // @ts-ignore
      wch: columnWidths[header] || 20, // Default width if not specified
    }));

    // Apply styles to headers and data rows
    // @ts-ignore
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    worksheet['!rows'] = worksheet['!rows'] || []; // Ensure !rows exists

    // Style Header Row (R=0)
    if (range.e.r >= 0) {
      // Check if there's at least a header row
      worksheet['!rows'][0] = { hpt: 25 }; // Header row height
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: C });
        const cell = worksheet[cellRef];
        if (!cell) continue;
        cell.s = {
          font: { bold: true },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true,
          }, // Center headers
        };
      }
    }

    // Style Data Rows (R > 0)
    for (let R = 1; R <= range.e.r; ++R) {
      // Start from row 1 for data
      worksheet['!rows'][R] = { hpt: 30 }; // Minimum row height for data
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellRef];
        if (!cell) continue; // Skip empty cells

        // Initialize style object if it doesn't exist
        if (!cell.s) cell.s = {};

        // Apply wrap text and top alignment to all data cells
        // This is the crucial part for handling long text
        cell.s.alignment = {
          vertical: 'top',
          wrapText: true, // <--- Instructs Excel to wrap text
        };

        // Add other data cell styles if needed (e.g., specific formats for dates)
      }
    }

    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate and save file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true, // MUST be true for styles to be saved
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return { exportToExcel };
}
