import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { PositionApplicationForm } from '@/components/core/position/application/application-form';
import { useIsMutating } from '@tanstack/react-query';
import {
  PositionApplicationFormValues,
  usePositionApplicationForm,
} from '@/lib/usePositionApplicationForm';
import {
  useGetOrganizationApplicationFormConfig,
  useUpdateOrganizationApplicationFormConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';
import StickyActionBar from '@/components/core/sticky-action-bar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { TApplicationForm } from '@/fetchers/usePosition';
import { usePositionDefaultsApplyChangesModal, ApplyChangesType } from '@/hooks/usePositionDefaultsApplyChangesModal';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/application',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsApplicationPage,
});

function CompanyPositionDefaultsApplicationPage() {
  const { organizationId } = Route.useParams();
  const isMutating = useIsMutating();
  const form = usePositionApplicationForm();
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const { data: organizationApplicationFormConfig } =
    useGetOrganizationApplicationFormConfig({
      organizationId,
    });
  const updateOrganizationApplicationFormConfig =
    useUpdateOrganizationApplicationFormConfigMutation();

  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (organizationApplicationFormConfig) {
      reset({
        ...organizationApplicationFormConfig,
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationApplicationFormConfig]);

  const handleFormSubmit = async (data: PositionApplicationFormValues, applyType: ApplyChangesType) => {
    const { error, data: updatedForm } =
      await updateOrganizationApplicationFormConfig({
        data: {
          ...(data as Partial<TApplicationForm>),
          applyToAllPositions: applyType === ApplyChangesType.ALL_POSITIONS,
        },
        organizationId,
      });
      
    if (error) {
      toast.error('Error updating application form');
      return;
    }
    
    const successMessage = applyType === ApplyChangesType.ALL_POSITIONS
      ? 'Application form updated successfully for all positions!'
      : 'Application form updated successfully for future positions!';
      
    toast.success(successMessage);

    if (updatedForm) {
      reset({
        ...updatedForm,
        isInitialized: true,
      });
    }
  };

  const onSubmit = async (data: PositionApplicationFormValues) => {
    await handleFormSubmit(data, ApplyChangesType.FUTURE_ONLY);
  };

  const { ApplyChangesModal, handleSaveClick } = usePositionDefaultsApplyChangesModal({
    onConfirm: async (applyType) => {
      const data = form.getValues();
      await handleFormSubmit(data, applyType);
    },
    isDirty,
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      {ApplyChangesModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
          <PositionApplicationForm />
          {isFormInitialized && (
            <StickyActionBar
              isSaveLoading={isMutating > 0}
              isSaveDisabled={isMutating > 0 || !isDirty}
              onSave={handleSaveClick}
            />
          )}
        </form>
      </Form>
    </>
  );
}
