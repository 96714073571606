import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { useIsMutating } from '@tanstack/react-query';
import {
  handleMapFormValuesToData,
  PositionCommunicationFormValues,
  usePositionCommunicationForm,
} from '@/lib/usePositionCommunicationForm';
import { PositionCommunicationForm } from '@/components/core/position/communication/communication-form';
import {
  useGetOrganizationCommunicationFormConfig,
  useUpdateOrganizationCommunicationFormConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { mergeDataWithDefaults } from '@/components/core/position/communication/form-defaults';
import StickyActionBar from '@/components/core/sticky-action-bar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { usePositionDefaultsApplyChangesModal, ApplyChangesType } from '@/hooks/usePositionDefaultsApplyChangesModal';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/communication',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsCommunicationPage,
});

function CompanyPositionDefaultsCommunicationPage() {
  const { organizationId } = Route.useParams();
  const isMutating = useIsMutating();
  const form = usePositionCommunicationForm();
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const { data: organizationCommunicationFormConfig } =
    useGetOrganizationCommunicationFormConfig({
      organizationId,
    });
  const updateOrganizationCommunicationFormConfig =
    useUpdateOrganizationCommunicationFormConfigMutation();
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (organizationCommunicationFormConfig) {
      reset({
        ...mergeDataWithDefaults(organizationCommunicationFormConfig),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCommunicationFormConfig]);

  const handleFormSubmit = async (values: PositionCommunicationFormValues, applyType: ApplyChangesType) => {
    const formData = handleMapFormValuesToData(values);
    
    const { error, data: updatedForm } =
      await updateOrganizationCommunicationFormConfig({
        data: {
          ...formData,
          applyToAllPositions: applyType === ApplyChangesType.ALL_POSITIONS,
        },
        organizationId,
      });
      
    if (error) {
      toast.error(error);
      return;
    }
    
    const successMessage = applyType === ApplyChangesType.ALL_POSITIONS
      ? 'Communication form updated successfully for all positions!'
      : 'Communication form updated successfully for future positions!';
      
    toast.success(successMessage);
    
    if (updatedForm) {
      reset({
        ...mergeDataWithDefaults(updatedForm),
        isInitialized: true,
      });
    }
  };

  const onSubmit = async (values: PositionCommunicationFormValues) => {
    await handleFormSubmit(values, ApplyChangesType.FUTURE_ONLY);
  };

  const { ApplyChangesModal, handleSaveClick } = usePositionDefaultsApplyChangesModal({
    onConfirm: async (applyType) => {
      const values = form.getValues();
      await handleFormSubmit(values, applyType);
    },
    isDirty,
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      {ApplyChangesModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PositionCommunicationForm />
          {isFormInitialized && (
            <StickyActionBar
              isSaveLoading={isMutating > 0}
              isSaveDisabled={isMutating > 0 || !isDirty}
              onSave={handleSaveClick}
            />
          )}
        </form>
      </Form>
    </>
  );
}
