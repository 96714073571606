import { useState } from 'react';
import AlertModal from '@/components/core/common/AlertModal';

export enum ApplyChangesType {
  ALL_POSITIONS = 'all_positions',
  FUTURE_ONLY = 'future_only',
}

type UsePositionDefaultsApplyChangesModalProps = {
  onConfirm: (applyType: ApplyChangesType) => Promise<void> | void;
  isDirty: boolean;
};

export function usePositionDefaultsApplyChangesModal({
  onConfirm,
  isDirty,
}: UsePositionDefaultsApplyChangesModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<ApplyChangesType>(ApplyChangesType.FUTURE_ONLY);

  const openModal = () => {
    if (isDirty) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedType(ApplyChangesType.FUTURE_ONLY);
  };

  const handleConfirm = async () => {
    await onConfirm(selectedType);
    closeModal();
  };

  const handleSaveClick = () => {
    openModal();
  };

  const ApplyChangesModal = (
    <AlertModal
      open={isModalOpen}
      onClose={closeModal}
      title="Apply Changes"
      description="How would you like to apply these changes?"
      content={
        <div className="flex flex-col gap-4 pt-2">
          <div className="flex items-start gap-2">
            <input
              type="radio"
              id="apply-all"
              name="apply-type"
              value={ApplyChangesType.ALL_POSITIONS}
              checked={selectedType === ApplyChangesType.ALL_POSITIONS}
              onChange={() => setSelectedType(ApplyChangesType.ALL_POSITIONS)}
              className="mt-1"
            />
            <label htmlFor="apply-all" className="flex flex-col">
              <span className="font-medium">Apply to all positions</span>
              <span className="text-sm text-muted-foreground">
                Update existing positions and apply to future positions
              </span>
            </label>
          </div>
          <div className="flex items-start gap-2">
            <input
              type="radio"
              id="apply-future"
              name="apply-type"
              value={ApplyChangesType.FUTURE_ONLY}
              checked={selectedType === ApplyChangesType.FUTURE_ONLY}
              onChange={() => setSelectedType(ApplyChangesType.FUTURE_ONLY)}
              className="mt-1"
            />
            <label htmlFor="apply-future" className="flex flex-col">
              <span className="font-medium">Apply to future positions only</span>
              <span className="text-sm text-muted-foreground">
                Only apply these changes to positions created after this update
              </span>
            </label>
          </div>
        </div>
      }
      confirmText="Apply Changes"
      type="info"
      onProceed={handleConfirm}
      isConfirmLoading={false}
    />
  );

  return {
    ApplyChangesModal,
    handleSaveClick,
    isModalOpen,
  };
}