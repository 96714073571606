import { Loader } from '@/components/core/loader';
import { Interview } from '@/components/core/scenario/Interview';

import { useInterviewQuery } from '@/fetchers/useInterview';

import { useBlocker, useParams } from '@tanstack/react-router';
import { ErrorView } from '../ErrorView';
import { useAudioStream } from '@/lib/useAudioStream';
import { RouterParams } from '@/main';

export function InterviewPage() {
  const { interviewUUid } = useParams({ strict: false }) as RouterParams;

  const {
    data: interview,
    isFetching,
    error,
  } = useInterviewQuery({
    interviewUUid:
      interviewUUid !== 'general-application' ? interviewUUid : undefined,
  });

  const { setIsStarted, aiTalking, endStream } = useAudioStream({
    interview,
  });

  useBlocker({
    blockerFn: () => window.confirm('Are you sure you want to leave?'),
    condition:
      !isFetching && !error && interview?.id && !interview?.completedAt,
  });

  if (isFetching) {
    return <Loader />;
  }

  if (error || interview?.interviewType === 'phone') {
    return (
      <ErrorView
        statusCode={401}
        title="Unauthorized access"
        description="You are not authorized to view this interview"
      />
    );
  }
  if (!interview && interviewUUid !== 'general-application') {
    return (
      <ErrorView
        statusCode={404}
        title="Interview not found"
        description="Interview not found"
      />
    );
  }

  return (
    <Interview
      data={interview}
      startStream={() => setIsStarted(true)}
      aiTalking={aiTalking}
      endStream={endStream}
    />
  );
}
