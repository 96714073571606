import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { RequiredStar } from '../common/RequiredStar';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

const connectionSchema = z.object({
  api_key: z.string().min(1, { message: 'API Key is required' }),
  domain: z.string().min(1, { message: 'Domain is required' }),
  agency_slug: z.string().min(1, { message: 'Agency Slug is required' }),
});

export type LoxoConnectionFormValues = z.infer<typeof connectionSchema>;

interface LoxoConnectionProps {
  onSubmit: (data: LoxoConnectionFormValues) => void;
}

export function LoxoConnection({ onSubmit }: LoxoConnectionProps) {
  const form = useForm<LoxoConnectionFormValues>({
    resolver: zodResolver(connectionSchema),
  });

  function onSubmitHandler(data: LoxoConnectionFormValues) {
    onSubmit(data);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler)}
        className="flex w-full flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="agency_slug"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel aria-required>
                Agency Slug
                <RequiredStar />
              </FormLabel>
              <FormDescription>Contact Loxo Support for assistance</FormDescription>
              <FormControl>
                <Input placeholder="Your client Id" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="domain"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel aria-required>
                Domain
                <RequiredStar />
              </FormLabel>
              <FormDescription>Contact Loxo Support for assistanc</FormDescription>
              <FormControl>
                <Input placeholder="Your domain" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="api_key"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel aria-required>
                API Key
                <RequiredStar />
              </FormLabel>
              <FormDescription>These tokens can be created and managed by navigating to the Settings {">"} API Keys page in your Loxo account. If you do not see the API Keys option available, please reach out to Loxo Support to ensure these have been enabled for your account.
              </FormDescription>
              <FormControl>
                <Input placeholder="Your API Key" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="self-end">Connect</Button>
      </form>
    </Form>
  );
}
