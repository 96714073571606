import { Button } from '@/components/ui/button';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { FilterIcon } from 'lucide-react';
import {
  PipelineFilter,
  PipelineFilterCategory,
  PipelineFilterOption,
  PipelineStage,
} from './types';
import React from 'react';
import { Checkbox } from '@/components/ui/checkbox';

const Filters = ({
  filters,
  selectedFilters,
  onSelectFilterOption,
  onClearAllFilters,
  stage,
  disabled,
  filterCounts = {},
}: {
  filters: PipelineFilter[];
  selectedFilters: PipelineFilter[];
  onSelectFilterOption: (
    option: PipelineFilterOption,
    category?: PipelineFilterCategory
  ) => void;
  onClearAllFilters: () => void;
  stage: PipelineStage;
  disabled: boolean;
  filterCounts?: Record<string, Record<string, number>>;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button
          variant={selectedFilters.length > 0 ? 'default' : 'ghost'}
          className="h-fit rounded-sm p-1"
          disabled={disabled}
        >
          <FilterIcon className="size-4 flex-shrink-0" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="relative max-h-[500px] min-w-56 overflow-y-auto p-0">
        <DropdownMenuLabel className="sticky left-0 right-0 top-0 z-10 flex items-center justify-between border-b border-border bg-white">
          <span className="text-lg">Filter by</span>
          <Button
            variant="ghost"
            className="h-fit p-1 text-sm text-primary"
            onClick={onClearAllFilters}
            disabled={selectedFilters.length === 0}
          >
            Clear all
          </Button>
        </DropdownMenuLabel>
        {filters.map((filter) => (
          <React.Fragment key={`${stage}-${filter.category}`}>
            {filter.category && (
              <DropdownMenuLabel>{filter.category}</DropdownMenuLabel>
            )}
            <DropdownMenuGroup>
              {filter.options.map((option) => {
                const checked = selectedFilters.some(
                  (selectedFilter) =>
                    selectedFilter.category === filter.category &&
                    selectedFilter.options.some(
                      (selectedOption) =>
                        selectedOption.label === option.label &&
                        selectedOption.isSelected
                    )
                );

                // @ts-ignore
                const count = filterCounts?.[filter.category]?.[option.label] || 0;

                return (
                  <DropdownMenuItem
                    key={`${stage}-${filter.category}-${option.label}`}
                    className="flex cursor-pointer items-center justify-between"
                    onSelect={(e) => {
                      e.preventDefault();
                      onSelectFilterOption(option, filter.category);
                    }}
                  >
                    <div className="flex items-center">
                      <Checkbox
                        checked={checked}
                        className="mr-2 border-foreground data-[state=checked]:border-primary"
                      />
                      {option.label}
                    </div>
                    <span className="ml-2 text-xs text-gray-500">({count})</span>
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuGroup>
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Filters;
