export enum CommunicationMethod {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum AutoSendOption {
  SEND_ALL = 'send_all',
  GOOD_FIT = 'good_fit',
  MAYBE_AND_UP = 'maybe_and_up',
}

export enum InterviewType {
  VIDEO = 'video',
  PHONE = 'phone',
}

export enum InterviewDelay {
  NO_DELAY = 'no_delay',
  FIFTEEN_MINUTES = 'fifteen_minutes',
  THIRTY_MINUTES = 'thirty_minutes',
  ONE_HOUR = 'one_hour',
  ONE_DAY = 'one_day',
  TWO_DAYS = 'two_days',
  THREE_DAYS = 'three_days',
}

export type MessageFields =
  | 'afterApplication'
  | 'afterApplicationSubject'
  | 'afterInterview'
  | 'afterInterviewSubject'
  | 'disqualified'
  | 'disqualifiedSubject';

export enum SelectedCommunicationMessageTab {
  INVITATION = 'invitation',
  COMPLETED = 'completed',
  DISQUALIFIED = 'disqualified',
}
