import { useGlobalAlertStore } from '@/lib/useGlobalAlertStore';
import { useEffect, useRef } from 'react';

export const useUpdateMarginWithGlobalAlert = () => {
  const alertHeight = useGlobalAlertStore((store) => store.alertHeight);
  const ref = useRef<HTMLDivElement>(null);
  const initialMarginRef = useRef(0);

  useEffect(() => {
    if (ref.current && initialMarginRef.current === 0) {
      const computedStyle = window.getComputedStyle(ref.current);
      const initialMarginStr = computedStyle.marginBottom;
      const initialMarginValue = parseFloat(initialMarginStr);
      initialMarginRef.current = initialMarginValue;
    }
  }, []);

  useEffect(() => {
    if (ref.current && initialMarginRef.current !== 0) {
      ref.current.style.marginBottom =
        alertHeight > 0
          ? `${initialMarginRef.current + alertHeight}px`
          : `${initialMarginRef.current}px`;
    }
  }, [alertHeight]);

  return ref;
};
