import { Badge, BadgeProps } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface BetaBadgeProps extends BadgeProps {
  className?: string;
}

export function BetaBadge({ className, ...props }: BetaBadgeProps) {
  return (
    <Badge
      className={cn(
        'ml-2 border-amber-500 bg-amber-100 text-amber-900 hover:bg-amber-200 text-xs',
        className
      )}
      {...props}
    >
      Beta
    </Badge>
  );
}
