import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { useIsMutating } from '@tanstack/react-query';
import {
  usePositionAiConfigForm,
  PositionAIConfigFormValues,
  handleMapAIConfigDataToFormValues,
} from '@/lib/usePositionAiConfigForm';
import { PositionAIConfigForm } from '@/components/core/position/ai-config/aiconfig-form';
import {
  useGetOrganizationAIConfig,
  useUpdateOrganizationAIConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';
import StickyActionBar from '@/components/core/sticky-action-bar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { useOrgDefaultCommunicationLanguage } from '@/hooks/useOrgDefaultCommunicationLanguage';
import { usePositionDefaultsApplyChangesModal, ApplyChangesType } from '@/hooks/usePositionDefaultsApplyChangesModal';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/ai-config',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsAIConfigPage,
});

function CompanyPositionDefaultsAIConfigPage() {
  const { organizationId } = Route.useParams();
  const isMutating = useIsMutating();
  const form = usePositionAiConfigForm();
  const { data: aiConfigForm } = useGetOrganizationAIConfig({
    organizationId,
  });
  const updateOrganizationAIConfigMutation =
    useUpdateOrganizationAIConfigMutation();
  const {
    orgDefaultCommunicationLanguage,
    isFetchingOrgDefaultCommunicationLanguage,
  } = useOrgDefaultCommunicationLanguage();
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (aiConfigForm) {
      reset({
        ...handleMapAIConfigDataToFormValues(aiConfigForm),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiConfigForm]);

  const handleFormSubmit = async (data: PositionAIConfigFormValues, applyType: ApplyChangesType) => {
    const additionalInfo = data?.additionalQuestions
      ?.map((question) => question.value)
      .filter(Boolean);
    const hasAdditionalInfo = additionalInfo && additionalInfo.length > 0;
    const restrictQuestions =
      hasAdditionalInfo && data.restrictQuestions === 'enabled';
    const { error, data: updatedForm } =
      await updateOrganizationAIConfigMutation({
        data: {
          aiVoiceModelId: data.aiVoiceModelId,
          additionalQuestions: additionalInfo,
          restrictQuestions,
          evaluationCriteria: data.evaluationCriteria,
          applyToAllPositions: applyType === ApplyChangesType.ALL_POSITIONS,
        },
        organizationId,
      });
    if (error) {
      toast.error('Error updating AI config form');
      return;
    }
    
    const successMessage = applyType === ApplyChangesType.ALL_POSITIONS
      ? 'AI config updated successfully for all positions!'
      : 'AI config updated successfully for future positions!';
    
    toast.success(successMessage);
    
    if (updatedForm) {
      reset({
        ...handleMapAIConfigDataToFormValues(updatedForm),
        isInitialized: true,
      });
    }
  };

  const onSubmit = async (data: PositionAIConfigFormValues) => {
    await handleFormSubmit(data, ApplyChangesType.FUTURE_ONLY);
  };

  const { ApplyChangesModal, handleSaveClick } = usePositionDefaultsApplyChangesModal({
    onConfirm: async (applyType) => {
      const data = form.getValues();
      await handleFormSubmit(data, applyType);
    },
    isDirty,
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      {ApplyChangesModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
          <PositionAIConfigForm
            language={orgDefaultCommunicationLanguage}
            isFetchingLanguage={isFetchingOrgDefaultCommunicationLanguage}
          />
          {isFormInitialized && (
            <StickyActionBar
              isSaveLoading={isMutating > 0}
              isSaveDisabled={isMutating > 0 || !isDirty}
              onSave={handleSaveClick}
            />
          )}
        </form>
      </Form>
    </>
  );
}
