import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

import { Button } from "@/components/ui/button"

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { toast } from "sonner"
import { TextEditor } from "../../common/TextEditor"
import { RequiredStar } from "../../common/RequiredStar"
import { useRef } from "react"
import { InsertVariables } from "./InsertVariables"
import { autoSendOptions, delayLabels } from "./templates"
import { mergeDataWithDefaults, DisplayFormValues, displayFormSchema } from "./form-defaults"
import { TCommunicationForm, TPosition, useUpdateCommunicationFormMutation } from "@/fetchers/usePosition"
import { useNavigate, useParams } from "@tanstack/react-router"
import { useIsMutating } from "@tanstack/react-query"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import { TooltipProvider } from "@/components/ui/tooltip"
import { CircleAlert } from "lucide-react"
import { Input } from "@/components/ui/input"


type TProps = {
  communicationForm?: TCommunicationForm
  organizationName?: string
  position?: TPosition
}
export function CommunicationForm({ communicationForm, organizationName, position }: Readonly<TProps>) {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false })
  const updateCommunicationForm = useUpdateCommunicationFormMutation()
  const navigate = useNavigate()
  const isMutating = useIsMutating()
  const applicationEmailRef = useRef()
  const interviewCompletedEmailRef = useRef()
  const disqualifiedRef = useRef()

  // const applicationSMSRef = useRef()
  // const applicationCompletedSMSRef = useRef()


  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: mergeDataWithDefaults(communicationForm, position, organizationName)
  })


  async function onSubmit(data: DisplayFormValues) {
    const { error } = await updateCommunicationForm({
      data: {
        selectedMethod: data.selectedMethod,
        afterApplication: data.afterApplication,
        afterInterview: data.afterInterview,
        autoInterview: data.autoInterview,
        interviewDelay: data.autoInterview ? data.interviewDelay : "no_delay",
        autoSendOption: data.autoInterview ? data.autoSendOption : "send_all",
        interviewType: data.interviewType,
        disqualified: data.disqualifiedToggle === "disabled" ? undefined : data.disqualified,


        afterApplicationSubject: data.afterApplicationSubject,
        afterInterviewSubject: data.afterInterviewSubject,
        disqualifiedSubject: data.disqualifiedToggle === "disabled" ? undefined : data.disqualifiedSubject,
      },
      organizationId,
      slug
    })

    if (error) {
      toast.error("Error updating communication form")
      return
    }

    navigate({
      // to: "/new-position/$step",
      to: "/organizations/$organizationId/positions/manage/$slug/$step",
      params: {
        organizationId: String(organizationId),
        slug: slug,
        step: "ai-config"
      }
    })

    // toast(
    //   "You submitted the following values:",
    //   {
    //     description: (
    //       <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
    //         <code className="text-white">{JSON.stringify(data, null, 2)}</code>
    //       </pre>
    //     ),
    //   })
  }



  return (
    <TooltipProvider>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="selectedMethod"
            render={({ field }) => (
              <FormItem>
                <div className="w-full flex items-center justify-between ">

                  <FormLabel>Preferred Communication Method</FormLabel>
                  <FormControl>
                    <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                      <TabsList >
                        <TabsTrigger value="email">Email</TabsTrigger>
                        <TabsTrigger value="sms">SMS</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormMessage />


                <FormDescription>
                  SMS option only available for US numbers.
                </FormDescription>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="interviewType"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between items-center w-full ">

                  <FormLabel>Preferred Interview Method </FormLabel>
                  <FormControl>
                    <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                      <TabsList>
                        <TabsTrigger value="phone">Phone Call</TabsTrigger>
                        <TabsTrigger value="video">Video Call</TabsTrigger>

                        {/* <Tooltip>
                          <TooltipTrigger type="button">
                            <TabsTrigger className="pointer-events-none opacity-50" value="video">Video Call</TabsTrigger>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Contact support</p>
                          </TooltipContent>
                        </Tooltip> */}


                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormDescription>
                  <p>Whether you prefer the AI to conduct interviews via phone or video call.</p>
                  <div className="flex items-center ">
                    <CircleAlert className="size-3 mr-1" />
                    <p>Phone option is only avaiable for US numbers</p>
                  </div>
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />



          <FormField
            control={form.control}
            name="autoInterview"
            render={({ field }) => (
              <FormItem>
                <div className="w-full flex items-center justify-between">

                  <FormLabel>Auto Send Interviews</FormLabel>
                  <FormControl>
                    <Tabs defaultValue={field.value ? "enabled" : "disabled"} className="" onValueChange={val => field.onChange(val === "enabled")}>
                      <TabsList >
                        <TabsTrigger value="disabled">Disabled</TabsTrigger>
                        <TabsTrigger value="enabled">Enabled</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>
                <FormDescription>
                  Automatically send interview requests to candidates.
                </FormDescription>

                <FormMessage />
              </FormItem>
            )}
          />


          {form.getValues("autoInterview") && (
            <FormField
              control={form.control}
              name="autoSendOption"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-between items-center w-full  ">
                    <FormLabel className="w-full">Auto Send to</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl className="w-[180px]" >
                        <SelectTrigger>
                          <SelectValue placeholder="Auto send option" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.keys(autoSendOptions).map((key) => (
                          // @ts-ignore
                          <SelectItem key={key} value={key}>{autoSendOptions[key]}</SelectItem>
                        ))}

                      </SelectContent>
                    </Select>
                  </div>
                  <FormDescription>
                    Choose to send all candidates or only good fits.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}


          {form.getValues("autoInterview") && (
            <FormField
              control={form.control}
              name="interviewDelay"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-between items-center w-full  ">
                    <FormLabel className="w-full">Interview Delay</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl className="w-[140px]" >
                        <SelectTrigger>
                          <SelectValue placeholder="Select interview delay" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.keys(delayLabels).map((key) => (
                          // @ts-ignore
                          <SelectItem key={key} value={key}>{delayLabels[key]}</SelectItem>
                        ))}

                      </SelectContent>
                    </Select>
                  </div>
                  <FormDescription>
                    How soon after applying would you like to invite candidates to perform their interview
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}



          <div className="space-y-2">
            <FormLabel className="text-md">Interview Invitation Message<RequiredStar /></FormLabel>
            <FormField
              control={form.control}
              name="afterApplicationSubject"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>Subject</FormLabel> */}
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="afterApplication"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>Message</FormLabel> */}
                  <FormControl>

                    <TextEditor
                      // @ts-expect-error style works
                      // style={{ height: "80%" }}
                      className="h-[300px] pb-20"
                      theme='snow'
                      placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                      {...field}
                      ref={ref => {
                        field.ref(ref)
                        // @ts-ignore
                        applicationEmailRef.current = ref
                      }}

                    />
                  </FormControl>
                  <FormDescription >
                    <InsertVariables customRef={applicationEmailRef} />
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Separator />


          <div className="space-y-2">
            <FormLabel className="text-md">Interview Completed Message<RequiredStar /></FormLabel>
            <FormField
              control={form.control}
              name="afterInterviewSubject"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>Interview Completed Subject<RequiredStar /></FormLabel> */}
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="afterInterview"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>Interview Completed Message<RequiredStar /></FormLabel> */}
                  <FormControl >

                    <TextEditor
                      // @ts-expect-error style works
                      // style={{ height: "80%" }}
                      className="h-[300px] pb-20"
                      theme='snow'
                      placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                      {...field}
                      ref={ref => {
                        field.ref(ref)
                        // @ts-ignore
                        interviewCompletedEmailRef.current = ref
                      }}

                    />
                  </FormControl>
                  <FormDescription>
                    <InsertVariables customRef={interviewCompletedEmailRef} />

                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Separator />
          <FormField
            control={form.control}
            name="disqualifiedToggle"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Tabs defaultValue={communicationForm?.disqualified ? "enabled" : "disabled"} className="" onValueChange={field.onChange}>
                    <div className="flex justify-between items-center w-full  ">
                      <FormLabel className="">Disqualified Communication</FormLabel>
                      <TabsList >
                        <TabsTrigger value="disabled">Disabled</TabsTrigger>
                        <TabsTrigger value="enabled">Enabled</TabsTrigger>
                      </TabsList>
                    </div>
                    <TabsContent value="disabled">
                      <FormDescription>
                        Automatically send a message to disqualified candidates.
                      </FormDescription>
                    </TabsContent>

                    <TabsContent value="enabled">
                      <div className="space-y-2">
                        <FormField
                          control={form.control}
                          name="disqualifiedSubject"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Disqualification Subject<RequiredStar /></FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="disqualified"
                          render={({ field }) => (
                            <FormItem>
                              {/* <FormLabel>Disqualification Email to be sent<RequiredStar /></FormLabel> */}
                              <FormControl >

                                <TextEditor
                                  // @ts-expect-error style works
                                  // style={{ height: "80%" }}
                                  className="h-[300px] pb-20"
                                  theme='snow'
                                  placeholder="Disqualification email content. ie: We regret to inform you that you have been disqualified from the application process for {{position_name}}. If you have any questions, please feel free to contact us at {{company_email}}."
                                  {...field}
                                  value={field.value}
                                  ref={ref => {
                                    field.ref(ref)
                                    // @ts-ignore
                                    disqualifiedRef.current = ref
                                  }}

                                />
                              </FormControl>
                              <FormDescription>
                                <InsertVariables customRef={disqualifiedRef} />

                              </FormDescription>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>

                    </TabsContent>
                  </Tabs>
                </FormControl>


                <FormMessage />
              </FormItem>
            )}
          />




          {/* 
        <Tabs
          defaultValue={form.getValues("selectedMethod")}
          className="w-full"
          // @ts-ignore
          onValueChange={(selectedMethod: "email" | "sms") => form.setValue("selectedMethod", selectedMethod)
          }>
          <TabsList className="grid w-full grid-cols-2 mb-4">
            <TabsTrigger value="email">Email</TabsTrigger>
            <TabsTrigger value="sms">SMS</TabsTrigger>
          </TabsList>


          <TabsContent value="email">

          </TabsContent>

          <TabsContent value="sms">
            <FormField
              control={form.control}
              name="applicationSMS"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Application SMS<RequiredStar /></FormLabel>
                  <FormControl >

                    <TextEditor
                      // @ts-expect-error style works
                      // style={{ height: "80%" }}
                      className="h-[300px] pb-20"
                      theme='snow'
                      placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                      {...field}
                      ref={ref => {
                        field.ref(ref)
                        // @ts-ignore
                        applicationSMSRef.current = ref
                      }}

                    />
                  </FormControl>
                  <FormDescription>
                    <InsertVariables customRef={applicationSMSRef} />

                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator className="my-8" />

            <FormField
              control={form.control}
              name="applicationCompletedSMS"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Interview Completed SMS<RequiredStar /></FormLabel>
                  <FormControl >

                    <TextEditor
                      // @ts-expect-error style works
                      // style={{ height: "80%" }}
                      className="h-[300px] pb-20"
                      theme='snow'
                      placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                      {...field}
                      ref={ref => {
                        field.ref(ref)
                        // @ts-ignore
                        applicationCompletedSMSRef.current = ref
                      }}

                    />
                  </FormControl>
                  <FormDescription>
                    <InsertVariables customRef={applicationCompletedSMSRef} />

                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </TabsContent>

        </Tabs> */}


          <Button isLoading={isMutating > 0} className="float-right" type="submit">Next</Button>
        </form>
      </Form>
    </TooltipProvider>
  )
}
