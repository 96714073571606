import { createFileRoute } from '@tanstack/react-router'
import DashboardPage from '@/pages/dashboard/page';
import { BillingComponent } from '@/components/core/billing';
import { useGetBilling, useGetTeamList } from '@/fetchers/useOrganization';



export const Route = createFileRoute('/billing')({
  component: BillingPageView
})

function BillingPageView() {
  const { data: teamMembers } = useGetTeamList()
  const { data: billing } = useGetBilling({ enabled: true })

  return (
    <DashboardPage>
      <BillingComponent
        tier={{
          amount: billing?.amount || 0,
          isMonthly: billing?.isMonthly || false,
          maxInterviewAllowed: billing?.maxInterviewAllowed || 5,
          maxApplicationAvailable: Infinity,
          maxResumeAnalysisAvailable: Infinity,
          atsIntegration: billing?.atsIntegration || false,
          nextRenewalDate: billing?.nextRenewalDate || "",
          planStatus: billing?.planStatus || "",
          currentPlan: billing?.currentPlan
        }}
        portalUrl={billing?.portalUrl}
        metrics={{
          interviewsTotal: billing?.interviewTotal || 0,
          applicationsTotal: billing?.applicationTotal || 0,
          teamCount: teamMembers?.length || 0
        }}
      />

    </DashboardPage >
  )
}


