import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import { forwardRef, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

type Props = ReactQuillProps & {
  height?: number;
};

const toolBarOptions = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const TextEditor = forwardRef<ReactQuill, Props>(
  function TextEditor(props, ref) {
    const { onChange, height = 300, ...rest } = props;

    useEffect(() => {
      //@ts-ignore
      if (ref.current) {
        //@ts-ignore
        ref.current.getEditor().clipboard.addMatcher('IMG', () => {
          const Delta = Quill.import('delta');
          return new Delta().insert('');
        });
      }
    }, [ref]);

    return (
      <div className="relative flex flex-col">
        <style>
          {`.ql-container {
              height: ${height}px;
          }`}
        </style>
        <ReactQuill
          {...rest}
          onChange={(value, _delta, source, editor) => {
            // prevent quill from removing empty lines from the content and breaking isDirty behavior of the forms
            if (source === 'api') return;

            onChange?.(value, _delta, source, editor);
          }}
          modules={toolBarOptions}
          ref={ref}
          preserveWhitespace
        />
      </div>
    );
  }
);

export { TextEditor };
