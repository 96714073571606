import {
  CheatDetectionVerdict,
  FinalVerdict,
  PhoneNumberStatus,
  PipelineFilter,
  PipelineFilterCategory,
  PipelineStage,
  ResumeAnalysisVerdict,
  SmsDeliverability,
} from './types';

export const pipelineTitleByStage = {
  [PipelineStage.APPLIED]: 'Applied',
  [PipelineStage.INTERVIEW_SENT]: 'Interview Sent',
  [PipelineStage.INTERVIEW_COMPLETED]: 'Interview Completed',
  [PipelineStage.SHORTLISTED]: 'Shortlist',
  [PipelineStage.DISQUALIFIED]: 'Disqualified',
};

export const interviewVerdictValues: Record<FinalVerdict, string> = {
  [FinalVerdict.NOT_FIT]: 'Not Fit',
  [FinalVerdict.MAYBE_FIT]: 'Maybe Fit',
  [FinalVerdict.GOOD_FIT]: 'Good Fit',
  [FinalVerdict.DISQUALIFIED]: 'Disqualified',
};

export const interviewFilters: PipelineFilter = {
  category: PipelineFilterCategory.INTERVIEW_ANALYSIS,
  options: [
    {
      label: interviewVerdictValues[FinalVerdict.NOT_FIT],
      isSelected: false,
      category: PipelineFilterCategory.INTERVIEW_ANALYSIS,
    },
    {
      label: interviewVerdictValues[FinalVerdict.MAYBE_FIT],
      isSelected: false,
      category: PipelineFilterCategory.INTERVIEW_ANALYSIS,
    },
    {
      label: interviewVerdictValues[FinalVerdict.GOOD_FIT],
      isSelected: false,
      category: PipelineFilterCategory.INTERVIEW_ANALYSIS,
    },
    {
      label: interviewVerdictValues[FinalVerdict.DISQUALIFIED],
      isSelected: false,
      category: PipelineFilterCategory.INTERVIEW_ANALYSIS,
    },
  ],
};

export const resumeVerdictValues: Record<ResumeAnalysisVerdict, string> = {
  [ResumeAnalysisVerdict.INTERVIEW_RECOMMENDED]: 'Good Fit',
  [ResumeAnalysisVerdict.INTERVIEW_POSSIBLY_RECOMMENDED]: 'Maybe Fit',
  [ResumeAnalysisVerdict.INTERVIEW_NOT_RECOMMENDED]: 'Not Fit',
  [ResumeAnalysisVerdict.RESUME_NOT_PROVIDED]: 'Resume Not Provided',
};

export const cheatDetectionVerdict: Record<CheatDetectionVerdict, string> = {
  [CheatDetectionVerdict.NOT_LIKELY]: 'Not Likely',
  [CheatDetectionVerdict.LIKELY]: 'Likely',
  [CheatDetectionVerdict.HIGHLY_LIKELY]: 'Highly Likely',
};

export const resumeFilters: PipelineFilter = {
  category: PipelineFilterCategory.RESUME_ANALYSIS,
  options: [
    {
      label: resumeVerdictValues[ResumeAnalysisVerdict.INTERVIEW_RECOMMENDED],
      isSelected: false,
      category: PipelineFilterCategory.RESUME_ANALYSIS,
    },
    {
      label:
        resumeVerdictValues[
          ResumeAnalysisVerdict.INTERVIEW_POSSIBLY_RECOMMENDED
        ],
      isSelected: false,
      category: PipelineFilterCategory.RESUME_ANALYSIS,
    },
    {
      label:
        resumeVerdictValues[ResumeAnalysisVerdict.INTERVIEW_NOT_RECOMMENDED],
      isSelected: false,
      category: PipelineFilterCategory.RESUME_ANALYSIS,
    },
    {
      label: resumeVerdictValues[ResumeAnalysisVerdict.RESUME_NOT_PROVIDED],
      isSelected: false,
      category: PipelineFilterCategory.RESUME_ANALYSIS,
    },
  ],
};

export const disqualifiedFilters: PipelineFilter = {
  options: [
    {
      label: 'Archived',
      isSelected: false,
    },
  ],
};

export const phoneNumberStatusFilters: PipelineFilter = {
  category: PipelineFilterCategory.PHONE_NUMBER_STATUS,
  options: [
    {
      label: PhoneNumberStatus.VALID,
      isSelected: false,
      category: PipelineFilterCategory.PHONE_NUMBER_STATUS,
    },
    {
      label: PhoneNumberStatus.INVALID,
      isSelected: false,
      category: PipelineFilterCategory.PHONE_NUMBER_STATUS,
    },
  ],
};

export const smsDeliverabilityFilters: PipelineFilter = {
  category: PipelineFilterCategory.SMS_DELIVERABILITY,
  options: [
    {
      label: SmsDeliverability.MOBILE_PHONE,
      isSelected: false,
      category: PipelineFilterCategory.SMS_DELIVERABILITY,
    },
    {
      label: SmsDeliverability.NON_MOBILE_PHONE,
      isSelected: false,
      category: PipelineFilterCategory.SMS_DELIVERABILITY,
    },
  ],
};
