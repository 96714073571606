import { Separator } from '@/components/ui/separator';
import InnerSidebar from '@/components/core/inner-sidebar';
import { PositionStatus, TPosition } from '@/fetchers/usePosition';
import { useMemo } from 'react';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import {
  Link,
  useBlocker,
  useRouterState,
  useSearch,
} from '@tanstack/react-router';
import AlertModal from '../common/AlertModal';
import { useUpdateMarginWithGlobalAlert } from '@/hooks/useUpdateMarginWithGlobalAlert';

const sidebarNavItems = (orgId: string, slug = 'create') => [
  {
    title: 'Details',
    href: `/organizations/${orgId}/positions/manage/${slug}/details`,
  },
  {
    title: 'Description',
    href: `/organizations/${orgId}/positions/manage/${slug}/description`,
  },
  {
    title: 'Application',
    href: `/organizations/${orgId}/positions/manage/${slug}/application`,
  },
  {
    title: 'Communication',
    href: `/organizations/${orgId}/positions/manage/${slug}/communication`,
  },
  {
    title: 'AI Configuration',
    href: `/organizations/${orgId}/positions/manage/${slug}/ai-config`,
  },
  {
    title: 'Hiring Team',
    href: `/organizations/${orgId}/positions/manage/${slug}/hiring-team`,
  },
  {
    title: 'Preview',
    href: `/organizations/${orgId}/positions/manage/${slug}/preview`,
  },
];

interface PositionCreationLayoutProps {
  children: React.ReactNode;
  slug?: string;
  organizationId: number;
  title: string;
  subtitle: string;
  CustomActions?: React.ReactNode[];
  position?: TPosition;
}

export default function PositionCreationLayout({
  children,
  organizationId,
  slug,
  title,
  subtitle,
  CustomActions,
  position,
}: Readonly<PositionCreationLayoutProps>) {
  const {
    location: { pathname },
  } = useRouterState();
  const { selectedOrganization } = useUserDetailStore();
  const { creating } = useSearch({ strict: false });
  const showUnpublishedPositionAlert =
    position?.status === PositionStatus.Draft && !!creating;
  const { proceed, reset, status } = useBlocker({
    condition: showUnpublishedPositionAlert,
  });
  const containerRef = useUpdateMarginWithGlobalAlert();

  const navItems = useMemo(() => {
    const integrationName = selectedOrganization?.integrationName;
    const items = sidebarNavItems(organizationId.toString(), slug);

    if (integrationName) {
      return items.filter((item) =>
        ['Communication', 'AI Configuration', 'Hiring Team'].includes(
          item.title
        )
      );
    }

    return items;
  }, [organizationId, selectedOrganization, slug]);

  return (
    <>
      <AlertModal
        title="Leave without publishing?"
        description="This position is currently in Draft status. If you leave now your progress will be saved. Complete the steps and publish the position to start interviewing applicants with Veton AI."
        confirmText="Leave anyway"
        cancelText="Stay on this page"
        onClose={reset}
        onProceed={proceed}
        open={status === 'blocked'}
        type="info"
      />
      <div className="mx-auto w-full max-w-4xl space-y-3 has-[.communication-form]:max-w-full">
        <div className="mx-auto w-full max-w-4xl space-y-0.5">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold tracking-tight">{title}</h2>

            <div className="flex items-end space-x-2">
              {CustomActions && CustomActions}
            </div>
          </div>
          <p className="text-muted-foreground">{subtitle}</p>
        </div>
        <Separator className="mx-auto w-full max-w-4xl" />
        <div className="flex h-screen w-full flex-col gap-4">
          <div className="mx-auto flex w-full max-w-4xl flex-shrink-0 items-center gap-4 overflow-x-auto p-1">
            {navItems.map((item) => {
              let isDisabled = false;
              if (item.href.includes('create')) {
                isDisabled = !item.href.includes('details');
              } else if (
                !selectedOrganization?.integrationName &&
                (!position?.content || position?.content?.length <= 11)
              ) {
                isDisabled = !(
                  item.href.includes('details') ||
                  item.href.includes('description')
                );
              }

              return (
                <Link
                  key={item.title}
                  // @ts-ignore
                  to={item.href}
                  ignoreBlocker={showUnpublishedPositionAlert}
                  className={buttonVariants({
                    variant: 'ghost',
                    className: cn(
                      isDisabled &&
                        'pointer-events-none text-muted-foreground hover:bg-transparent hover:text-muted-foreground',
                      pathname === item.href && 'bg-muted',
                      pathname.includes('create') &&
                        item.title === 'Details' &&
                        'bg-muted',
                      !isDisabled && 'hover:bg-muted'
                    ),
                  })}
                >
                  {item.title}
                </Link>
              );
            })}
          </div>
          <div
            ref={containerRef}
            className={cn(
              'mb-[350px] w-full space-y-6 overflow-y-auto pb-4 md:mb-[215px] md:px-2',
              'has-[.hiring-team-form-container]:mb-[238px]',
              'md:has-[.hiring-team-form-container]:mb-[167px]',
              'has-[.hiring-team-form-container]:has-[.sticky-action-bar]:mb-[312px]',
              'md:has-[.hiring-team-form-container]:has-[.sticky-action-bar]:mb-[240px]',
              'has-[.communication-form]:mx-auto has-[.communication-form]:max-w-7xl'
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export function AdminSidebarLayout({
  title,
  subtitle,
  children,
  navLinks,
}: {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  navLinks: { title: string; href: string }[];
}) {
  return (
    <div className="space-y-6 md:w-full">
      <div className="space-y-0.5">
        <div className="flex max-w-7xl items-center justify-between">
          <h2 className="text-2xl font-bold tracking-tight">{title}</h2>
        </div>
        <p className="text-muted-foreground">{subtitle}</p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-3 xl:flex-row xl:space-x-12 xl:space-y-0">
        <aside>
          <InnerSidebar items={navLinks} />
        </aside>
        <div className="max-h-screen flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
}
