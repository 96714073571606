export const afterApplicationSubjectDutch =
  'Nieuwe sollicitatiegesprek voor {{position_name}} bij {{company_name}}';

export const afterInterviewSubjectDutch =
  '{{company_name}} | {{position_name}} Sollicitatiegesprek';

export const disqualifiedSubjectDutch =
  'Statusupdate sollicitatie voor {{position_name}} bij {{company_name}}';

export const applicationEmailDutch = `<p>Beste {{candidate_name}},</p><p><br/></p><p>Hartelijk dank voor je sollicitatie voor de functie {{position_name}} bij {{company_name}}. We zijn klaar voor je interview!</p><p><br/></p><p>Wanneer je er klaar voor bent, klik dan op de onderstaande link om je interview te starten.</p>`;

export const interviewCompletedEmailDutch = `<p>Beste {{candidate_name}},</p><p><br/></p><p>Bedankt voor het afronden van het sollicitatiegesprek voor {{position_name}} bij {{company_name}}. We zullen je gesprek beoordelen en zo snel mogelijk contact met je opnemen. Als je vragen hebt, kun je ons gerust bereiken via {{company_email}}.</p><p><br/></p><p>Wij waarderen je feedback! Neem alstublieft een moment om onze korte enquête over je sollicitatie-ervaring in te vullen: {{survey_link}}</p><p><br/></p><p>We kijken ernaar uit om mogelijk samen te werken en nemen binnenkort contact met je op.</p><p><br/></p><p>Met vriendelijke groet,<br/>{{company_name}}</p>`;

export const disqualifiedEmailDutch = `<p>Beste {{candidate_name}},</p><p><br/></p><p>Het spijt ons u te moeten informeren dat u bent gediskwalificeerd uit het sollicitatieproces voor {{position_name}}. Als u vragen heeft, kunt u contact met ons opnemen via {{company_email}}.</p><p><br/></p><p>Met vriendelijke groet,<br/>{{company_name}}</p>`;
