import { useAuth } from '@clerk/clerk-react';
import { fetcher } from '@/lib/fetcher';
import { convertToCamelCase } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { useOrganizationStore } from './useOrganization';
import { toast } from 'sonner';

export type UserHiringPosition = {
  id: number;
  title: string;
  slug: string;
  enabled: boolean;
};


export function useUserHiringPositions(organizationId: string) {
  const { getToken } = useAuth();
  const { selectedParentOrganization } = useOrganizationStore();

  const parentId = selectedParentOrganization?.id
  return useQuery({
    queryKey: ['user-hiring-positions', organizationId, parentId],
    queryFn: async () => {
      // For development, return mock data
      // if (import.meta.env.DEV) {
      //   return Promise.resolve(mockHiringPositions);
      // }

      const token = await getToken();
      // const url = `/organizations/${organizationId}/positions`;

      const url = `/positions/parent-organization/${parentId}?organization_id=${organizationId}`

      return fetcher
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return convertToCamelCase(res.data) as UserHiringPosition[];
        });
    },
  });
}

// Update user hiring positions with the new API structure
export function useUpdateUserHiringPositions() {
  const { getToken } = useAuth();

  const { selectedParentOrganization } = useOrganizationStore();
  const parentId = selectedParentOrganization?.id
  return async (_parentOrgId: string, positionActions: { positionId: number; action: 'add' | 'remove' }[]) => {
    try {
      const token = await getToken();

      // Convert to the format expected by the backend
      const actions = positionActions.map(pa => ({
        position_id: pa.positionId,
        action: pa.action
      }));

      const response = await fetcher.put(
        `/positions/parent-organization/${parentId}/user-hiring-positions`,
        { actions },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return { data: response.data, error: null };
    } catch {
      toast.error("Something went wrong");
    }
  };
}
