import {
  FormItem,
  FormField,
  FormMessage,
  FormControl,
} from '@/components/ui/form';
import { useFormContext } from 'react-hook-form';
import { PositionCommunicationFormValues } from '@/lib/usePositionCommunicationForm';
import { CommunicationMethod, MessageFields } from './types';
import { Input } from '@/components/ui/input';
import { InsertVariables } from './InsertVariables';
import { TextEditor } from '../../common/TextEditor';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import { containsEmoji } from './utils';
import { Textarea } from '@/components/ui/textarea';
import SMSCharacterControlFields from './SMSFields';
import { SMS_CHARACTER_PREVENT_INPUT_LIMIT } from './constants';

const INTERVIEW_LINK_CHARACTER_COUNT = 40;

const MessageField = ({
  messageFieldName,
  subjectFieldName,
  focusedField,
  onEditorFocus,
  onFieldFocus,
  onInsertVariableToField,
}: {
  messageFieldName: MessageFields;
  subjectFieldName: MessageFields;
  focusedField: MessageFields | null;
  onEditorFocus: () => void;
  onFieldFocus: (field: MessageFields) => void;
  onInsertVariableToField: (
    variable: string,
    ref: React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement | null>
  ) => void;
}) => {
  const { control, watch } = useFormContext<PositionCommunicationFormValues>();
  const isCommunicationMethodSMS =
    watch('selectedMethod') === CommunicationMethod.SMS;
  const quillRef = useRef<ReactQuill | null>(null);
  const smsMessageRef = useRef<HTMLTextAreaElement | null>(null);
  const subjectRef = useRef<HTMLInputElement | null>(null);

  const getCharacterCount = (value: string) => {
    let count = 0;

    if (!value.length) return count;

    if (messageFieldName === 'afterApplication') {
      count = value.length + INTERVIEW_LINK_CHARACTER_COUNT;
    } else {
      count = value.length;
    }

    return count;
  };

  return (
    <div className="mt-2 space-y-2">
      {!isCommunicationMethodSMS && (
        <FormField
          control={control}
          name={subjectFieldName}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={subjectRef}
                  onFocus={() => onFieldFocus(subjectFieldName)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      <FormField
        control={control}
        name={messageFieldName}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              {isCommunicationMethodSMS ? (
                <Textarea
                  ref={smsMessageRef}
                  value={field.value}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (containsEmoji(value)) {
                      return;
                    }

                    const valueLength = getCharacterCount(value);

                    const limit =
                      messageFieldName === 'afterApplication'
                        ? SMS_CHARACTER_PREVENT_INPUT_LIMIT -
                          INTERVIEW_LINK_CHARACTER_COUNT
                        : SMS_CHARACTER_PREVENT_INPUT_LIMIT;

                    if (valueLength >= SMS_CHARACTER_PREVENT_INPUT_LIMIT) {
                      field.onChange(value.slice(0, limit));
                      return;
                    }
                    field.onChange(e);
                  }}
                  onFocus={() => onFieldFocus(messageFieldName)}
                />
              ) : (
                <TextEditor
                  onFocus={onEditorFocus}
                  ref={quillRef}
                  onChange={(value, _delta, _source, editor) => {
                    const text = editor.getText();
                    if (text.length === 1) {
                      field.onChange('');
                    } else {
                      field.onChange(value);
                    }
                  }}
                  value={field.value}
                />
              )}
            </FormControl>
            {isCommunicationMethodSMS ? (
              <SMSCharacterControlFields
                characterCount={getCharacterCount(field.value)}
              />
            ) : (
              <FormMessage />
            )}
            <InsertVariables
              editorRef={quillRef}
              onVariableClick={(variable) => {
                onInsertVariableToField(
                  variable,
                  focusedField === messageFieldName ? smsMessageRef : subjectRef
                );
              }}
            />
          </FormItem>
        )}
      />
    </div>
  );
};

export default MessageField;
