import {
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useMemo } from 'react';

export function useAuthorization() {
  const { selectedParentOrganization } = useOrganizationStore();
  const { selectedOrganization } = useUserDetailStore();



  const hasAccessToBoardSettings = useMemo(() => {
    if (!selectedParentOrganization || !selectedOrganization) return false;

    const isOrgPrivate = selectedOrganization.isPrivate;
    const isAdmin = selectedParentOrganization?.memberRole === 'admin'
    if (isAdmin) return true;
    if (isOrgPrivate) return false;

    return true;
  }, [selectedParentOrganization, selectedOrganization]);

  return {
    privateBoards: selectedParentOrganization?.privateBoards,
    isAdmin: selectedParentOrganization?.memberRole === 'admin',
    isOrganizationPrivate: selectedOrganization?.isPrivate,
    hasAccessToBoardSettings: hasAccessToBoardSettings
  };
}