import { Loader } from '@/components/core/loader';
import DashboardPage from '@/pages/dashboard/page';
import { createFileRoute, ErrorComponent, Outlet, redirect } from '@tanstack/react-router'


import 'regenerator-runtime/runtime'
import { AdminSidebarLayout } from '@/components/core/position/layout';



export const Route = createFileRoute('/admin-panel')({
  beforeLoad: ({ context, location }) => {
    const isSignedIn = context?.auth?.isSignedIn;
    if (!isSignedIn) {
      throw redirect({
        to: '/signin',
        search: {
          redirect_url: location.href,
        },
      })
    }
  },
  // validateSearch: integrationStateSchema,
  component: AdminPanelComponent,

  errorComponent: ({ error }) => {
    console.log("error @organizations", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  }
})

function AdminPanelComponent() {
  // const { organizationId } = Route.useParams()



  return (
    <DashboardPage>
      <AdminSidebarLayout
        title="Veton Admin Panel"
        subtitle='With great power comes great responsibility'
        navLinks={[
          {
            title: "Dashboard",
            // href: "/new-position/description",
            href: `/admin-panel`
          },
          {
            title: "Organizations",
            href: "/admin-panel/organizations",
          },

          {
            title: "Create Organization",
            href: "/admin-panel/create-organization",
          },



        ]}
      >
        <Outlet />
      </AdminSidebarLayout>
    </DashboardPage>
  )
}