import { cn } from '@/lib/utils';
import DOMPurify from 'dompurify';

import { marked } from 'marked';

type TProps = {
  content: string;
  className?: string;
};
export function Markdown({ content, className }: TProps) {
  const parsedContent = marked.parse(content);
  if (!parsedContent) {
    return null;
  }
  return (
    <div
      className={cn('prose', className)}
      // @ts-ignore - ?? marked.parse claims to return promise but this works just fine
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parsedContent) }}
    />
  );
}
