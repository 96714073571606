import { MoreHorizontal } from 'lucide-react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  TeamMember,
  useToggleTeamMemberRole,
  useGetTeamList,
} from '@/fetchers/useOrganization';
import { Loader } from '../loader';
import { useMemo } from 'react';

type TProps = {
  searchTerm?: string;
};
export function TeamSettings({ searchTerm = '' }: TProps) {
  const { data: teamMembers, isFetching } = useGetTeamList();

  const updateMember = useToggleTeamMemberRole();

  const filteredMembers = useMemo(() => {
    if (!teamMembers) {
      return [];
    }

    if (!searchTerm) {
      return teamMembers;
    }

    return teamMembers.filter((member) => {
      const email = member?.email
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      const name = member?.fullName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      const role = member?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

      return email || name || role;
    });
  }, [teamMembers, searchTerm]);

  return (
    <Card className="flex flex-col justify-between overflow-hidden">
      <CardContent className="p-0">
        <ScrollArea className="h-[50vh]">
          <Table>
            <TableHeader className="overflow-hidden">
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Role</TableHead>
                <TableHead>
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <Loader />
              ) : (
                filteredMembers?.map((member) => (
                  <Row
                    key={member.id}
                    member={member}
                    updateMember={updateMember}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
      <CardFooter className="border-t border-gray-200 pt-4">
        <div className="text-xs text-muted-foreground">
          Showing <strong>{filteredMembers?.length || 0}</strong> members
        </div>
      </CardFooter>
    </Card>
  );
}

function Row({
  member,
  updateMember,
}: {
  member: TeamMember;
  updateMember: (data: {
    memberId: number;
    role?: 'admin' | 'member';
    remove?: boolean;
  }) => void;
}) {
  return (
    <TableRow>
      <TableCell className="flex items-center font-medium">
        <Avatar className="mr-2 size-10">
          <AvatarImage src={member?.imageUrl || ''} alt={'Team member'} />
          <AvatarFallback>
            {member?.email?.slice(0, 2)?.toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <p className="w-24 truncate text-sm text-muted-foreground sm:w-auto">
          {member?.fullName || member?.email?.split('@')[0] || ''}
        </p>
      </TableCell>

      <TableCell>{member?.email}</TableCell>

      <TableCell>
        <Badge
          className="-ml-1 flex w-20 items-center justify-center"
          variant={'outline'}
        >
          {member?.role?.toUpperCase()}
        </Badge>
      </TableCell>

      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                updateMember({
                  memberId: member?.id,
                  role: member?.role === 'admin' ? 'member' : 'admin',
                });
              }}
            >
              {member?.role === 'admin'
                ? 'Switch to Member'
                : 'Switch to Admin'}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                updateMember({
                  memberId: member?.id,
                  remove: true,
                });
              }}
              className="text-red-500"
            >
              Remove
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}
