export const afterApplicationSubjectGerman =
  'Neues Vorstellungsgespräch für {{position_name}} bei {{company_name}}';

export const afterInterviewSubjectGerman =
  '{{company_name}} | Vorstellungsgespräch für {{position_name}}';

export const disqualifiedSubjectGerman =
  'Aktualisierung des Bewerbungsstatus für {{position_name}} bei {{company_name}}';

export const applicationEmailGerman = `<p>Sehr geehrte/r {{candidate_name}},</p><p><br/></p><p>Vielen Dank für Ihre Bewerbung für die Position {{position_name}} bei {{company_name}}. Wir sind bereit für Ihr Vorstellungsgespräch!</p><p><br/></p><p>Sobald Sie bereit sind, klicken Sie bitte auf den untenstehenden Link, um Ihr Gespräch zu starten.</p>`;

export const interviewCompletedEmailGerman = `<p>Sehr geehrte/r {{candidate_name}},</p><p><br/></p><p>Vielen Dank, dass Sie das Vorstellungsgespräch für die Position {{position_name}} bei {{company_name}} abgeschlossen haben. Wir werden Ihr Gespräch prüfen und uns bei Ihnen melden. Sollten Sie Fragen haben, können Sie uns gerne unter {{company_email}} kontaktieren.</p><p><br/></p><p>Wir schätzen Ihr Feedback sehr! Bitte nehmen Sie sich einen Moment Zeit, um unsere kurze Umfrage zu Ihrer Bewerbungserfahrung auszufüllen: {{survey_link}}</p><p><br/></p><p>Wir freuen uns auf die Möglichkeit, mit Ihnen zusammenzuarbeiten, und werden uns bald mit Ihnen in Verbindung setzen.</p><p><br/></p><p>Mit freundlichen Grüßen,<br/>{{company_name}}</p>`;

export const disqualifiedEmailGerman = `<p>Sehr geehrte/r {{candidate_name}},</p><p><br/></p><p>Leider müssen wir Ihnen mitteilen, dass Sie aus dem Bewerbungsprozess für die Position {{position_name}} ausgeschieden sind. Wenn Sie Fragen haben, können Sie uns gerne unter {{company_email}} kontaktieren.</p><p><br/></p><p>Mit freundlichen Grüßen,<br/>{{company_name}}</p>`;
