import { useFieldArray, useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';

import { Button } from '@/components/ui/button';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { PositionAIConfigFormValues } from '@/lib/usePositionAiConfigForm';
import { useEffect, useState } from 'react';
import { XIcon } from 'lucide-react';
import InterviewerCard from './interviewer-card';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Loader } from '../../loader';
import { useSidebar } from '@/components/ui/sidebar';
import { Alert } from '../../alert';
import { useParams } from '@tanstack/react-router';
import { RouterParams } from '@/main';
import { useGetOrganizationAIInterviewers } from '@/fetchers/useOrganization';
import { VetonIndicator } from '../../common/VetonIndicator';
import { BetaBadge } from '@/components/ui/beta-badge';

export function PositionAIConfigForm({
  language,
  isFetchingLanguage,
}: {
  language?: string;
  isFetchingLanguage: boolean;
}) {
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const { control, watch } = useFormContext<PositionAIConfigFormValues>();
  const [noQuestionsExist, setNoQuestionsExist] = useState(false);
  const { open } = useSidebar();
  const { data: interviewers = [], isFetching: isFetchingInterviewers } =
    useGetOrganizationAIInterviewers({
      organizationId,
      language,
    });

  const {
    fields: additionalQuestions,
    append,
    remove,
  } = useFieldArray({
    name: 'additionalQuestions',
    control,
  });
  const additionalQuestionsWatched = watch('additionalQuestions');

  const isRestrictQuestionsDisabled =
    noQuestionsExist && additionalQuestionsWatched?.[0]?.value.length === 0;
  const isInitialized = watch('isInitialized');

  useEffect(() => {
    if (
      additionalQuestions.length === 1 &&
      additionalQuestions[0].value === ''
    ) {
      setNoQuestionsExist(true);
    } else {
      setNoQuestionsExist(false);
    }
  }, [additionalQuestions]);

  if (isFetchingLanguage || isFetchingInterviewers || !isInitialized) {
    return <Loader className="static h-[50vh] bg-transparent" />;
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <FormField
          control={control}
          name="aiVoiceModelId"
          render={({ field }) => (
            <>
              <FormLabel>Select AI interviewer</FormLabel>
              <RadioGroup
                onValueChange={(value) => field.onChange(Number(value))}
                value={field.value?.toString()}
                className={cn(
                  'grid grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-4',
                  !open && 'md:grid-cols-3'
                )}
              >
                {interviewers.map((interviewer) => (
                  <FormItem key={interviewer.id}>
                    <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                      <FormControl>
                        <RadioGroupItem
                          value={interviewer.id.toString()}
                          className="sr-only"
                        />
                      </FormControl>
                      <InterviewerCard interviewer={interviewer} />
                    </FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            </>
          )}
        />
      </div>

      <div className='p-4 border border-gray-200 rounded-lg'>
        <div>
          {additionalQuestions.map((question, index) => (
            <FormField
              control={control}
              key={question.id}
              name={`additionalQuestions.${index}.value`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={cn(index !== 0 && 'sr-only')}>
                    Must ask questions <VetonIndicator />
                  </FormLabel>
                  <FormDescription className={cn(index !== 0 && 'sr-only')}>
                    Our AI interviewers are robust enough to identify the right
                    questions. However if there are must ask questions, you can
                    add them here.
                  </FormDescription>
                  <FormControl>
                    <div className="relative flex max-w-xl">
                      <Input
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Do you believe you can be successful working remotely?"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            append({ value: '' });
                          }
                        }}
                      />

                      <Button
                        variant="ghost"
                        type="button"
                        className="absolute right-[1px] top-[1px] h-[38px] bg-gray-50/90"
                        onClick={(event) => {
                          event.preventDefault();
                          // check if there is only one question
                          if (additionalQuestions.length === 1) {
                            // if only one clear its value
                            field.onChange('');
                            setNoQuestionsExist(true);
                          } else {
                            remove(index);
                          }
                        }}
                      >
                        <XIcon className="size-5" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}

          <Button
            type="button"
            variant="outline"
            size="sm"
            className="mt-2"
            onClick={() => append({ value: '' })}
          >
            Add more questions
          </Button>
        </div>



        <FormField
          control={control}
          name="restrictQuestions"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger
                    onClick={(event) => event.preventDefault()}
                    className="w-full"
                  >
                    <Tabs
                      value={field.value}
                      className={cn(isRestrictQuestionsDisabled && 'opacity-50')}
                      onValueChange={field.onChange}
                    >
                      <div className="flex flex-col items-start md:flex-row md:items-center md:justify-between">
                        <FormLabel className="mb-2 md:mb-0 mt-4">
                          Restrict Questions (Recommended for specific use cases
                          only)
                        </FormLabel>
                        <TabsList className="md:self-end">
                          <TabsTrigger
                            disabled={isRestrictQuestionsDisabled}
                            value="disabled"
                          >
                            Disabled
                          </TabsTrigger>
                          <TabsTrigger
                            disabled={isRestrictQuestionsDisabled}
                            value="enabled"
                          >
                            Enabled
                          </TabsTrigger>
                        </TabsList>
                      </div>
                      <TabsContent value="disabled" className="w-full">
                        <FormDescription className="w-2/4 text-left">
                          {/* {field.value === "enabled" && ()} */}
                          If you want the AI to ask{' '}
                          <span className="font-bold">only</span> the questions
                          you have added above, you can enable this feature.
                        </FormDescription>
                      </TabsContent>
                      <TabsContent className="mt-4 w-full" value="enabled">
                        <Alert
                          variant="outline"
                          severity="warning"
                          title="Heads up!"
                          description="Interviewer will only ask the questions you have added above. No other questions or follow-ups will be asked."
                        />
                      </TabsContent>
                    </Tabs>
                  </TooltipTrigger>
                  {isRestrictQuestionsDisabled && (
                    <TooltipContent>
                      <p>Add custom questions to enable this feature</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className='p-4 border border-gray-200 rounded-lg '>
        <FormField
          control={control}
          name="evaluationCriteria"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Evaluation Criteria <VetonIndicator /> <BetaBadge className='float-right' /></FormLabel>
              <FormDescription>
                Optionally provide custom evaluation criteria for the AI interviewer
                This helps the AI focus on specific qualities or skills during the interview.

                {/* You can also give add some qualifying criteria for the interview you didn't include in the Job Description. */}
              </FormDescription>
              <FormControl>
                <Textarea
                  placeholder="Example: Focus on screening must-have qualifications such as 'legally authorized to work in the US', 'minimum of 2 years Python experience', or 'willingness to work EST business hours'."
                  className="min-h-28 resize-y"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </>
  );
}
