import { Markdown } from '@/components/core/common/Markdown';
import { Loader } from '@/components/core/loader';
import { Badge } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { SelectSeparator } from '@/components/ui/select';

import {
  ApplicationDetail,
  useGetApplication,
  useGetApplicationMessages,
  useGetMessageResponseRecording,
  useSendInterviewMutation,
} from '@/fetchers/useApplication';
import { cn, parseSalaryToDisplay } from '@/lib/utils';
import {
  createRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  DownloadIcon,
  Ellipsis,
  NotepadText,
  PlayCircle,
  User2Icon,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import pendingImage from '@/assets/svg/pending.svg';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ASSISTANT_TYPES } from '@/constants/interview';
import { Tabs } from '@/components/ui/tabs';
import { Applicant, useApplicants } from '@/lib/useApplicants';
import { z } from 'zod';
import { toast } from 'sonner';
import { Breadcrumb } from '@/components/core/breadcrumb';
import {
  useCommunicationForm,
  usePaginatedPositionsQuery,
} from '@/fetchers/usePosition';
import { Route as positionManageRoute } from './organizations.$organizationId.positions.manage.$slug.$step';
import useDisqualifyCandidateModal from '@/hooks/useDisqualifyCandidateModal';
import useResetInterviewModal from '@/hooks/useResetInterviewModal';
import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/applications/constants';
import { PhoneLineType } from '@/fetchers/types';
import { Alert } from '@/components/core/alert';
import { CommunicationMethod } from '@/components/core/position/communication/types';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { ItemAction } from '@/pages/dashboard/components/item-list';
import ApplicationTimeline from '@/components/core/applications/ApplicationTimeline';
import { CopyableField } from '@/components/core/CopyableField';
import InfoTooltip from '@/components/core/InfoTooltip';
import { useUpdateMarginWithGlobalAlert } from '@/hooks/useUpdateMarginWithGlobalAlert';
import DetailedResumeAnalysis from '@/components/core/applications/DetailedResumeAnalysis';
import CheatDetectionDashboard from '@/components/core/applications/CheatDetectionDashboard';
import useContinueInterviewModal from '@/hooks/useContinueInterviewModal';
import { generateDOCX } from '@/lib/generateDocx';

const searchSchema = z.object({
  selectedTab: z.string().optional(),
  searchQuery: z.string().optional(),
});

export const Route = createRoute({
  path: '/$id',
  getParentRoute: () => positionManageRoute,
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  validateSearch: searchSchema,
  pendingComponent: () => {
    return <Loader />;
  },
  component: CandidateInfo,
});

enum TabValue {
  INTERVIEW_ANALYSIS = 'interview_analysis',
  RESUME_ANALYSIS = 'resume_analysis',
  CHEAT_DETECTION = 'cheat_detection',
  QUESTIONS = 'questions',
  RECORDINGS = 'recordings',
  RESUME = 'resume',
  COVER_LETTER = 'cover-letter',
}

type TabItem = {
  title: string;
  value: TabValue;
};
const rightPanelItems: TabItem[] = [
  { title: 'Interview Analysis', value: TabValue.INTERVIEW_ANALYSIS },
  { title: 'Resume Analysis', value: TabValue.RESUME_ANALYSIS },
  { title: 'Cheat Detection', value: TabValue.CHEAT_DETECTION },
  { title: 'Custom Questions', value: TabValue.QUESTIONS },
  { title: 'Recordings', value: TabValue.RECORDINGS },
  { title: 'Resume', value: TabValue.RESUME },
  { title: 'Cover Letter', value: TabValue.COVER_LETTER },
];

export function CandidateInfo() {
  const containerRef = useUpdateMarginWithGlobalAlert();
  const { id, organizationId, slug } = Route.useParams();
  const [selectedTab, setSelectedTab] = useState<TabValue>(
    TabValue.INTERVIEW_ANALYSIS
  );
  const { data: application, isFetching: isFetchingApplication } =
    useGetApplication({
      applicationId: Number(id),
    });
  const { data: messages = [], isFetching: isFetchingMessages } =
    useGetApplicationMessages({
      applicationId: Number(id),
      enabled: selectedTab === 'recordings',
    });

  const { data: positions, isFetching: isFetchingPositionData } =
    usePaginatedPositionsQuery({
      organizationId,
      slug,
    });
  const { data: communicationForm } = useCommunicationForm({
    slug,
  });
  const isCommunicationMethodSMS =
    communicationForm?.selectedMethod === CommunicationMethod.SMS;
  const showPhoneNumberInvalidAlert =
    isCommunicationMethodSMS && !application?.phoneIsValid;

  const positionData = positions?.[0];

  const interview = application?.interviews?.[0];
  const [messageId, setMessageId] = useState<string | undefined>(undefined);
  const { data: recording } = useGetMessageResponseRecording({
    messageId: messageId || '',
    applicationId: Number(id),
  });

  const legacyVideoDisplay = !interview?.videoRecordingUrl && !interview?.audioRecordingUrl;

  const navigate = useNavigate();
  const { searchQuery = '' } = Route.useSearch();
  const sendInterviewMutation = useSendInterviewMutation();
  const { applicants } = useApplicants({
    searchQuery,
  });
  const { selectedOrganization } = useUserDetailStore();

  const { handleOpenResetInterviewModal, ResetInterviewModal } =
    useResetInterviewModal();
  const { handleOpenDisqualifyCandidateModal, DisqualifyCandidateModal } =
    useDisqualifyCandidateModal();

  const { handleOpenContinueInterviewModal, ContinueInterviewModal } = useContinueInterviewModal();

  useEffect(() => {
    if (!interview) return;
    if (!interview?.assistantType) return;

    if (interview?.assistantType !== ASSISTANT_TYPES.RESTRICTED) return;

    setSelectedTab(TabValue.QUESTIONS);
  }, [interview]);

  const currentIndex = applicants?.findIndex(
    (applicant) => applicant?.application?.id === Number(id)
  );

  const nextApplicant =
    currentIndex !== undefined &&
      currentIndex >= 0 &&
      currentIndex < applicants.length - 1
      ? applicants[currentIndex + 1]
      : undefined;

  const previousApplicant =
    currentIndex !== undefined && currentIndex > 0
      ? applicants[currentIndex - 1]
      : undefined;

  function handleNextApplicant(applicant?: Applicant) {
    if (!applicant) return;
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      params: {
        organizationId,
        slug,
        step: 'applications',
        id: applicant.application?.id.toString(),
      },
    });
  }

  function handleRightPanelItemsFilter(item: TabItem) {
    // item => item.value === TabValue.RESUME_ANALYSIS ? !!application?.detailedResume : true

    if (item.value === TabValue.RESUME_ANALYSIS) {
      return !!application?.detailedResume;
    }

    if (item.value === TabValue.CHEAT_DETECTION) {
      return !!interview?.cheatDetection;
    }

    return true
  }

  function handleReportCardClick() {
    const resumeAnalysisVerdict = application?.resumeAnalysisVerdict ? resumeVerdictValues[application?.resumeAnalysisVerdict] : "N/A"
    const content = `# Resume Analysis (${resumeAnalysisVerdict})
    
## Summary
${application?.resumeAnalysis}

# Interview Analysis (${interview?.finalVerdict ? interviewVerdictValues[interview?.finalVerdict] : "N/A"})

## Summary
${interview?.analysisSummary}

## Detailed Analysis
${interview?.analysis || ""}
`
    generateDOCX(
      content || '',
      {
        position: `${positionData?.title} at ${selectedOrganization?.name}`,
        name: application?.name,
        phone: application?.phone,
        email: application?.email,
        finalVerdict: interview?.finalVerdict ? interviewVerdictValues[interview?.finalVerdict] : 'N/A',
        completedAt: interview?.completedAt || ""
      },
      // getDominantColor(selectedOrganization?.color)?.split("#")?.[1] || 'E2E8F0'
      "0558EE"
    ).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${application?.name || 'report'}.docx`;
      a.click();
      URL.revokeObjectURL(url);
    })
  }

  const phoneLineType = application?.phoneLineType;

  const firstInterviewUrl = application?.interviews?.find(
    (interview) => interview?.interviewUrl
  )?.interviewUrl;
  const getDropdownActions = () => {
    const actions: ItemAction[] = [];

    if (firstInterviewUrl) {
      actions.push({
        title: 'Copy Interview Url',
        onClick: () => {
          navigator.clipboard.writeText(firstInterviewUrl);
          toast.success('Interview URL copied to clipboard');
        },
      });
    }

    if ((application?.interviews || []).length === 0) {
      actions.push({
        title:
          showPhoneNumberInvalidAlert ||
            (phoneLineType && phoneLineType !== PhoneLineType.MOBILE)
            ? 'Send interview via email'
            : 'Send interview',
        onClick: async () => {
          if (!application?.id) return;
          await sendInterviewMutation({
            applicationId: application.id,
          });
          window.location.reload()
        },
      });
    }

    if ((application?.interviews || []).length > 0) {
      actions.push({
        title: 'Allow Continue Interview',
        onClick: () => {
          if (!application) return;
          handleOpenContinueInterviewModal(application?.interviews?.[0]?.id);
        },
        customClasses: 'cursor-pointer',
      });

      actions.push({
        title: 'Reset interview',
        onClick: () => {
          if (!application) return;
          handleOpenResetInterviewModal(application?.interviews?.[0]?.id);
        },
        customClasses: 'font-bold cursor-pointer text-red-500',
      });


    }

    actions.push({
      title: 'Disqualify',
      onClick: () => {
        if (!application) return;
        handleOpenDisqualifyCandidateModal(application?.id);
      },
      customClasses: 'font-bold cursor-pointer text-red-500',
    });

    return actions;
  };

  if (isFetchingApplication || isFetchingPositionData) {
    return <Loader />;
  }

  return (
    <>
      {DisqualifyCandidateModal}
      {ResetInterviewModal}
      {ContinueInterviewModal}

      <Breadcrumb
        items={[
          {
            title: positionData?.title ?? '',
            href: `/organizations/${organizationId}/positions/manage/${slug}/${selectedOrganization?.integrationName ? 'communication' : 'details'}`,
          },
          {
            title: 'Applications',
            href: `/organizations/${organizationId}/positions/manage/${slug}/applications`,
          },
          {
            title: application?.name ?? '',
          },
        ]}
      />
      <Tabs defaultValue="all" className="flex h-full flex-col">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="no-print flex items-center px-4 py-2">
            <h1 className="text-xl font-bold">Candidate Information</h1>
          </div>

          <div className="no-print flex items-center gap-2 p-2">
            <Button
              variant={'outline'}
              className={cn(!previousApplicant && 'hidden')}
              onClick={() => handleNextApplicant(previousApplicant)}
            >
              <ChevronLeft className="size-4" />
              <p>{previousApplicant?.title}</p>
            </Button>
            <Button
              variant={'outline'}
              className={cn(!nextApplicant && 'hidden')}
              onClick={() => handleNextApplicant(nextApplicant)}
            >
              <p>{nextApplicant?.title}</p>
              <ChevronRight className="size-4" />
            </Button>
            <Button variant={'outline'} onClick={handleReportCardClick}>
              <DownloadIcon className="mr-1 size-4" />
              Report Card
            </Button>
          </div>
        </div>
        <SelectSeparator />

        <div
          className="mb-5 flex h-full flex-col gap-4 overflow-y-auto xl:flex-row xl:overflow-y-hidden"
          ref={containerRef}
        >
          <div className="flex flex-1 flex-shrink-0 flex-col overflow-y-auto p-2 xl:w-1/2">
            <div className="relative flex flex-col gap-4 rounded-lg p-4 shadow-lg lg:flex-row xl:gap-0">
              <div className="absolute right-4 top-4">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon" className="ml-2">
                      <Ellipsis />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-42">
                    <DropdownMenuGroup>
                      {getDropdownActions().map((action, idx) => (
                        <DropdownMenuItem
                          key={idx}
                          onClick={action.onClick}
                          role="button"
                          className={cn('cursor-pointer', action.customClasses)}
                        >
                          {action.title}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="flex w-1/2 flex-col">
                <div className="flex flex-col items-center self-start">
                  <Avatar className="size-[100px] text-4xl text-gray-400">
                    {application?.userAvatar && (
                      <AvatarImage
                        src={application.userAvatar}
                        alt="User Avatar"
                      />
                    )}
                    <AvatarFallback>
                      <User2Icon className="size-12" />{' '}
                    </AvatarFallback>
                  </Avatar>
                  <p className="text-xl font-bold">{application?.name}</p>
                </div>

                <InformationTable
                  application={application}
                  showPhoneNumberInvalidAlert={showPhoneNumberInvalidAlert}
                  phoneLineType={phoneLineType}
                />
              </div>
              {application && (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-1">
                    <span className="text-lg font-semibold">
                      Application Timeline
                    </span>
                    <InfoTooltip content="The date and time are displayed according to your timezone." />
                  </div>
                  <ApplicationTimeline application={application} />
                </div>
              )}
            </div>

            {interview?.analysisSummary && (
              <div className="mb-12 rounded-lg p-4 shadow-lg">
                <div className="mb-6 flex items-center justify-between">
                  <h2 className="text-2xl font-bold">Interview Summary</h2>
                  {interview?.finalVerdict && (
                    <Badge>
                      {interviewVerdictValues[interview?.finalVerdict]}
                    </Badge>
                  )}
                </div>
                <p>{interview?.analysisSummary}</p>
              </div>
            )}

            {application?.resumeAnalysis && (
              <div className="mb-12 rounded-lg p-4 shadow-lg">
                <div className="mb-6 flex items-center justify-between">
                  <h2 className="text-2xl font-bold">Resume Analysis</h2>
                  <Badge>
                    {resumeVerdictValues[application?.resumeAnalysisVerdict]}
                  </Badge>
                </div>
                <p>{application?.resumeAnalysis}</p>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-1 flex-shrink-0 flex-col overflow-y-hidden rounded-lg shadow-lg xl:w-1/2">
            <nav className="sticky top-0 z-10 flex space-x-2 bg-white p-4 ">
              <div className='overflow-x-auto w-full flex '>
                {rightPanelItems.filter(handleRightPanelItemsFilter).map((item) => {
                  if (
                    item.value === 'interview_analysis' &&
                    interview?.assistantType === ASSISTANT_TYPES.RESTRICTED
                  )
                    return null;
                  if (
                    item.value === 'questions' &&
                    !interview?.additionalQuestionsAndAnswers
                  )
                    return null;
                  return (
                    <Button
                      key={item.value}
                      // @ts-ignore
                      // to={item.href}
                      onClick={() => setSelectedTab(item.value)}
                      variant="ghost"
                      className={cn(
                        buttonVariants({ variant: 'ghost' }),
                        // "pathname" === item.href
                        item.value === selectedTab
                          ? 'bg-muted hover:bg-muted'
                          : 'hover:bg-transparent hover:underline',
                        'justify-start'
                      )}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </div>
            </nav>
            <Separator className="sticky top-[65px]" />

            {selectedTab === 'interview_analysis' && (
              <div className="p-4 overflow-y-auto">
                <h2 className="mb-4 text-2xl font-bold">Interview Analysis</h2>
                {interview?.analysis && (
                  <Markdown content={interview?.analysis} />
                )}
                {!interview?.analysis && (
                  <EmptyTabView
                    title="No analysis available"
                    subtitle="Awaiting applicant to finish its interview to analyze"
                    image={pendingImage}
                  />
                )}
              </div>
            )}

            {selectedTab === TabValue.RESUME_ANALYSIS &&
              application?.detailedResume && (
                <div className="p-4 overflow-y-auto">
                  <DetailedResumeAnalysis
                    data={application?.detailedResume}
                  />
                </div>
              )}

            {selectedTab === TabValue.CHEAT_DETECTION && interview?.cheatDetection && (
              <div className="p-4 overflow-y-auto">
                <CheatDetectionDashboard analysisData={{
                  ...interview?.cheatDetection,
                  candidateName: application?.name || "",
                  position: positionData?.title || "",
                }} />
              </div>
            )}

            {selectedTab === TabValue.QUESTIONS && (
              <div className="p-4 overflow-y-auto">
                {interview?.additionalQuestionsAndAnswers && (
                  <div className="prose">
                    {interview?.additionalQuestionsAndAnswers.map(
                      (question, idx) => (
                        <div key={idx} className="mb-4">
                          <h3 className="text-lg font-bold">
                            {question.question}
                          </h3>
                          <p>{question.answer}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
                {!interview?.additionalQuestionsAndAnswers && (
                  <EmptyTabView
                    title="No analysis available"
                    subtitle="Awaiting applicant to finish its interview to analyze"
                    image={pendingImage}
                  />
                )}
              </div>
            )}

            {selectedTab === 'recordings' &&
              (isFetchingMessages ? (
                <Loader className="static h-1/2 bg-transparent" />
              ) : messages.length > 1 ? (
                <div className='p-4 overflow-y-auto'>
                  {legacyVideoDisplay && interview?.completedAt && (
                    <Alert
                      variant="outline"
                      severity="info"
                      title="Processing media..."
                      description="Recording is being processed and will be available shortly."
                    />

                  )}

                  {interview?.audioRecordingUrl && (
                    interview?.audioRecordingUrl.split(",").map((url, idx) => {
                      return (
                        <audio
                          controls
                          src={url}
                          className="mb-2 mt-4 w-full px-4"
                          key={idx}
                        >
                          Your browser does not support the
                          <code>audio</code> element.
                        </audio>
                      )
                    })
                    // <audio
                    //   controls
                    //   src={interview?.audioRecordingUrl}
                    //   className="mb-2 mt-4 w-full px-4"
                    // >
                    //   Your browser does not support the
                    //   <code>audio</code> element.
                    // </audio>
                  )}
                  {interview?.videoRecordingUrl && interview?.videoRecordingUrl !== "preparing" && (
                    <video
                      controls
                      src={interview?.videoRecordingUrl}
                      className="mb-2 mt-4 w-full px-4"
                    >
                      Your browser does not support the
                      <code>video</code> element.
                    </video>
                  )}
                  {messages.map((message, idx) => {
                    return (
                      <div className="p-4" key={message?.id || idx}>
                        <div className="flex items-center justify-between">
                          <h2 className="mb-4 text-lg font-bold">
                            {message?.role === 'user'
                              ? application?.name
                              : 'HR Manager'}
                            :
                          </h2>

                          {legacyVideoDisplay && message?.role === 'user' &&
                            !interview?.audioRecordingUrl && (
                              <>
                                {(!messageId ||
                                  messageId !== messages[idx - 1]?.id) && (
                                    <Button
                                      variant="outline"
                                      onClick={() =>
                                        setMessageId(messages?.[idx - 1]?.id)
                                      }
                                    >
                                      <PlayCircle />
                                    </Button>
                                  )}

                                {messageId &&
                                  messageId === messages?.[idx - 1]?.id ? (
                                  <Button
                                    variant="outline"
                                    onClick={() => setMessageId(undefined)}
                                  >
                                    <NotepadText />
                                  </Button>
                                ) : null}
                              </>
                            )}

                        </div>

                        <Markdown content={message?.content} />

                        {legacyVideoDisplay && message.role === 'user' &&
                          (messageId !== messages?.[idx - 1]?.id ? null : ( // <Markdown content={content} />
                            <video
                              autoPlay
                              controls
                              playsInline
                              src={recording?.url}
                              className="mb-2 mt-4 h-[350px] w-full px-4"
                            >
                              Your browser does not support the
                              <code>video</code> element.
                            </video>
                          ))}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <EmptyTabView
                  title="No recordings available"
                  subtitle="Awaiting applicant to start the interview to record messages"
                  image={pendingImage}
                />
              ))}

            {selectedTab === 'resume' && (
              <div className="p-4 overflow-y-auto">
                {application?.resumeUrl ? (
                  <iframe
                    src={application?.resumeUrl?.split('?')?.[0] || ''}
                    width="100%"
                    height="85vh"
                    style={{
                      height: '85vh',
                    }}
                  ></iframe>
                ) : (
                  <EmptyTabView
                    title="No resume available"
                    subtitle="Candidate didn't provide a resume."
                    image={pendingImage}
                  />
                )}
              </div>
            )}

            {selectedTab === 'cover-letter' && (
              <div className="p-4 overflow-y-auto">
                {application?.coverLetterUrl ? (
                  <iframe
                    src={application?.coverLetterUrl}
                    width="100%"
                    height="85vh"
                    style={{
                      height: '85vh',
                    }}
                  ></iframe>
                ) : (
                  <EmptyTabView
                    title="No cover letter available"
                    subtitle="Candidate didn't provide a cover letter."
                    image={pendingImage}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Tabs>
    </>
  );
}
const isUndefined = (value: string) => value === 'undefined';

function handleUndefinedValue(value: string | undefined) {
  if (value === 'undefined') {
    return '-';
  } else {
    return value;
  }
}

const extractLinkedInUsername = (url: string) => {
  // This pattern matches:
  // - Optional protocol (http/https)
  // - Optional www
  // - linkedin.com domain
  // - /in/ path
  // - Captures the username (allows letters, numbers, hyphens, dots, underscores)
  // - Ignores everything after username (query params, additional paths, etc)
  const regex =
    /(?:https?:)?(?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-._]+)/;

  const match = url.match(regex);
  return match ? match[1] : null;
};

function InformationTable({
  application,
  showPhoneNumberInvalidAlert,
  phoneLineType,
}: {
  application?: ApplicationDetail;
  showPhoneNumberInvalidAlert: boolean;
  phoneLineType?: PhoneLineType;
}) {
  if (!application) return null;
  return (
    <div className="mt-4 w-full space-y-4 break-words lg:w-[75%]">
      {application?.email && (
        <CopyableField text={application?.email} title="Email" />
      )}

      {application?.phone && (
        <div className="flex flex-wrap items-center gap-2">
          <CopyableField text={application?.phone} title="Phone" />
          {showPhoneNumberInvalidAlert && (
            <div className="relative min-w-0">
              <Alert
                severity="error"
                variant="ghost"
                description="Invalid phone number"
              />
            </div>
          )}
          {phoneLineType === PhoneLineType.LANDLINE && (
            <div className="min-w-0">
              <Alert
                severity="error"
                variant="ghost"
                description="This phone cannot receive SMS messages."
              />
            </div>
          )}
        </div>
      )}

      {application?.linkedinProfile && (
        <>
          {isUndefined(application?.linkedinProfile) ? (
            <div className="flex flex-col">
              <span className="font-semibold text-muted-foreground">
                Linkedin Profile
              </span>
              <p>{`-`}</p>
            </div>
          ) : (
            <CopyableField
              text={application?.linkedinProfile}
              title="Linkedin Profile"
              formatText={(text) => {
                return extractLinkedInUsername(text) || text;
              }}
            />
          )}
        </>
      )}

      {application?.additionalInfo && (
        <div className="flex flex-col">
          <span className="font-semibold text-muted-foreground">
            Additional Information
          </span>
          <p>{handleUndefinedValue(application?.additionalInfo)}</p>
        </div>
      )}
      {application?.desiredSalary && application?.salaryCurrency && (
        <div className="flex flex-col">
          <span className="mr-4 font-semibold text-muted-foreground">
            Desired Salary
          </span>
          {application?.desiredSalary === 'undefined' ? (
            <p>{`-`}</p>
          ) : (
            <p>
              {parseSalaryToDisplay(
                Number(application?.desiredSalary),
                application?.salaryCurrency
              )}{' '}
              / {application?.salaryType}
            </p>
          )}
        </div>
      )}

      {application?.education && (
        <div className="flex flex-col">
          <span className="mr-4 font-semibold text-muted-foreground">
            Education
          </span>
          <p>{handleUndefinedValue(application?.education)}</p>
        </div>
      )}
    </div>
  );
}

function EmptyTabView({
  title,
  subtitle,
  image,
}: {
  title: string;
  subtitle: string;
  image: string;
}) {
  return (
    <div className="mt-12 flex flex-col items-center space-y-8">
      <p className="text-xl font-bold"> {title}</p>
      <img
        src={image}
        alt="pending image"
        className="rounded-lg"
        style={{
          width: '50%',
          height: 'auto',
        }}
      />
      <p className="mt-12 max-w-xs text-center">{subtitle}</p>
    </div>
  );
}
