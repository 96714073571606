import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useNavigate } from '@tanstack/react-router';
import ImageCropper from '../common/ImageCropper';
import { RequiredStar } from '../common/RequiredStar';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ParentOrganization,
  useCreateParentOrganization,
  useUpdateParentOrganization,
} from '@/fetchers/useOrganization';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';
import { Checkbox } from '@/components/ui/checkbox';
import { Alert } from '../alert';

const displayFormSchema = z.object({
  logo: z.union([z.string(), z.instanceof(File)]).optional(),
  name: z.string().min(1, 'Company name is required.'),
  webUrl: z
    .string()
    .url({
      message: 'Please enter a valid website domain.',
    })
    .optional(),
  privateBoards: z.boolean().optional().default(false),
});

type CreateParentOrganizationValues = z.infer<typeof displayFormSchema>;

const defaultValues: Partial<CreateParentOrganizationValues> = {
  logo: '',
  name: '',
  webUrl: '',
  privateBoards: false,
};

type TProps = {
  organization?: ParentOrganization;
  layoutClasses?: string;
};
export function CreateParentOrganizationForm({
  organization,
  layoutClasses = '',
}: TProps) {
  const navigate = useNavigate();
  const update = useUpdateParentOrganization();
  const create = useCreateParentOrganization();



  const form = useForm<CreateParentOrganizationValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      name: organization?.name || defaultValues.name,
      logo: organization?.logoUrl || defaultValues.logo,
      webUrl: organization?.webUrl || defaultValues.webUrl,
      privateBoards: !!organization?.privateBoards || defaultValues.privateBoards,
    },
  });


  async function onSubmit(data: CreateParentOrganizationValues) {
    const payload = {
      name: data.name,
      logo: typeof data.logo === 'string' ? undefined : data.logo,
      webUrl: data.webUrl,
      privateBoards: data.privateBoards,
    };
    let res;
    if (organization) {
      res = await update(payload);
    } else {
      res = await create(payload);
    }
    if (res?.id) {
      form.reset();
      navigate({
        to: '/organizations',
      });
    } else {
      toast.error('Something went wrong. Please try again.');
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <Card
          className={cn(
            'w-full max-w-[500px] overflow-hidden sm:w-[400px] sm:px-0 md:w-[450px]',
            layoutClasses
          )}
        >
          <CardHeader>
            <CardTitle>
              {organization ? 'Update' : 'Create'} your organization
            </CardTitle>
            {/* <CardDescription>{organization ? "Update" : "Create"} your organization</CardDescription> */}
          </CardHeader>
          <CardContent className="max-w-[450px] overflow-hidden">
            <FormField
              control={form.control}
              name="logo"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Company Logo (260 x 260)</FormLabel>
                  <ImageCropper
                    onChange={field.onChange}
                    value={field.value}
                    layoutClasses="size-[160px]"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="name"
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="mt-4 w-full">
                  <FormLabel aria-required>
                    Company Name
                    <RequiredStar />
                  </FormLabel>
                  <FormDescription></FormDescription>
                  <FormControl>
                    <Input placeholder="Acme Inc." {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="webUrl"
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="mt-4 w-full">
                  <FormLabel aria-required>Company Website</FormLabel>
                  <FormDescription></FormDescription>
                  <FormControl>
                    <Input placeholder="https://www.domain.com" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="privateBoards"
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="mt-4 w-full">
                  <div className=' flex items-center mb-4'>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel aria-required className='ml-2'>Board Privacy Enabled</FormLabel>
                  </div>

                  {field.value && (
                    <Alert
                      severity="info"
                      variant="ghost"
                      description="Enabling this will prevent non admin users and non hiring managers from viewing the positions they don't belong."
                    />
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />


          </CardContent>
          <CardFooter className="">
            <Button
              isLoading={form.formState.isSubmitting}
              className=""
              type="submit"
            >
              {organization ? 'Update' : 'Create'}
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form >
  );
}
