import { WorkableConnectionFormValues } from './WorkableConnection';

import { BreezyConnectionFormValues } from './BreezyConnection';
import { JobDivaConnectionFormValues } from './JobDivaConnection';
import { ATSConnectionAPIKeyFormValues } from './ATSConnectionAPIKeyForm';

export enum ATSConnectionId {
  WORKABLE = 'WORKABLE',
  ZOHO_RECRUIT = 'ZOHO_RECRUIT',
  BREEZY = 'BreezyATS',
  RECRUITER_FLOW = 'RECRUITER_FLOW',
  MANATAL = 'MANATAL',
  CRELATE = 'CRELATE',
  CEIPAL = 'CEIPAL',
  JAZZ_HR = 'JAZZ_HR',
  GREENHOUSE = 'GREENHOUSE',
  JOBDIVA = 'JOBDIVA',
  LEVER = 'Lever',
  TEMPWORKS = 'TEMPWORKS',
  JOBADDER = 'JOBADDER',
  LOXO = 'LOXO',
  ASHBY = 'ASHBY',
}

export enum ATSConnectionAuthMethod {
  API_KEY = 'API_KEY',
  USERNAME_PASSWORD = 'USERNAME_PASSWORD',
  OAUTH2 = 'OAUTH2',
  SUBDOMAIN_API_KEY = 'SUBDOMAIN_API_KEY',
  EMAIL_PASSWORD_API_KEY = 'EMAIL_PASSWORD_API_KEY',
}

export type ATSConnection = {
  id: ATSConnectionId;
  name: string;
  authMethod: ATSConnectionAuthMethod;
  active: boolean;
  imageUrl: string;
  bigImageUrl: string;
};

export type ATSConnectionData =
  | ATSConnectionAPIKeyFormValues
  | WorkableConnectionFormValues
  | BreezyConnectionFormValues
  | JobDivaConnectionFormValues;
