import { Organization } from '@/fetchers/useUserDetails';
import { EMOJI_REGEX } from './constants';
import { TPosition } from '@/fetchers/usePosition';

export const containsEmoji = (str: string) => {
  return EMOJI_REGEX.test(str);
};

export const handleReplaceVariables = ({
  text,
  isPreview,
  position,
  selectedOrganization,
}: {
  text: string;
  selectedOrganization?: Organization;
  position?: TPosition;
  isPreview?: boolean;
}) => {
  const email = isPreview
    ? `<span style="color: rgb(59 130 246); text-decoration: underline;">${selectedOrganization?.companyReachOutEmail}</span>`
    : selectedOrganization?.companyReachOutEmail;
  return text
    .replace(/{{company_name}}/g, selectedOrganization?.name ?? '')
    .replace(/{{company_email}}/g, email ?? '')
    .replace(/{{position_name}}/g, position?.title ?? 'XYZ')
    .replace(/{{candidate_name}}/g, 'John Doe');
};
