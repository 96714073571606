import { AdminDashboardComponent } from '@/components/core/admin/dashboard'
import { Loader } from '@/components/core/loader'
import { useGetAdminDashboardOrganization } from '@/fetchers/useOrganization'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/admin-panel/organizations/$id')({
  component: () => <AdminOrganizationsPage />
})

function AdminOrganizationsPage() {
  const { id } = Route.useParams()
  const { data, isFetching } = useGetAdminDashboardOrganization({
    enabled: true,
    organizationId: Number(id)
  })

  if (isFetching) {
    return <Loader />
  }

  console.log('items', data)
  return (
    <div>
      <AdminDashboardComponent
        items={data || []}
        title="Dashboard"
        description="Latest Interviews"
      />
    </div>
  )
}