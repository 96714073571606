import { Loader } from '@/components/core/loader';
import { useUserDetails, useUserDetailStore } from '@/fetchers/useUserDetails';
import creatingSvg from '@/assets/svg/creating.svg';
import syncingSvg from '@/assets/svg/undraw_sync.svg';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  createRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { PlusIcon, SquareArrowOutUpRight } from 'lucide-react';
import {
  PositionStatus,
  TPosition,
  usePaginatedPositionsQuery,
} from '@/fetchers/usePosition';
import { Button, buttonVariants } from '@/components/ui/button';
import { getSubtitleFromPosition } from '@/lib/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import AlertModal from '@/components/core/common/AlertModal';
import {
  isAtsAvailableForPlan,
  Plan,
  useATSDisconnect,
  useGetOrganizationTimeSaved,
  useOrganizationStore,
} from '@/fetchers/useOrganization';

import { toast } from 'sonner';
import { SearchInput } from '@/components/core/search-input';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { useVirtualizedList } from '@/hooks/useVirtualizedList';
import PositionListItem from '@/components/core/position/position-list-item';
import { Route as OrganizationsRoute } from './organizations';
import { Separator } from '@/components/ui/separator';
import TimeSavedGrid from '@/components/core/time-saved-grid';
import { useParsedTimeSaved } from '@/lib/useParsedTimeSaved';
import { ATS_CONNECTIONS } from '@/components/core/ats/constants';
import { ATSConnectionDialog } from '@/components/core/ats/ATSConnectionDialog';
import { useGlobalAlertStore } from '@/lib/useGlobalAlertStore';
import { useAuthorization } from '@/hooks/useAuthorization';

export const Route = createRoute({
  path: '/$organizationId/positions/list',
  getParentRoute: () => OrganizationsRoute,
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: PositionsPage,
});

type PositionStatusTabValue = 'all' | PositionStatus;

const statusTabs: {
  title: string;
  value: PositionStatusTabValue;
}[] = [
    {
      title: 'Draft',
      value: PositionStatus.Draft,
    },
    {
      title: 'Open',
      value: PositionStatus.Open,
    },
    {
      title: 'Closed',
      value: PositionStatus.Closed,
    },
    {
      title: 'All',
      value: 'all',
    },
  ];

const getPositionCountByStatus = (
  positions: TPosition[],
  status: PositionStatus
) => {
  return positions.filter((position) => position.status === status).length;
};

function PositionsPage() {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();
  const alertHeight = useGlobalAlertStore((store) => store.alertHeight);
  const [selectedTab, setSelectedTab] = useState<PositionStatusTabValue>();
  const [searchTerm, setSearchTerm] = useState('');
  const [isDisconnectATSAlertModalOpen, setIsDisconnectATSAlertModal] =
    useState(false);
  const { selectedOrganization } = useUserDetailStore();
  const disconnect = useATSDisconnect();
  const { isAdmin } = useAuthorization();

  const { isFetching: isFetchingUserDetails } = useUserDetails();
  const { data: positions, isFetching: isFetchingPositions } =
    usePaginatedPositionsQuery({
      enabled: !isFetchingUserDetails,
      organizationId,
    });
  const { data: timeSaved } = useGetOrganizationTimeSaved({
    organizationId,
  });
  const parsedTimeSaved = useParsedTimeSaved(timeSaved);
  const positionsContainerRef = useRef<HTMLDivElement>(null);
  const isEmpty = positions.length === 0;

  useEffect(() => {
    if (selectedTab !== undefined) return;
    if (!positions || positions.length === 0) return;

    if (positions.length === 0) {
      setSelectedTab('all');
      return;
    }

    if (positions.every((position) => !getIsPositionOpen(position))) {
      setSelectedTab('all');
      return;
    }

    setSelectedTab(PositionStatus.Open);
  }, [positions, selectedTab]);

  const filteredPositisions: TPosition[] = useMemo(() => {
    const allItems = positions.map((position) => ({
      ...position,
      subtitle: getSubtitleFromPosition(position),
    }));

    return allItems.filter((position) => {
      const matchesTitle =
        position.title.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      const matchesSubtitle =
        position.subtitle.toLowerCase().split(searchTerm.toLowerCase()).length >
        1;
      const matchesTags = position.tags
        ? position.tags.join(' ').toLowerCase().split(searchTerm.toLowerCase())
          .length > 1
        : false;

      if (selectedTab === 'all') {
        return matchesTitle || matchesSubtitle || matchesTags;
      }

      return (
        selectedTab?.toString() === position.status.toString() &&
        (matchesTitle || matchesSubtitle)
      );
    });
  }, [selectedTab, positions, searchTerm]);

  const virtualizer = useVirtualizedList({
    itemCount: filteredPositisions.length,
    virtualizerOptions: {
      getScrollElement: () => positionsContainerRef.current,
    },
  });

  const getPositionCount = (tabValue: PositionStatusTabValue) => {
    return tabValue === 'all'
      ? positions.length
      : getPositionCountByStatus(positions, tabValue);
  };

  if (isFetchingPositions) {
    return <Loader />;
  }

  return (
    <>
      <AlertModal
        open={isDisconnectATSAlertModalOpen}
        onClose={() => setIsDisconnectATSAlertModal(false)}
        title="You are about to disconnect your ATS Connection"
        description={`Your ATS connection will be disconnected and you will not be able to receive applications from your ATS. Are you sure you want to disconnect?`}
        confirmText="Disconnect"
        onProceed={() =>
          disconnect({
            organizationId,
            integrationType: ATS_CONNECTIONS.find(
              (c) => c.name === selectedOrganization?.integrationName
            )?.id,
          })
        }
      />
      <Breadcrumb />
      <div className="mx-auto flex h-full w-full max-w-2xl flex-col xl:max-w-4xl">
        <div className="mb-4 flex flex-col">
          {parsedTimeSaved?.totalDuration && (
            <TimeSavedGrid parsedTimeSaved={parsedTimeSaved} />
          )}
          <div className="flex items-center justify-between">
            <h1 className="font-bold md:text-xl">
              Positions{' '}
              {selectedOrganization?.name
                ? `- ${selectedOrganization?.name}`
                : ''}
            </h1>
            {!selectedOrganization?.integrationName && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${selectedOrganization?.slug}${selectedOrganization?.slug === 'careers' ? '' : '.careers'}.veton.ai`}
                className={buttonVariants({ variant: 'outline' })}
              >
                <SquareArrowOutUpRight className="mr-2 size-4" />
                Careers page
              </a>
            )}
          </div>
          <Separator className="my-4" />
          <div className="flex flex-col gap-4 md:flex-row md:justify-between">
            <div className="flex w-full flex-col justify-between gap-4 md:justify-normal lg:flex-row">
              {selectedOrganization?.integrationName ? (

                <Button
                  variant={'outline'}
                  disabled={!isAdmin}
                  onClick={() => {
                    if (selectedOrganization?.integrationName) {
                      setIsDisconnectATSAlertModal(true);
                    } else {
                      toast.error('No ATS connection found');
                    }
                  }}
                  className="order-1"
                >
                  Disconnect {selectedOrganization?.integrationName || 'ATS'}
                </Button>

              ) : (
                <Button
                  variant="default"
                  onClick={() =>
                    navigate({
                      to: `/organizations/$organizationId/positions/create`,
                      params: { organizationId },
                    })
                  }
                  className="order-1"
                >
                  <PlusIcon className="mr-1 size-4" />
                  New position
                </Button>
              )}
              <Tabs
                value={selectedTab?.toString()}
                onValueChange={(val) =>
                  setSelectedTab(val === 'all' ? val : Number(val))
                }
                className="order-3 lg:order-2 lg:mx-auto"
              >
                <TabsList className="w-full">
                  {statusTabs.map((tab) => (
                    <TabsTrigger
                      key={tab.value}
                      value={tab.value.toString()}
                      className="flex-1 gap-0.5 text-zinc-600 dark:text-zinc-200"
                    >
                      {tab.title}
                      <span
                        className={
                          selectedTab === tab.value ? '' : 'opacity-75'
                        }
                        hidden={getPositionCount(tab.value) === 0}
                      >
                        {`(${getPositionCount(tab.value)})`}
                      </span>
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
              <SearchInput
                value={searchTerm}
                onChange={setSearchTerm}
                className="order-2 lg:order-3"
              />
            </div>
          </div>
        </div>

        {filteredPositisions.length === 0 ? (
          <EmptyView
            title={
              isEmpty
                ? 'No positions found'
                : 'No positions found with the search criteria'
            }
            ctaText={
              isEmpty ? 'Create your first position' : 'Create a new position'
            }
            subTitle={
              isEmpty
                ? 'Create your first position or connect to your ATS'
                : 'Create a new position that matches the search criteria to get started'
            }
            onClick={() =>
              navigate({
                to: `/organizations/$organizationId/positions/create`,
                params: {
                  organizationId,
                },
              })
            }
          />
        ) : (
          <div
            ref={positionsContainerRef}
            className="no-scrollbar w-full overflow-y-auto pb-20 md:pb-6"
            style={{
              marginBottom: alertHeight,
            }}
          >
            <div
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {virtualizer.getVirtualItems().map((virtualItem) => {
                const position = filteredPositisions[virtualItem.index];
                return (
                  <div
                    key={virtualItem.key}
                    data-index={virtualItem.index}
                    ref={virtualizer.measureElement}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualItem.start}px)`,
                      marginBottom: '8px', // Add margin here,
                    }}
                  >
                    <PositionListItem position={position} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function EmptyView({
  onClick,
  title,
  ctaText,
  subTitle,
}: {
  ctaText: string;
  subTitle: string;
  title: string;
  onClick: () => void;
}) {
  // const { connect, disconnect, isLoading } = useMergeConnect()
  const { selectedOrganization } = useUserDetailStore();
  const { selectedParentOrganization } = useOrganizationStore();

  const parentPlan = selectedParentOrganization?.plan || Plan.FREE;

  if (selectedOrganization?.integrationName) {
    return (
      <div className="mx-auto my-12 flex w-full max-w-2xl flex-col items-center justify-center space-y-8">
        <h1 className="max-w-sm text-center text-3xl font-bold">
          Syncing in progress
        </h1>
        <img
          src={syncingSvg}
          alt="Syncing in progress"
          style={{
            width: '40%',
            height: 'auto',
          }}
        />
      </div>
    );
  }

  return (
    <div className="mx-auto my-12 flex w-full max-w-2xl flex-col items-center justify-center space-y-8">
      <h1 className="max-w-sm text-center text-3xl font-bold">{title}</h1>
      <img
        src={creatingSvg}
        alt="Create a new position svg"
        style={{
          width: '40%',
          height: 'auto',
        }}
      />

      <Button onClick={onClick}>
        <PlusIcon className="mr-2 h-4 w-4" />
        {ctaText}
      </Button>

      <p className="max-w-md text-center">
        {!isAtsAvailableForPlan(parentPlan)
          ? 'Create your first board to get started'
          : subTitle}
      </p>

      {isAtsAvailableForPlan(parentPlan) && <ATSConnectionDialog />}
    </div>
  );
}
