import { useEffect, useRef, useState } from 'react';
import {
  decodeAudioBuffer,
  inputBufferToMuLaw,
  payloadToWAV,
} from '@/lib/audioStream';
import { useGetWebGeneralApplication } from '@/fetchers/useApplication';
import { useVocalConversation } from '@/fetchers/useVocalConversation';
import { InterviewQuery } from '@/fetchers/useInterview';
import { uuid } from './utils';

const STREAM_URL =
  (import.meta.env.VITE_API_WS_URL as string) + '/ws/stream/twilio';

interface AudioData {
  event: string;
  media?: {
    payload: string;
  };
  mark?: {
    name: string;
  };
}

export const useAudioStream = ({
  interviewId,
  assistantType,
  interview,
}: {
  interviewId?: number;
  assistantType?: string;
  interview?: InterviewQuery;
}) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const audioBufferQueueRef = useRef<ArrayBuffer[]>([]);
  const isPlayingRef = useRef<boolean>(false);
  const currentSourceNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const markReceivedRef = useRef<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [aiTalking, setAiTalking] = useState<boolean | undefined>();
  const greetFinished = useRef(false);
  const currentTwilioTrackRef = useRef<MediaStreamTrack | null>(null);
  // This ref will track if we've already processed a clear signal during the current AI turn
  const clearSignalProcessedRef = useRef<boolean>(false);
  const { data } = useGetWebGeneralApplication({
    enabled: isStarted && !interview,
  });

  const interviewData = {
    interviewId: interview?.id || interviewId,
    assistantType: interview?.assistantType || data?.assistantType,
    openaiAssistantId: interview?.openaiAssistantId || data?.openaiAssistantId,
    openaiThreadId: interview?.openaiThreadId || data?.openaiThreadId,
    voiceModel: interview?.voiceModel || data?.voiceModel,
    callType: interview ? interview?.assistantType : 'general-application',
  };

  const { uploadMedia, recordMedia } = useVocalConversation({
    interviewId: interviewData.interviewId,
  });

  function endStream() {
    setIsStarted(false);

    // disconnect websocket
    if (websocketRef.current) {
      websocketRef.current.close();
    }

    // stop recording
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    // Reset the flag before ending the stream
    clearSignalProcessedRef.current = false;
    handleClearSignal({ event: 'clear' });
  }
  useEffect(() => {
    if (!interviewData?.interviewId || !interviewData?.assistantType) return;
    if (!isStarted) return;

    if (aiTalking) {
      if (!greetFinished.current) return;
      console.log('upload previous media');
      uploadMedia();
    } else {
      console.log('start recording');
      recordMedia();
    }
  }, [isStarted, aiTalking, interviewId, assistantType]);

  useEffect(() => {
    if (
      data?.assistantType &&
      data?.openaiAssistantId &&
      data?.openaiThreadId &&
      data?.voiceModel
    ) {
      startRecording();
    }
  }, [data]);

  useEffect(() => {
    if (interview && isStarted) {
      startRecording();
    }
  }, [interview, isStarted]);

  useEffect(() => {
    websocketRef.current = new WebSocket(STREAM_URL);
    websocketRef.current.onopen = () => {
      console.log('WebSocket connected');
    };
    websocketRef.current.onclose = () => console.log('WebSocket disconnected');
    websocketRef.current.onmessage = (message: MessageEvent) => {
      const data: AudioData = JSON.parse(message.data);
      console.log('Incoming data event:', data);
      handleIncomingAudio(data);
      handleClearSignal(data);
      handleMarkEvent(data);
    };

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const handleClearSignal = (data: AudioData) => {
    if (data.event !== 'clear') return;

    console.log(
      `Handling clear signal. Current state: clearSignalProcessed=${clearSignalProcessedRef.current}`
    );

    if (currentSourceNodeRef.current) {
      currentSourceNodeRef.current.stop();
      currentSourceNodeRef.current = null;
    }

    // Only unpublish the Twilio track on the first clear signal of each AI turn
    if (
      window.twilioRoom &&
      currentTwilioTrackRef.current &&
      !clearSignalProcessedRef.current
    ) {
      try {
        window.twilioRoom.localParticipant.unpublishTrack(
          currentTwilioTrackRef.current
        );
        console.log('Unpublished keyboard filler track (first clear signal)');
        currentTwilioTrackRef.current = null;
        // Mark that we've processed a clear signal during this AI turn
        clearSignalProcessedRef.current = true;
        console.log('Set clearSignalProcessed to true');
      } catch (e) {
        console.error('Error unpublishing track:', e);
      }
    } else if (clearSignalProcessedRef.current) {
      console.log(
        'Skipping track unpublish - already handled by a previous clear signal'
      );
    }

    audioBufferQueueRef.current = [];
  };

  const handleMarkEvent = (data: AudioData) => {
    if (data.event !== 'mark') return;
    markReceivedRef.current = true;
  };

  // Removed presigned URL handling as we're using Twilio for recording now

  const sendStartEvent = () => {
    const startEvent = {
      event: 'start',
      start: {
        callSid: 'web',
        streamSid: uuid(),
        customParameters: {
          call_type: interviewData?.callType,
          openai_thread_id: interviewData?.openaiThreadId,
          openai_assistant_id: interviewData?.openaiAssistantId,
          assistant_type: interviewData?.assistantType,
          voice_model: interviewData?.voiceModel,
          interview_id: interviewData?.interviewId,
          initiator: 'web',
        },
      },
    };
    websocketRef.current?.send(JSON.stringify(startEvent));
  };

  const handleIncomingAudio = async (data: AudioData) => {
    if (data.event !== 'media' || !data.media) return;
    try {
      const wavData = payloadToWAV(data.media.payload);

      if (wavData) {
        audioBufferQueueRef.current.push(wavData);
        if (!isPlayingRef.current) {
          setAiTalking(true);
          await playAudioQueue();
        }
      }
    } catch (error) {
      console.error('Error handling incoming audio:', error);
    }
  };

  const playAudioQueue = async () => {
    isPlayingRef.current = true;
    while (audioBufferQueueRef.current.length > 0) {
      const wavData = audioBufferQueueRef.current.shift();
      if (wavData && audioContextRef.current) {
        try {
          const audioBuffer = await decodeAudioBuffer(
            audioContextRef.current,
            wavData
          );

          // Create a MediaStream from the audio buffer for Twilio
          // Use the existing audioContextRef to ensure consistency
          if (window.twilioRoom && audioContextRef.current) {
            try {
              const streamDestination =
                audioContextRef.current.createMediaStreamDestination();
              const twilioSource = audioContextRef.current.createBufferSource();
              twilioSource.buffer = audioBuffer;
              twilioSource.connect(streamDestination);

              const audioTrack = streamDestination.stream.getAudioTracks()[0];
              if (audioTrack) {
                // Create a proper MediaStreamTrack that Twilio can record
                const audioId = 'ai-audio-' + Date.now();

                // Start the source to ensure audio is playing when published
                twilioSource.start(0);

                // Publish the track to Twilio
                console.log(`Publishing audio track ${audioId} to Twilio`);
                window.twilioRoom.localParticipant
                  .publishTrack(audioTrack, {
                    name: audioId,
                  })
                  // @ts-ignore
                  .then((track) => {
                    console.log(
                      `Successfully published track ${audioId}`,
                      track
                    );

                    // Log track status for debugging
                    console.log(
                      `Track state: clearSignalProcessed=${clearSignalProcessedRef.current}`
                    );

                    // Store reference to the track
                    currentTwilioTrackRef.current = audioTrack;

                    // Cleanup the track when audio finishes - only if not handled by clear signal
                    twilioSource.onended = () => {
                      // If clear signal has already handled this track, don't try to unpublish again
                      if (clearSignalProcessedRef.current) {
                        console.log(
                          `Skipping unpublish for ${audioId} - already handled by clear signal`
                        );
                        return;
                      }

                      try {
                        if (window.twilioRoom?.localParticipant && audioTrack) {
                          window.twilioRoom.localParticipant.unpublishTrack(
                            audioTrack
                          );
                          console.log(
                            `Unpublished track ${audioId} on audio end`
                          );

                          // Only reset the current track ref if it's still this track
                          if (currentTwilioTrackRef.current === audioTrack) {
                            currentTwilioTrackRef.current = null;
                          }
                        }
                      } catch (e) {
                        console.error('Error unpublishing track:', e);
                      }
                    };
                  })
                  // @ts-ignore
                  .catch((err) => {
                    console.error('Failed to publish track:', err);
                  });
              }
            } catch (e) {
              console.error('Error setting up Twilio audio track:', e);
            }
          }

          // Continue with normal audio playback
          const localSource = audioContextRef.current.createBufferSource();
          localSource.buffer = audioBuffer;
          localSource.connect(audioContextRef.current.destination);
          currentSourceNodeRef.current = localSource;
          localSource.start(0);
          // source.start(0);

          await new Promise<void>((resolve) => {
            localSource.onended = () => {
              resolve();
              currentSourceNodeRef.current = null;
            };
          });
        } catch (error) {
          console.error('Error decoding audio data:', error);
        }
      }
    }
    isPlayingRef.current = false;

    if (markReceivedRef.current && audioBufferQueueRef.current.length === 0) {
      sendEndSignal();
      markReceivedRef.current = false;
    }
  };

  const startRecording = async () => {
    sendStartEvent();
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        // @ts-ignore
        window.webkitAudioContext)({ sampleRate: 16000 });
    }

    if (audioContextRef.current.state === 'suspended') {
      await audioContextRef.current.resume();
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioInput =
          audioContextRef.current!.createMediaStreamSource(stream);
        const recorder = audioContextRef.current!.createScriptProcessor(
          2048,
          1,
          1
        );

        recorder.onaudioprocess = (e: AudioProcessingEvent) => {
          const base64Data = inputBufferToMuLaw(e.inputBuffer);
          // Only send if ai is not talking
          websocketRef.current?.send(
            JSON.stringify({
              event: 'media',
              media: {
                payload: base64Data,
              },
            })
          );
        };

        audioInput.connect(recorder);
        if (audioContextRef.current) {
          recorder.connect(audioContextRef.current.destination);
        }
      })
      .catch((err) => {
        console.error('Error capturing audio: ', err);
      });
  };

  const sendEndSignal = () => {
    console.log('Sending end signal');
    websocketRef.current?.send(
      JSON.stringify({
        event: 'mark',
        mark: { name: 'END_SIGNAL' },
      })
    );
    if (!greetFinished.current) {
      greetFinished.current = true;
    }
    setAiTalking(false);
    // Reset the clear signal flag when AI stops talking
    // This allows us to properly handle the first clear signal of the next AI turn
    console.log(
      'Resetting clearSignalProcessed flag from',
      clearSignalProcessedRef.current,
      'to false'
    );
    clearSignalProcessedRef.current = false;
  };

  return {
    data,
    isStarted,
    setIsStarted,
    aiTalking,
    endStream,
  };
};
