import { ApplicationDetail } from '@/fetchers/useApplication';
import { format } from 'date-fns';

type ApplicationTimelineProps = {
  application: ApplicationDetail;
};
const ApplicationTimeline = ({ application }: ApplicationTimelineProps) => {
  const getSteps = () => {
    const steps = [];

    const formatString = 'MMM d, yyyy • h:mm a';

    if (application.createdAt) {
      steps.push({
        title: 'Applied',
        dateTime: format(new Date(application.createdAt), formatString),
      });
    }

    if (application.interviews?.[0]?.createdAt) {
      steps.push({
        title: 'Interview sent',
        dateTime: format(
          new Date(application.interviews?.[0]?.createdAt),
          formatString
        ),
      });
    }

    if (application.interviews?.[0]?.lastReminderSentAt) {
      steps.push({
        title: 'Reminder sent',
        dateTime: format(
          new Date(application.interviews?.[0]?.lastReminderSentAt),
          formatString
        ),
      });
    }

    if (application.interviews?.[0]?.completedAt) {
      steps.push({
        title: 'Interview completed',
        dateTime: format(
          new Date(application.interviews?.[0]?.completedAt),
          formatString
        ),
      });
    }

    return steps;
  };

  return (
    <div className="space-y-6">
      {getSteps().map((step, index) => (
        <div key={step.title} className="relative">
          {index !== getSteps().length - 1 && (
            <div className="absolute left-[5.5px] top-[22px] h-[54px] w-0.5 bg-gray-200" />
          )}

          <div className="flex items-start">
            <div className="flex flex-shrink-0 pt-2">
              <span className="size-3 rounded-full bg-primary" />
            </div>

            <div className="ml-4 flex-grow">
              <h3 className="text-base text-gray-900">{step.title}</h3>
              <p className="mt-0.5 text-sm text-gray-500">{step.dateTime}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApplicationTimeline;
