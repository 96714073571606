import { Badge } from '@/components/ui/badge';
import { Applicant } from '@/lib/useApplicants';
import { RouterParams } from '@/main';
import { useNavigate, useParams } from '@tanstack/react-router';
import { ItemActions } from '../management/item-actions';
import { ItemAction } from '@/pages/dashboard/components/item-list';
import { useMemo, useState } from 'react';
import {
  useBulkShortlistMutation,
  useSendInterviewMutation,
  useSendReminderMutation,
  useUpdateApplicationMutation,
} from '@/fetchers/useApplication';
import useResetInterviewModal from '@/hooks/useResetInterviewModal';
import useDisqualifyCandidateModal from '@/hooks/useDisqualifyCandidateModal';
import AlertModal from '../common/AlertModal';
import { toast } from 'sonner';
import { useDraggable } from '@dnd-kit/core';
import { PipelineStage } from './types';
import { FileText, PhoneCall } from 'lucide-react';
import { PhoneLineType } from '@/fetchers/types';
import { Alert } from '../alert';
import { useCommunicationForm } from '@/fetchers/usePosition';
import { CommunicationMethod } from '../position/communication/types';
import BulkSelectCheckbox from './BulkSelectCheckbox';

import LandlineIcon from '@/assets/svg/landline.svg?react';
import MobilePhoneIcon from '@/assets/svg/mobile-phone.svg?react';
import { cn } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns';
import { Separator } from '@/components/ui/separator';
import useContinueInterviewModal from '@/hooks/useContinueInterviewModal';
type ApplicantProps = {
  applicant: Applicant;
  searchQuery: string;
  stage: PipelineStage;
  bulkSelectionActive: boolean;
  selectedApplicants: Applicant[];
  onSelect: (applicant: Applicant, isSelected: boolean) => void;
};

const ApplicantPipelineItem = ({
  applicant,
  searchQuery,
  stage,
  bulkSelectionActive,
  selectedApplicants,
  onSelect,
}: ApplicantProps) => {
  const {
    application,
    interview,
    resumeVerdict,
    interviewVerdict,
    resumeBadgeVariant,
    interviewBadgeVariant,
  } = applicant;
  const { email, phone, phoneIsValid, phoneLineType } = application;
  const applicationId = application.id;
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const navigate = useNavigate();
  const { handleOpenResetInterviewModal, ResetInterviewModal } =
    useResetInterviewModal();
  const { handleOpenDisqualifyCandidateModal, DisqualifyCandidateModal } =
    useDisqualifyCandidateModal();
  const { handleOpenContinueInterviewModal, ContinueInterviewModal } = useContinueInterviewModal();
  const [isSendReminderModalOpen, setIsSendReminderModalOpen] = useState(false);
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const sendReminderMutation = useSendReminderMutation();
  const sendInterviewMutation = useSendInterviewMutation();
  const updateApplicationMutation = useUpdateApplicationMutation();
  const bulkShortlistMutation = useBulkShortlistMutation();
  const { data: communicationForm } = useCommunicationForm({
    slug,
  });
  const isCommunicationMethodSMS =
    communicationForm?.selectedMethod === CommunicationMethod.SMS;
  const showPhoneNumberInvalidAlert = isCommunicationMethodSMS && !phoneIsValid;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: applicationId.toString(),
    disabled: [
      PipelineStage.INTERVIEW_SENT,
      PipelineStage.DISQUALIFIED,
    ].includes(stage),
    data: {
      stage,
    },
  });
  const style: React.CSSProperties | undefined = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }
    : undefined;

  async function handleSendReminder() {
    if (!interview?.id) return;
    await sendReminderMutation({
      positionSlug: slug,
      candidates: [interview.id],
    }).then(() => {
      toast.success('Reminder sent successfully');
    });
  }

  const handleRevert = () => {
    bulkShortlistMutation({
      applicationIds: [applicationId],
      shortlisted: false,
    });
  };

  const handleClick = () => {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      params: {
        organizationId,
        slug,
        step: 'applications',
        id: applicationId.toString(),
      },
      search: {
        searchQuery,
        selectedTab: 'all',
      },
    });
  };

  const seeProfileAction: ItemAction = {
    title: 'See profile',
    onClick: handleClick,
  };

  const sendInterviewAction: ItemAction = {
    title:
      showPhoneNumberInvalidAlert ||
        (phoneLineType && phoneLineType !== PhoneLineType.MOBILE)
        ? 'Send interview via email'
        : 'Send interview',
    onClick: () => {
      sendInterviewMutation({
        applicationId,
      });
    },
  };

  const sendReminderAction: ItemAction = {
    title: 'Send reminder',
    onClick: () => {
      setIsSendReminderModalOpen(true);
    },
  };

  const disqualifyAction: ItemAction = {
    title: 'Disqualify',
    onClick: () => handleOpenDisqualifyCandidateModal(applicationId),
    customClasses: 'text-red-500',
  };

  const resetInterviewAction: ItemAction = {
    title: 'Reset interview',
    onClick: () => {
      if (!interview?.id) return;
      handleOpenResetInterviewModal(interview.id);
    },
  };

  const continueInterviewAction: ItemAction = {
    title: 'Continue interview',
    onClick: () => {
      if (!interview?.id) return;
      handleOpenContinueInterviewModal(interview.id);
    },
  };

  const archiveAction: ItemAction = {
    title: 'Archive',
    onClick: () => {
      updateApplicationMutation({
        applicationId,
        data: {
          archived: true,
        },
      }).then(() => {
        toast.success('Candidate archived successfully');
      });
    },
  };

  const interviewSentActions = useMemo(() => {
    if (interview?.id === 0) {
      return [seeProfileAction, disqualifyAction];
    }

    return [seeProfileAction, sendReminderAction, disqualifyAction];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview?.id]);

  const shortlistAction: ItemAction = {
    title: 'Shortlist',
    onClick: () => {
      bulkShortlistMutation({
        applicationIds: [applicationId],
        shortlisted: true,
      });
    },
  };

  const revertAction: ItemAction = {
    title: 'Revert',
    onClick: () => {
      setIsRevertModalOpen(true);
    },
  };

  const dropdownActionsByStage: Record<PipelineStage, ItemAction[]> = {
    [PipelineStage.APPLIED]: [
      seeProfileAction,
      sendInterviewAction,
      shortlistAction,
      disqualifyAction,
    ],
    [PipelineStage.INTERVIEW_SENT]: interviewSentActions,
    [PipelineStage.INTERVIEW_COMPLETED]: [
      seeProfileAction,
      continueInterviewAction,
      resetInterviewAction,
      shortlistAction,
      disqualifyAction,
    ],
    [PipelineStage.SHORTLISTED]: [
      seeProfileAction,
      continueInterviewAction,
      revertAction,
      disqualifyAction,

    ],
    [PipelineStage.DISQUALIFIED]: [
      seeProfileAction,
      continueInterviewAction,
      resetInterviewAction,
      archiveAction,
    ],
  };

  const dropdownActions = dropdownActionsByStage[stage];

  const isSelected = selectedApplicants.some(
    (selectedApplicant) => selectedApplicant.application.id === applicationId
  );

  const showCompletedDateStages = [
    PipelineStage.INTERVIEW_COMPLETED,
    PipelineStage.SHORTLISTED,
    PipelineStage.DISQUALIFIED,
  ];
  const interviewCompletedDate =
    showCompletedDateStages.includes(stage) && interview?.completedAt ? (
      <>
        <Separator
          orientation="vertical"
          className={cn(
            'mx-1 h-3 bg-gray-300',
            interviewBadgeVariant === 'default' && 'bg-white'
          )}
        />
        {formatDistanceToNow(new Date(interview.completedAt), {
          addSuffix: true,
        })
          .replace(/about|over|almost/, '')
          .trim()}
      </>
    ) : null;

  return (
    <>
      {ResetInterviewModal}
      {DisqualifyCandidateModal}
      {ContinueInterviewModal}
      <AlertModal
        open={isSendReminderModalOpen}
        onClose={() => setIsSendReminderModalOpen(false)}
        title={`You are about to send reminder to ${application.name}`}
        description={`Are you sure you want to send reminder to ${application.email}? The interview email will be sent immediately.`}
        confirmText="Send"
        onProceed={handleSendReminder}
      />
      <AlertModal
        open={isRevertModalOpen}
        onClose={() => setIsRevertModalOpen(false)}
        title="You are about to revert this applicant"
        description="Please confirm that you want to revert this applicant back to its previous column"
        confirmText="Revert"
        onProceed={handleRevert}
      />

      <div
        className="relative shadow-sm"
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        {dropdownActions.length > 0 && (
          <div className="absolute right-3 top-2 flex items-center gap-2">
            <ItemActions dropdownActions={dropdownActions} />
          </div>
        )}
        <div
          className={cn(
            'flex w-full flex-col items-start gap-2 rounded-sm p-3 text-left text-sm',
            'border bg-white transition-all hover:bg-accent'
          )}
          onClick={() => {
            if (bulkSelectionActive) {
              onSelect(applicant, isSelected);
            } else {
              handleClick();
            }
          }}
          role="button"
        >
          <div className="flex w-full flex-col gap-1">
            <div className="flex flex-col gap-1 font-semibold">
              <span className="flex items-center overflow-hidden text-ellipsis whitespace-nowrap pr-4 text-base">
                {bulkSelectionActive && (
                  <BulkSelectCheckbox checked={isSelected} className="mt-0.5" />
                )}
                {application.name}
              </span>
              {(resumeVerdict || interviewVerdict) && (
                <div className="flex flex-wrap items-center gap-2">
                  {resumeVerdict && (
                    <Badge variant={resumeBadgeVariant}>
                      <FileText className="mr-1 size-3" />
                      {resumeVerdict}
                    </Badge>
                  )}
                  {interviewVerdict && (
                    <Badge variant={interviewBadgeVariant}>
                      <PhoneCall className="mr-1 size-3" />
                      {interviewVerdict}
                      {interviewCompletedDate}
                    </Badge>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-wrap items-center gap-1 text-xs">
              <span>{email}</span>
              <span>|</span>
              {showPhoneNumberInvalidAlert ? (
                <div className="min-w-0">
                  <Alert
                    severity="error"
                    variant="ghost"
                    description="Invalid phone number"
                    descriptionClassName="text-xs"
                    iconClassName="size-3 mr-1"
                  />
                </div>
              ) : (
                <span className="flex items-center gap-1">
                  {phoneLineType === PhoneLineType.LANDLINE && (
                    <LandlineIcon className="size-4" />
                  )}
                  {phoneLineType === PhoneLineType.MOBILE && (
                    <MobilePhoneIcon className="size-4" />
                  )}
                  {phone}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantPipelineItem;
