import { MoreHorizontal } from 'lucide-react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TAdminDashboard } from '@/fetchers/useOrganization';
import { useNavigate } from '@tanstack/react-router';
import { interviewVerdictValues } from '../applications/constants';
import { FinalVerdict } from '../applications/types';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';

type TProps = {
  items: TAdminDashboard[];
  title: string;
  description: string;
};

export function AdminDashboardComponent({ items, title, description }: TProps) {
  const navigate = useNavigate();

  const renderFinalVerdict = (verdict: FinalVerdict) => {
    const value = interviewVerdictValues[verdict];
    if (!value) return '-';
    return <Badge variant="outline">{value}</Badge>;
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="relative w-full overflow-auto">
          <ScrollArea className="h-[60vh] w-full">
            <div className="min-w-[800px]">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Applicant</TableHead>
                    <TableHead>Organization</TableHead>
                    <TableHead>Position</TableHead>
                    <TableHead>Ending Type</TableHead>
                    <TableHead>Final Verdict</TableHead>
                    <TableHead>Completed</TableHead>
                    <TableHead>Reminders</TableHead>
                    <TableHead>
                      <span className="sr-only">Actions</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {items.map((item) => (
                    <TableRow className="cursor-pointer" key={item.id}>
                      <TableCell>
                        <div>
                          <p className="text-lg font-bold">{item.applicantName}</p>
                          <p>{item.applicantEmail}</p>
                        </div>
                      </TableCell>
                      <TableCell>{item.organizationName}</TableCell>
                      <TableCell>{item.positionTitle}</TableCell>
                      <TableCell>{item.endingType}</TableCell>
                      <TableCell>
                        {renderFinalVerdict(item.finalVerdict as FinalVerdict)}
                      </TableCell>
                      <TableCell>
                        {new Date(item.completedAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{item.reminderCount}</TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem
                              className="cursor-pointer"
                              onClick={() => {
                                navigate({
                                  to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
                                  params: {
                                    organizationId: String(item.organizationId),
                                    slug: item.slug,
                                    step: 'applications',
                                    id: String(item.applicationId),
                                  },
                                });
                              }}
                            >
                              Candidate Report
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="cursor-pointer"
                              onClick={() => {
                                navigate({
                                  to: `/admin-panel/organizations/$id`,
                                  params: {
                                    id: String(item.organizationId),
                                  },
                                });
                              }}
                            >
                              Organization Dashboard
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-50</strong>
        </div>
      </CardFooter>
    </Card>
  );
}
