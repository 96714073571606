import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  BorderStyle,
  AlignmentType,
  ShadingType,
  Footer,
} from 'docx';
import { Marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import hljs from 'highlight.js';
import { formatDate } from './utils';

type TMetaData = {
  position?: string;
  name?: string;
  phone?: string;
  email?: string;
  finalVerdict?: string;
  completedAt?: string;
};

export async function generateDOCX(
  markdownContent: string,
  metadata: TMetaData,
  color: string
): Promise<Blob> {
  const headerColor = color.replace('#', '').toUpperCase();

  // Pre-process the content to ensure proper markdown formatting
  // Ensure there's a space after # characters for headers
  const processedContent = markdownContent.replace(/##(?=\S)/g, '## ');

  const marked = new Marked(
    markedHighlight({
      langPrefix: 'hljs language-',
      highlight(code: string, lang: string) {
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    })
  );

  // Parse markdown content with pre-processed text
  const tokens = marked.lexer(processedContent);

  // Create document content (children)
  const children = [];

  // Add title section with custom color
  children.push(
    new Paragraph({
      children: [
        new TextRun({
          text: 'Candidate Report Plan',
          color: headerColor,
          size: 46,
          bold: true,
        }),
      ],
      heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
      spacing: {
        after: 50,
      },
    })
  );

  children.push(
    new Paragraph({
      children: [
        new TextRun({
          text: metadata.position || '',
          // color: headerColor,
          color: '000000',
          size: 28,
          bold: true,
        }),
      ],
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER,
      spacing: {
        after: 400,
      },
      border: {
        bottom: {
          color: 'EAEAEA',
          style: BorderStyle.SINGLE,
          size: 10,
        },
      },
    })
  );

  // Add metadata as simple paragraphs with bold labels - Using standard black color
  if (metadata.name) {
    children.push(
      new Paragraph({
        children: [
          new TextRun({ text: 'Name: ', bold: true }),
          new TextRun({ text: metadata.name }),
        ],
        spacing: {
          before: 100,
          after: 100,
        },
      })
    );
  }

  if (metadata.phone) {
    children.push(
      new Paragraph({
        children: [
          new TextRun({ text: 'Phone: ', bold: true }),
          new TextRun({ text: metadata.phone }),
        ],
        spacing: {
          before: 100,
          after: 100,
        },
      })
    );
  }

  if (metadata.email) {
    children.push(
      new Paragraph({
        children: [
          new TextRun({ text: 'Email: ', bold: true }),
          new TextRun({ text: metadata.email }),
        ],
        spacing: {
          before: 100,
          after: 100,
        },
      })
    );
  }

  if (metadata.finalVerdict) {
    children.push(
      new Paragraph({
        children: [
          new TextRun({ text: 'Overall Assessment: ', bold: true }),
          new TextRun({ text: metadata.finalVerdict }),
        ],
        spacing: {
          before: 100,
          after: 100,
        },
      })
    );
  }

  // Always add creation date
  children.push(
    new Paragraph({
      children: [
        new TextRun({ text: 'Completed At: ', bold: true }),
        new TextRun({
          text: formatDate(metadata.completedAt || ''),
        }),
      ],
      spacing: {
        before: 100,
        after: 200,
      },
      border: {
        bottom: {
          style: BorderStyle.SINGLE,
          size: 10,
          color: 'EAEAEA',
        },
      },
    })
  );

  // Add spacing after metadata
  children.push(new Paragraph({ text: '', spacing: { after: 200 } }));

  // Process markdown tokens
  for (const token of tokens) {
    if (token.type === 'heading') {
      const level = token.depth;
      let fontSize = 24; // Default font size for headings

      // Adjust size based on heading level
      if (level === 1) fontSize = 28;
      else if (level === 2) fontSize = 24;
      else fontSize = 20;

      // Only apply custom color to heading level 1, use black for others
      const textColor = level === 1 ? headerColor : '757373';
      // const textColor = '000000';

      children.push(
        new Paragraph({
          children: [
            new TextRun({
              text: token.text || '',
              color: textColor,
              size: fontSize,
              bold: true,
            }),
          ],
          heading:
            level === 1
              ? HeadingLevel.HEADING_1
              : level === 2
                ? HeadingLevel.HEADING_2
                : HeadingLevel.HEADING_3,
          spacing: {
            before: 240,
            after: 120,
          },
        })
      );
    } else if (token.type === 'paragraph') {
      // Process paragraph content - may contain formatting
      const textRuns = processFormattedText(token.text || '');
      children.push(
        new Paragraph({
          children: textRuns,
          spacing: {
            after: 120,
          },
        })
      );
    } else if (token.type === 'code') {
      // Format code blocks with shading - using black text instead of custom color
      children.push(
        new Paragraph({
          children: [
            new TextRun({
              text: token.lang || 'code',
              bold: true,
            }),
          ],
          spacing: { before: 120 },
          shading: { type: ShadingType.CLEAR, fill: 'F1F5F9' },
        })
      );

      const codeLines = token.text?.split('\n') || [];
      for (const line of codeLines) {
        children.push(
          new Paragraph({
            text: line || ' ', // Use space for empty lines
            // @ts-ignore
            font: 'Roboto',
            shading: { type: ShadingType.CLEAR, fill: 'F1F5F9' },
          })
        );
      }

      children.push(new Paragraph({ text: '', spacing: { after: 120 } }));
    } else if (token.type === 'list') {
      // Process lists
      // @ts-ignore
      token.items.forEach((item, index) => {
        const bullet = token.ordered ? `${index + 1}.` : '•';
        children.push(
          new Paragraph({
            text: `${bullet} ${item.text || ''}`,
            indent: { left: 720 }, // 0.5 inch indent
            spacing: { after: 120 },
          })
        );
      });
    } else if (token.type === 'blockquote') {
      // Format blockquotes with custom color only for the border
      children.push(
        new Paragraph({
          text: token.text || '',
          border: {
            left: { color: headerColor, style: BorderStyle.SINGLE, size: 15 },
          },
          indent: { left: 720 },
          shading: { type: ShadingType.CLEAR, fill: 'F9FAFB' },
          spacing: { before: 120, after: 120 },
        })
      );
    }
  }

  // Create footer with "Powered by veton.ai"
  const footer = new Footer({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: 'Powered by veton.ai',
            // color: headerColor,
            size: 24,
          }),
        ],
        alignment: AlignmentType.CENTER,
        border: {
          top: {
            color: 'F1F5F9',
            style: BorderStyle.SINGLE,
            size: 10,
          },
        },
        spacing: {
          before: 600,
          after: 600,
        },
      }),
    ],
  });

  // Create document with proper sections structure and footer
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: children,
        footers: {
          default: footer,
        },
      },
    ],
  });

  // Generate the document
  return Packer.toBlob(doc);
}

function processFormattedText(text: string): TextRun[] {
  // Simple implementation - for complex formatting, more regex parsing would be needed
  const parts = [];

  // Extract bold text
  const boldRegex = /\*\*(.*?)\*\*/g;
  let match;
  let lastIndex = 0;

  while ((match = boldRegex.exec(text)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      parts.push(
        new TextRun({
          text: text.substring(lastIndex, match.index),
        })
      );
    }

    // Add bold text
    parts.push(
      new TextRun({
        text: match[1],
        bold: true,
      })
    );

    lastIndex = match.index + match[0].length;
  }

  // Add remaining text
  if (lastIndex < text.length) {
    parts.push(
      new TextRun({
        text: text.substring(lastIndex),
      })
    );
  }

  return parts.length > 0 ? parts : [new TextRun({ text })];
}
