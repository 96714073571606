import AlertModal from '@/components/core/common/AlertModal';
import { useContinueInterviewMutation } from '@/fetchers/useInterview';
import { useState } from 'react';
import { toast } from 'sonner';

const useContinueInterviewModal = () => {
  const [open, setOpen] = useState(false);
  const [interviewId, setInterviewId] = useState<number | undefined>(undefined);
  const resetInterview = useContinueInterviewMutation();

  async function handleContinueInterview() {
    if (!interviewId) return;
    await resetInterview({
      interviewId,
    }).then(() => {
      toast.success('Interview reset successfully');
    });
  }

  const handleOpenModal = (interviewId: number) => {
    setInterviewId(interviewId);
    setOpen(true);
  };

  const ContinueInterviewModal = (
    <AlertModal
      open={!!open}
      onClose={() => setOpen(false)}
      title="You are allowing candidate to continue the interview"
      description={`Are you sure you want to allow the candidate to continue the interview?`}
      confirmText="Confirm"
      onProceed={handleContinueInterview}
      type='info'
    />
  );

  return {
    handleOpenContinueInterviewModal: handleOpenModal,
    ContinueInterviewModal,
  };
};

export default useContinueInterviewModal;
