import { AdminDashboardComponent } from '@/components/core/admin/dashboard';
import { Loader } from '@/components/core/loader';
import { useGetAdminDashboard } from '@/fetchers/useOrganization';
import { createRoute } from '@tanstack/react-router';
import { Route as adminPanelRoute } from './admin-panel';

export const Route = createRoute({
  path: '/interviews',
  getParentRoute: () => adminPanelRoute,
  component: InterviewsPage,
});

function InterviewsPage() {
  const { data, isFetching } = useGetAdminDashboard({
    enabled: true,
  });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <AdminDashboardComponent
      items={data || []}
      title="Interviews"
      description="Latest interviews"
    />
  );
}
