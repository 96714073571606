import { RouterParams } from '@/main';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { Application } from '@/fetchers/useApplication';
import { DeepPartial } from 'react-hook-form';
import deepmerge from 'deepmerge';

export const useUpdateApplicationsCache = () => {
  const queryClient = useQueryClient();
  const { slug } = useParams({ strict: false }) as RouterParams;

  return (
    applicationId: number,
    newApplicationData: DeepPartial<Application>
  ) => {
    queryClient.setQueryData(
      ['applications', 'admin', slug],
      (prevApplications: Application[]) => {
        return prevApplications.map((application) => {
          if (application.id !== applicationId) return application;
          return deepmerge(application, newApplicationData, {
            arrayMerge: (_destinationArray, sourceArray) => sourceArray,
          });
        });
      }
    );
  };
};
