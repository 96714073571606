import { VoiceModel } from '@/fetchers/usePosition';
import { Language } from '@/i18n';

export const AI_NAME_BY_VOICE_MODEL: Record<
  VoiceModel,
  Record<Language, string>
> = {
  echo: {
    [Language.EN_US]: 'Alex',
    [Language.EN_GB]: 'Alex',
    [Language.EN_AU]: 'Alex',
    [Language.EN_NZ]: 'Alex',
    [Language.ES]: 'Diego',
    [Language.NL]: 'Lars',
    [Language.FR]: 'Lucas',
    [Language.DE]: 'Felix',
    [Language.TR]: 'Can',
  },
  nova: {
    [Language.EN_US]: 'Emma',
    [Language.EN_GB]: 'Emma',
    [Language.EN_AU]: 'Emma',
    [Language.EN_NZ]: 'Emma',
    [Language.ES]: 'Sofia',
    [Language.NL]: 'Eva',
    [Language.FR]: 'Léa',
    [Language.DE]: 'Hannah',
    [Language.TR]: 'Elif',
  },
  shimmer: {
    [Language.EN_US]: 'Alice',
    [Language.EN_GB]: 'Alice',
    [Language.EN_AU]: 'Alice',
    [Language.EN_NZ]: 'Alice',
    [Language.ES]: 'Luna',
    [Language.NL]: 'Lisa',
    [Language.FR]: 'Claire',
    [Language.DE]: 'Marie',
    [Language.TR]: 'Zehra',
  },
  onyx: {
    [Language.EN_US]: 'James',
    [Language.EN_GB]: 'James',
    [Language.EN_AU]: 'James',
    [Language.EN_NZ]: 'James',
    [Language.ES]: 'Marco',
    [Language.NL]: 'Thomas',
    [Language.FR]: 'Louis',
    [Language.DE]: 'Lukas',
    [Language.TR]: 'Eren',
  },
};
