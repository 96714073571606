import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { NotificationForm } from './NotificationForm';
import { Loader } from '../loader';
import { useNofitificationForm, useUserDetails } from '@/fetchers/useUserDetails';
import { useState } from 'react';
import { OrganizationPositionNotifications } from './OrganizationPositionNotifications';
import { useGetMemberships, useGetOrganizations } from '@/fetchers/useOrganization';
import { buttonVariants } from '@/components/ui/button';

export function Notifications() {
  // const { data, isFetching: notificationsFetching } = useNofitificationForm({});
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);

  const { data: userDetails, isFetching: isFetchingUserDetails } = useUserDetails();
  const { isFetching: isFetchingMemberships } = useGetMemberships({ enabled: !isFetchingUserDetails && !!userDetails?.id });
  const { data: organizations, isFetching } = useGetOrganizations({
    enabled: !isFetchingMemberships,
  });

  const { data, isFetching: isFetchingNotifications } = useNofitificationForm({});

  if (isFetching || isFetchingMemberships || isFetchingNotifications) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="mx-auto w-full max-w-4xl space-y-3">
      <div className="mx-auto w-full max-w-4xl space-y-0.5">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold tracking-tight">
            {selectedOrgId ? "Position Notifications" : "Notifications"}
          </h2>
        </div>
        <p className="text-muted-foreground">
          {selectedOrgId
            ? "Configure notifications for positions where you are a hiring manager."
            : "Configure your notifications settings to stay up-to-date on what matters."}
        </p>
      </div>

      <Separator className="mx-auto w-full max-w-4xl" />

      <div className="flex h-screen w-full flex-col gap-4">
        <div className="mx-auto flex w-full max-w-4xl flex-shrink-0 items-center gap-4 overflow-x-auto p-1">
          <button
            onClick={() => setSelectedOrgId(null)}
            className={buttonVariants({
              variant: 'ghost',
              className: cn(
                !selectedOrgId && 'bg-muted',
                selectedOrgId && 'hover:bg-muted'
              ),
            })}
          >
            Global Settings
          </button>

          {organizations && organizations.length > 0 && (
            <>
              {organizations.map((org) => (
                <button
                  key={org.id}
                  onClick={() => setSelectedOrgId(org.id.toString())}
                  className={buttonVariants({
                    variant: 'ghost',
                    className: cn(
                      selectedOrgId === org.id.toString() && 'bg-muted',
                      selectedOrgId !== org.id.toString() && 'hover:bg-muted'
                    ),
                  })}
                >
                  {org.name}
                </button>
              ))}
            </>
          )}
        </div>

        <div className={cn('w-full space-y-6 overflow-y-auto pb-4 md:px-2')}>
          {selectedOrgId ? (
            <OrganizationPositionNotifications organizationId={selectedOrgId} />
          ) : (
            // @ts-ignore
            <NotificationForm notificationForm={data} />
          )}
        </div>
      </div>
    </div>
  );
}
