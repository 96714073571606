import * as React from "react";
const SvgLogoSmall = (props) => /* @__PURE__ */ React.createElement("svg", { width: 5e3, height: 3970, viewBox: "0 0 5000 3970", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_145_925", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 527, y: 603, width: 4474, height: 3367 }, /* @__PURE__ */ React.createElement("path", { d: "M5000 603.015H527.638V3969.85H5000V603.015Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_145_925)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask1_145_925", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 668, y: 640, width: 4215, height: 3309 }, /* @__PURE__ */ React.createElement("path", { d: "M668.97 640.115H4882.22V3948.85H668.97V640.115Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_145_925)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask2_145_925", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 670, y: 640, width: 4202, height: 3309 }, /* @__PURE__ */ React.createElement("path", { d: "M2045.19 640.115H4600.15C4687.5 640.115 4769.55 682.122 4820.59 752.984C4871.62 823.846 4885.56 914.73 4857.88 997.566L3993.41 3591.2C3922.15 3804.77 3722.32 3948.85 3497.37 3948.85H942.408C855.057 3948.85 773.006 3906.84 721.97 3835.98C670.933 3765.11 656.996 3674.03 684.674 3591.2L1549.15 997.566C1620.41 784.195 1820.23 640.115 2045.19 640.115Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_145_925)" }, /* @__PURE__ */ React.createElement("path", { d: "M565.524 640.115H4972.72V3948.85H565.524V640.115Z", fill: "#0558EE" })))), /* @__PURE__ */ React.createElement("mask", { id: "mask3_145_925", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 4473, height: 3367 }, /* @__PURE__ */ React.createElement("path", { d: "M4472.36 0H0V3366.83H4472.36V0Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_145_925)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask4_145_925", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 141, y: 37, width: 4214, height: 3309 }, /* @__PURE__ */ React.createElement("path", { d: "M141.332 37.0996H4354.59V3345.83H141.332V37.0996Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_145_925)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask5_145_925", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 143, y: 37, width: 4202, height: 3309 }, /* @__PURE__ */ React.createElement("path", { d: "M1517.55 37.0996H4072.51C4159.86 37.0996 4241.91 79.1065 4292.95 149.969C4343.99 220.831 4357.92 311.715 4330.24 394.551L3465.77 2988.18C3394.51 3201.75 3194.68 3345.83 2969.73 3345.83H414.769C327.418 3345.83 245.368 3303.82 194.331 3232.96C143.295 3162.1 129.358 3071.02 157.035 2988.18L1021.51 394.551C1092.77 181.179 1292.6 37.0996 1517.55 37.0996Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_145_925)" }, /* @__PURE__ */ React.createElement("path", { d: "M37.8851 37.0996H4445.08V3345.83H37.8851V37.0996Z", fill: "#21E8E6", fillOpacity: 0.8 })))));
export default SvgLogoSmall;
