import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef, useState } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area"
import { ItemPreview } from "@/components/core/management/item-preview"
// import { usePanelStore } from "@/lib/usePanelStore";



type TItem = {
  slug: string,
  title: string;
  // status: string;
  // type: string;
  subtitle: string;
  // sessionId: number;
  content: string | null;
  createdAt: string | null;
  tags: string[];
  isOpen: boolean;
  disabled?: boolean;
  // created_at: string;
  // started_at: string | null;
  // updated_at: string;
}
export type ItemListProps = {
  dndEnabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[] | TItem[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (item: any | TItem) => void;
  layoutClasses?: string;
  // handleTogglePublishPosition?: (item: TItem) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // handleOnEdit?: (item: any | TItem) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownActions?: (item: any) => {
    title: string;
    onClick: () => void;
    customClasses?: string;
  }[]
}



export function ItemList({ items, onClick, layoutClasses = "", dropdownActions, dndEnabled = false }: Readonly<ItemListProps>) {
  // const { setPanelItem } = usePanelStore()
  const parentRef = useRef<HTMLDivElement>(null);
  const [itemSizes, setItemSizes] = useState<number[]>([]);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => (itemSizes[index] || 100) + 8, // Add 8px for margin
    overscan: 5,
    measureElement: (element) => {
      const height = element.getBoundingClientRect().height;
      setItemSizes((prev) => {
        const newSizes = [...prev];
        const index = rowVirtualizer.getVirtualItems()[0].index + parseInt((element as HTMLElement).dataset.index || "0");
        newSizes[index] = height;
        return newSizes;
      });
      return height + 8; // Add 16px for margin
    },
  });

  return (
    <ScrollArea
      className={layoutClasses}
      // @ts-ignore
      viewPortStyle={dndEnabled ? {
        overflowX: "visible",
        overflowY: "visible",
        overflow: "visible"
      } : {}}
    >
      <div
        ref={parentRef}
        className="flex flex-col p-4 pt-0 pb-40 max-w-2xl xl:max-w-4xl mx-auto h-[80vh] overflow-auto no-scrollbar"
      >
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const item = items[virtualItem.index];
            return (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={rowVirtualizer.measureElement}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItem.start}px)`,
                  marginBottom: '8px', // Add margin here
                }}
              >
                <ItemPreview
                  draggable={dndEnabled}
                  id={item?.applicationId}
                  title={item?.title}
                  titleJSX={item?.titleJSX}
                  completedAt={item?.createdAt}
                  subtitle={item?.subtitle}
                  tags={item?.tags || []}
                  onClick={() => onClick?.(item)}
                  content={item?.content}
                  dropdownActions={dropdownActions?.(item)}
                  isOpen={item?.isOpen}
                  disabled={item?.disabled}
                  applicantCount={item?.applicantCount}
                />
              </div>
            );
          })}
        </div>
      </div>
    </ScrollArea>
  );
}
