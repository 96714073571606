import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

import { useState } from 'react';

import {
  ATSConnection,
  ATSConnectionAuthMethod,
  ATSConnectionData,
  ATSConnectionId,
} from './types';
import { useParams } from '@tanstack/react-router';
import { ATSConnectionList } from './ATSConnectionList';
import { useATSConnect } from '@/fetchers/useOrganization';
import { RouterParams } from '@/main';
import { ArrowLeft } from 'lucide-react';
import { ATSConnectionAPIKeyForm } from './ATSConnectionAPIKeyForm';
import { WorkableConnection } from './WorkableConnection';
import { BreezyConnection } from './BreezyConnection';
import { JobDivaConnection } from './JobDivaConnection';
import { ZohoRecruitConnection } from './ZohoRecruitConnection';
import { Alert } from '../alert';
import { JobAdderConnection } from './JobAdderConnection';
import { LoxoConnection } from './LoxoConnection';

export function ATSConnectionDialog() {
  const [open, setOpen] = useState(false);
  const [integration, setIntegration] = useState<ATSConnection>();
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const atsConnect = useATSConnect();

  function handleConnect(data: ATSConnectionData) {
    if (!integration) return;
    atsConnect({
      authMethod: integration.authMethod,
      integrationType: integration.id,
      organizationId,
      credentials: data,
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="mt-4">
          Connect to your ATS
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>
          {integration ? (
            <Button variant={'ghost'} onClick={() => setIntegration(undefined)}>
              <ArrowLeft className="mr-2 size-4" />
              Back
            </Button>
          ) : (
            <>Select integration</>
          )}
        </DialogTitle>
        {integration ? (
          <div className="flex w-full flex-col items-center space-y-6">
            <img
              src={integration.bigImageUrl}
              className="my-4 h-[80px] object-center"
            />
            <Alert
              severity="warning"
              title="Administrator role required"
              description={`You must be an administrator of ${integration.name} in order to link to Veton successfully.`}
            />
            {integration.authMethod === ATSConnectionAuthMethod.API_KEY && (
              <ATSConnectionAPIKeyForm onSubmit={handleConnect} />
            )}
            {integration.id === ATSConnectionId.WORKABLE && (
              <WorkableConnection onSubmit={handleConnect} />
            )}
            {integration.id === ATSConnectionId.BREEZY && (
              <BreezyConnection onSubmit={handleConnect} />
            )}
            {integration.id === ATSConnectionId.JOBDIVA && (
              <JobDivaConnection onSubmit={handleConnect} />
            )}
            {integration.id === ATSConnectionId.ZOHO_RECRUIT && (
              <ZohoRecruitConnection />
            )}
            {integration.id === ATSConnectionId.JOBADDER && (
              <JobAdderConnection />
            )}
            {integration.id === ATSConnectionId.LOXO && (
              <LoxoConnection onSubmit={handleConnect} />
            )}
          </div>
        ) : (
          <ATSConnectionList onSelectIntegration={setIntegration} />
        )}
      </DialogContent>
    </Dialog>
  );
}
