import { AutoSendOption, InterviewDelay } from './types';

export const interviewDelaySelectOptions: Record<InterviewDelay, string> = {
  [InterviewDelay.NO_DELAY]: 'No Delay',
  [InterviewDelay.FIFTEEN_MINUTES]: '15 Minutes',
  [InterviewDelay.THIRTY_MINUTES]: '30 Minutes',
  [InterviewDelay.ONE_HOUR]: '1 Hour',
  [InterviewDelay.ONE_DAY]: '1 Day',
  [InterviewDelay.TWO_DAYS]: '2 Days',
  [InterviewDelay.THREE_DAYS]: '3 Days',
};

export const autoSendSelectOptions: Record<AutoSendOption, string> = {
  [AutoSendOption.SEND_ALL]: 'Send All',
  [AutoSendOption.GOOD_FIT]: 'Good Fits Only',
  [AutoSendOption.MAYBE_AND_UP]: 'Maybe and Up',
};

export const INTERVIEW_LINK_CHARACTER_COUNT = 40;

export const SMS_CHARACTER_WARNING_LIMIT = 160;

export const SMS_CHARACTER_PREVENT_INPUT_LIMIT = 450;

export const SMS_CHARACTER_LIMIT_ERROR_MESSAGE =
  "You're likely sending multiple messages, so keep them shorter for better deliverability.";

export const SMS_CHARACTER_PREVENT_INPUT_ERROR_MESSAGE =
  "You've reached the limit and can't add more characters.";

// Emoji ranges in Unicode
export const EMOJI_REGEX =
  /[\u{1F300}-\u{1F9FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F000}-\u{1F02F}]|[\u{1F0A0}-\u{1F0FF}]|[\u{1F100}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{1F910}-\u{1F96B}]|[\u{1F980}-\u{1F9E0}]/gu;
