import {
  createRoute,
  ErrorComponent,
  Link,
  Outlet,
  useRouterState,
} from '@tanstack/react-router';
import { Route as CompanyRoute } from '@/routes/organizations.$organizationId.company';
import { Loader } from '@/components/core/loader';
import { buttonVariants } from '@/components/ui/button';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { cn } from '@/lib/utils';
import { useUpdateMarginWithGlobalAlert } from '@/hooks/useUpdateMarginWithGlobalAlert';

export const Route = createRoute({
  getParentRoute: () => CompanyRoute,
  path: '/position-defaults',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsPage,
});

export default function CompanyPositionDefaultsPage() {
  const containerRef = useUpdateMarginWithGlobalAlert();
  const { organizationId } = Route.useParams();
  const {
    location: { pathname },
  } = useRouterState();
  const { selectedOrganization } = useUserDetailStore();

  const positionDefaultMenuItems = [
    {
      href: `/organizations/${organizationId}/company/position-defaults/application`,
      title: 'Application',
    },
    {
      href: `/organizations/${organizationId}/company/position-defaults/communication`,
      title: 'Communication',
    },
    {
      href: `/organizations/${organizationId}/company/position-defaults/ai-config`,
      title: 'AI Configuration',
    },
    {
      href: `/organizations/${organizationId}/company/position-defaults/hiring-team`,
      title: 'Hiring Team',
    },
  ];

  return (
    <div className="flex flex-col">
      <Breadcrumb
        items={[
          {
            title: 'Board Settings',
            href: `/organizations/${organizationId}/company/settings`,
          },
          {
            title: 'Position Defaults',
          },
        ]}
      />
      <div className="mx-auto flex w-full max-w-4xl flex-col space-y-3 has-[.communication-form]:max-w-full">
        <div className="mx-auto flex w-full max-w-4xl flex-col">
          <h2 className="text-2xl font-bold tracking-tight">
            Position Defaults
          </h2>
          <p className="text-muted-foreground">
            Manage default settings for new positions on this board. Existing
            positions will not be affected by changes made here.
          </p>
        </div>
        <div className="flex h-screen w-full flex-col gap-4">
          <div className="mx-auto flex w-full max-w-4xl flex-shrink-0 items-center gap-4 overflow-x-auto p-2">
            {(selectedOrganization?.integrationName
              ? positionDefaultMenuItems.slice(1)
              : positionDefaultMenuItems
            ).map((item) => (
              <Link
                key={item.title}
                // @ts-ignore
                to={item.href}
                className={buttonVariants({
                  variant: 'ghost',
                  className:
                    pathname === item.href ? 'bg-muted' : 'hover:bg-muted',
                })}
              >
                {item.title}
              </Link>
            ))}
          </div>
          <div
            ref={containerRef}
            className={cn(
              'mb-[315px] w-full overflow-y-auto px-2 pb-4 md:mb-[193px]',
              'has-[.communication-form]:mx-auto has-[.communication-form]:w-full has-[.communication-form]:max-w-7xl'
            )}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
