import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { useEffect, useState } from "react"
import { DownloadIcon } from "lucide-react";
import { useImportApplicant } from "@/lib/useImportApplicant";
import { MIME_TYPE, UploadInput } from "../common/UploadInput";
// import { useIsMutating } from "@tanstack/react-query";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
// import { Progress } from "@/components/ui/progress"
import { toast } from "sonner"
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { RequiredStar } from "../common/RequiredStar";
import { cn } from "@/lib/utils";


export function ImportApplicantDialog({ onSelectFile, onUploadCompleted }: { onSelectFile: (file: File | FileList, isSpreadsheet?: boolean) => Promise<void>, onUploadCompleted: () => void }) {
  const [open, setOpen] = useState(false);
  // const isMutating = useIsMutating()
  const { downloadTemplate } = useImportApplicant()
  const [checked, setChecked] = useState(false)
  const [files, setFiles] = useState<{ files: File | FileList, isSpreadsheet: boolean } | null>(null)

  useEffect(() => {
    if (!files || !checked) {
      return
    }

    handleFileUpload(files.files, files.isSpreadsheet)
  }, [files, checked])


  const handleFileUpload = async (files: File | FileList, isSpreadsheet: boolean) => {
    let toastId;

    try {
      if (isSpreadsheet) {
        await onSelectFile(files as File, true)
      } else {
        const toastId = toast("Upload in progress 0%", {
          description: "Please do not close this window while uploading",
          duration: Infinity,
          position: "top-right",
        })


        setOpen(false) // Close the modal immediately after file selection

        const fileList = files as FileList
        for (let i = 0; i < fileList.length; i++) {
          // @ts-ignore
          await onSelectFile([fileList[i]], false)
          const progress = Math.round(((i + 1) / fileList.length) * 100)
          toast(`Upload in progress ${progress}%`, {
            id: toastId,
            description: "Please do not close this window while uploading",
          })
        }
        toast.success("All uploads completed successfully", { id: toastId })
        // close toast after 2 seconds
        setTimeout(() => {
          toast.dismiss(toastId)
        }, 2000)

      }

      onUploadCompleted()
    } catch (error) {
      if (toastId) {
        toast.error("Upload failed", {
          id: toastId,
          description: "An error occurred during the upload.",
        })
      }
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          className='h-[40px]'
          variant="outline"
        >
          <DownloadIcon className='size-4 mr-1' />
          Import
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[70vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Import Applicants</DialogTitle>
          <DialogDescription>
            Import applicants from a spreadsheet or upload multiple resumes
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="resumes" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger onFocus={() => setFiles(null)} value="resumes">Resumes</TabsTrigger>
            <TabsTrigger onFocus={() => setFiles(null)} value="spreadsheet">Spreadsheet</TabsTrigger>
          </TabsList>
          <TabsContent value="spreadsheet">
            <div className="space-y-12 w-full pt-6">
              <div className="flex items-center gap-3 ">
                <NumberDisplay>1</NumberDisplay>
                <div className="flex flex-col">
                  <p className="text-sm">Download the Applicant template</p>
                  <div>
                    <Button onClick={downloadTemplate}
                      size={'sm'}
                      className="mt-1"
                    >
                      <DownloadIcon className='size-4 mr-1' />
                      Download
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <NumberDisplay>2</NumberDisplay>
                <div className="flex flex-col">
                  <p className="text-sm font-bold">Add Content</p>
                  <div>
                    <p className="text-sm">Don't delete or edit columns</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-3 w-full">
                <NumberDisplay>3</NumberDisplay>
                <div className="flex flex-col w-full">
                  <p className="text-sm font-bold">Upload your file</p>
                  <div className="w-full">
                    <UploadInput
                      multiple={false}
                      mimeType={MIME_TYPE.EXCEL}
                      onChange={async (event) => {
                        const file = event.target.files?.[0]
                        if (!file) {
                          console.log('no file')
                          return
                        }
                        // await handleFileUpload(file, true)
                        setFiles({ files: file, isSpreadsheet: true })
                      }}
                    />
                  </div>

                  <ConsentCheckBox
                    checked={checked}
                    setChecked={setChecked}
                    // @ts-ignore
                    errored={files && !checked}
                  />
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="resumes">
            <div className="space-y-6 w-full pt-6">

              <UploadInput
                mimeType={`${MIME_TYPE.PDF},${MIME_TYPE.DOC}`}
                multiple={true}
                onChange={async (event) => {
                  const files = event.target.files
                  if (!files || files.length === 0) {
                    console.log('No files selected')
                    return
                  }
                  // await handleFileUpload(files, false)
                  // @ts-ignore
                  setFiles({ files: event.target.files, isSpreadsheet: false })
                }}
              />

              <p className="text-sm">Upload multiple PDF or DOCX files</p>
            </div>


            <ConsentCheckBox
              checked={checked}
              setChecked={setChecked}
              // @ts-ignore
              errored={files && !checked}
            />

          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button
            variant={'outline'}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NumberDisplay({ children }: any) {
  return (
    <div>
      <div className="size-10 bg-gray-200 rounded-full text-xs font-bold flex items-center justify-center text-gray-900">
        {children}
      </div>
    </div>
  )
}

function ConsentCheckBox({ checked, setChecked, errored }: { checked: boolean, setChecked: (value: boolean) => void, errored?: boolean }) {
  return (
    <div className={cn("flex space-x-1 items-center mt-4", errored && "text-red-500")}>
      <Checkbox
        checked={checked}
        onCheckedChange={setChecked}
      />
      <Label>
        I agree to the <a
          href="https://www.veton.ai/terms-of-service"
          className="underline underline-offset-4 hover:text-primary"
          target="_blank"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://www.veton.ai/privacy-policy"
          className="underline underline-offset-4 hover:text-primary"
          target="_blank"
        >
          Privacy Policy
        </a>
        .
      </Label>
      <RequiredStar />
    </div>
  )
}