import AlertModal from '@/components/core/common/AlertModal'
import { GenericTable } from '@/components/core/common/Table'
import { Loader } from '@/components/core/loader'
import { useAdminGetAllParentOrganizations, useAdminParentOrganizationPlanUpdate, useAdminTeamMemberUpdate } from '@/fetchers/useOrganization'
import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'

export const Route = createFileRoute('/admin-panel/organizations')({
  component: () => <AdminOrganizationsPage />
})

function AdminOrganizationsPage() {
  const [updatePlanModalOpen, setUpdatePlanModalOpen] = useState(false)
  const { data, isFetching } = useAdminGetAllParentOrganizations({
    enabled: true,
  })

  const updateTeam = useAdminTeamMemberUpdate()
  const updatePlan = useAdminParentOrganizationPlanUpdate()

  if (isFetching) {
    return <Loader />
  }



  function handleUpdatePlan() {
    updatePlan({
      // @ts-ignore
      parentOrganizationId: updatePlanModalOpen?.id,
      // @ts-ignore
      stripeCustomerId: document.getElementById('stripeCustomerId')?.value,
      // @ts-ignore
      plan: document.getElementById('plan')?.value
    })

  }

  return (
    <>
      <AlertModal
        open={!!updatePlanModalOpen}
        onClose={() => setUpdatePlanModalOpen(false)}
        title="You are about to send reminders to all candidates"
        // @ts-ignore
        body={(
          <div>
            <p>Are you sure you want to send reminders to all candidates? The interview email will be send to all candidates who have not started their interviews.</p>

            <input
              // @ts-ignore
              defaultValue={updatePlanModalOpen?.stripeCustomerId}
              id="stripeCustomerId"
            />


            <select
              // @ts-ignore
              defaultValue={updatePlanModalOpen?.plan}
              id='plan' onChange={
                (e) => console.log('item', e.target.value)
              }>
              <option value='free'>Free</option>
              <option value='basic'>Basic</option>
              <option value='standard'>Standard</option>
              <option value='premium'>Premium</option>
              <option value='basic_annual'>Basic Annual</option>
              <option value='standard_annual'>Standard Annual</option>
              <option value='premium_annual'>Premium Annual</option>
              <option value='admin'>Admin</option>
            </select>

          </div>
        )}
        confirmText="Send"
        onProceed={handleUpdatePlan}
      />

      <div>
        <GenericTable
          tableHeaders={['id', 'Name', "Plan", "Stripe Customer Id", "Total Interview Count"]}
          items={data || []}
          itemKeyOrder={['id', 'name', 'plan', "stripeCustomerId", 'completedInterviewCount']}
          title="Organizations"
          description="Organizations"
          tableActions={[
            {
              title: "Join",
              onClick: (item) => {
                updateTeam({
                  parentOrganizationId: item.id,
                  action: "add",
                })
              }
            },
            {
              title: "Leave",
              onClick: (item) => {
                updateTeam({
                  parentOrganizationId: item.id,
                  action: "remove",
                })
              }
            },

            {
              title: "Update Plan",
              onClick: (item) => {
                setUpdatePlanModalOpen(item)
              }
            },

          ]}
        />
      </div>
    </>
  )
}