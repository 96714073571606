import {
  BellDotIcon,
  BookUser,
  Building2Icon,
  ChevronLeft,
  ChevronRight,
  Columns4,
  ExternalLinkIcon,
  HelpCircle,
  Inbox,
  LogOut,
  Receipt,
  User2,
} from "lucide-react"


import { cn } from "@/lib/utils"
import { Separator } from "@/components/ui/separator"
import { AccountSwitcher } from "./account-switcher"
import { Nav } from "@/components/core/dashboard/nav"

import { Link, useRouterState } from "@tanstack/react-router"
import { useUserDetailStore, useUserDetails } from "@/fetchers/useUserDetails"
import { Button } from "@/components/ui/button"
import vetonLogo from "@/assets/svg/logo-whitebg.svg"
import vetonLogoSmall from "@/assets/svg/logo-small.svg"
import { useGetMemberships, useOrganizationStore } from "@/fetchers/useOrganization"
import { Loader } from "../loader"
import { UpdateParentOrganizationPopup } from "../parent-organization/update-parent-organization-popup"
import { useIntercomMessenger } from "@/lib/useIntercomMessenger"
import { AcademicCapIcon } from "@heroicons/react/24/solid"



type TProps = {
  isCollapsed: boolean
  defaultLayout: number[]
  navCollapsedSize: number
  setIsCollapsed: (isCollapsed: boolean) => void
}
export function Sidebar({
  isCollapsed,
  // defaultLayout,
  // navCollapsedSize,
  setIsCollapsed
}: Readonly<TProps>) {
  // const navigate = useNavigate()
  const { location: { pathname } } = useRouterState();
  const { data: userDetails, isFetching: isFetchingUserDetails } = useUserDetails()
  const { selectedOrganization, setSelectedOrganization } = useUserDetailStore()
  const { data: memberships, isFetching: isFetchingMemberships } = useGetMemberships({ enabled: !isFetchingUserDetails && !!userDetails?.id })
  const { selectedParentOrganization } = useOrganizationStore()
  const { openChat } = useIntercomMessenger()

  // const { data: organizations, isFetching: organizationsFetching } = useGetOrganizations({
  //   enabled: !!selectedMembership?.id
  // })

  const isNewPosition = pathname === `/organizations/${selectedOrganization?.id}/positions/create`

  function onToggleCollapsedClick() {
    setIsCollapsed(!isCollapsed)
    document.cookie = `react-resizable-panels:collapsed=${!isCollapsed}`
  }

  if (isFetchingMemberships) {
    return (
      <Loader />
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const boardLinks: any[] = [
    {
      title: "Boards",
      label: "",
      icon: Columns4,
      variant: pathname === `/organizations` ? "default" : "ghost",
      to: `/organizations`,
      onClick: () => {
        setSelectedOrganization()
      }
    },
    {
      title: "Careers page",
      label: "",
      icon: ExternalLinkIcon,
      variant: "ghost",
      // to: `/organizations/${selectedOrganization?.id}/company`,
      href: `https://${selectedOrganization?.slug}${selectedOrganization?.slug === "careers" ? "" : ".careers"}.veton.ai`,
      disabled: !selectedOrganization?.slug,
    },
    {
      title: "Board Settings",
      label: "",
      icon: Building2Icon,
      variant: pathname === `/organizations/${selectedOrganization?.id}/company` ? "default" : "ghost",
      to: `/organizations/${selectedOrganization?.id}/company`,
    },
    {
      title: "Positions",
      label: "",
      icon: Inbox,
      variant: !isNewPosition && pathname.includes(`organizations/${selectedOrganization?.id}/positions`) ? "default" : "ghost",
      to: `/organizations/${selectedOrganization?.id}/positions/list`,
    },
    {
      title: "Team Settings",
      label: "",
      icon: BookUser,
      variant: pathname === `/team-management` ? "default" : "ghost",
      to: `/team-management`,
    },
    {
      title: "Billing",
      label: "",
      icon: Receipt,
      variant: pathname === `/billing` ? "default" : "ghost",
      to: `/billing`,
    },
  ].filter(link => !(selectedOrganization?.integrationName && link.title === "Careers page"))

  const userLinks = [
    {
      title: userDetails?.firstName && userDetails?.lastName ? `${userDetails?.firstName} ${userDetails?.lastName}` : (userDetails?.email || "Profile"),
      label: "",
      icon: User2,
      variant: pathname === `/user/profile` ? "default" : "ghost",
      to: `/user/profile`,
    },
    {
      title: "Notifications",
      label: "",
      icon: BellDotIcon,
      variant: pathname === `/user/notifications` ? "default" : "ghost",
      to: `/user/notifications`,
    },

    {
      title: "Support",
      label: "",
      icon: HelpCircle,
      variant: "ghost",
      // to: `/user/profile`,
      onClick: () => {
        openChat()
      }
    },
    {
      title: "Logout",
      label: "",
      icon: LogOut,
      variant: "ghost",
      to: `/logout`,
    },
  ]

  if (userDetails?.email.endsWith("@veton.ai")) {
    userLinks.push({
      title: "Admin Panel",
      label: "",
      icon: AcademicCapIcon,
      variant: pathname === `/admin` ? "default" : "ghost",
      to: `/admin-panel`,
    })
  }




  return (
    <>
      <div className={cn("absolute top-[50%] bottom-[50%] z-10", isCollapsed ? "left-[37px]" : "left-[205px]")}>
        <Button onClick={onToggleCollapsedClick} variant="outline" className="rounded-full p-0 size-6 ">
          {isCollapsed ? <ChevronRight className='size-4' /> :
            <ChevronLeft className='size-4' />}
        </Button>
      </div>
      <div

        className={cn(isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out relative")}
      >


        <div className={cn("flex h-[56px] items-center justify-center", isCollapsed ? 'h-[52px] p-2' : 'px-2')}>
          {/* <AccountSwitcher isCollapsed={isCollapsed} organizations={userDetails?.organizations || []} /> */}
          <Link
            to='/organizations'
          >
            <img
              src={isCollapsed ? vetonLogoSmall : vetonLogo}
              alt='veton.ai logo'
              className='size-40'
            />
          </Link>
        </div>
        <Separator />

        {/* <ScrollArea className="overflow-auto max-h-screen pb-20"> */}

        <h2 className={cn("mt-4 mb-4 px-4 text-lg font-semibold tracking-tight", isCollapsed && "hidden")}>
          Management
        </h2>
        <div className={cn("flex h-[40px] items-center justify-center", isCollapsed ? 'h-[75px] mt-2 flex-col' : 'px-2')}>
          <AccountSwitcher isCollapsed={isCollapsed} memberships={memberships || []} />
          {selectedParentOrganization?.memberRole === "admin" && (
            <UpdateParentOrganizationPopup />
          )}
        </div>
        <Nav
          isCollapsed={isCollapsed}
          links={selectedOrganization?.id ? boardLinks : [
            {
              title: "Boards",
              label: "",
              icon: Columns4,
              variant: pathname === `/organizations` ? "default" : "ghost",
              to: `/organizations`,
            },
            {
              title: "Team Settings",
              label: "",
              icon: BookUser,
              variant: pathname === `/team-management` ? "default" : "ghost",
              to: `/team-management`,
            },
            {
              title: "Billing",
              label: "",
              icon: Receipt,
              variant: pathname === `/billing` ? "default" : "ghost",
              to: `/billing`,
            },
          ]}
        />

        <Separator />
        <h2 className={cn("mt-4 mb-2 px-4 text-lg font-semibold tracking-tight", isCollapsed && "hidden")}>
          User
        </h2>

        <Nav
          isCollapsed={isCollapsed}
          // @ts-ignore
          links={userLinks}
        />

        {/* </ScrollArea> */}
      </div>
    </>
  )
}