import {
  TInterviewConfirmation,
  useSubmitFeedbackMutation,
} from '@/fetchers/useInterview';
import InterviewConfirmationHeader from '../interview-confirmation/interview-confirmation-header';
import { Trans, useTranslation } from 'react-i18next';
import StarRating from '../StarRating';
import { useEffect, useState } from 'react';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { useParams } from '@tanstack/react-router';
import { RouterParams } from '@/main';
import { CircleCheckBig } from 'lucide-react';
import { useIntercom } from 'react-use-intercom';

type InterviewCompleteProps = {
  interviewDetails: TInterviewConfirmation;
};

const InterviewComplete = ({ interviewDetails }: InterviewCompleteProps) => {
  const { interviewUUid } = useParams({ strict: false }) as RouterParams;
  const [rating, setRating] = useState(0);
  const [feedbackComment, setFeedbackComment] = useState('');
  const { organization, positionName } = interviewDetails;
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  const submitFeedbackMutation = useSubmitFeedbackMutation();
  const { boot } = useIntercom();

  const handleSubmit = () => {
    submitFeedbackMutation({
      interviewUUid,
      rating,
      comment: feedbackComment,
    });
  };

  useEffect(() => {
    boot();
  }, [boot]);

  return (
    <div className="max-h-screen overflow-y-auto pb-4">
      <div className="border-b">
        <InterviewConfirmationHeader
          logo={organization.logoUrl}
          companyReachOutEmail={organization.companyReachOutEmail ?? ''}
          title={organization.name}
        />
      </div>
      <div className="flex flex-col justify-center gap-6 p-4 text-center sm:px-0">
        <div className="flex flex-col">
          <span className="mx-auto mb-6 w-full text-2xl font-bold">
            {t('interview_completed')}
          </span>
          <p className="mx-auto w-full max-w-2xl whitespace-pre-wrap text-left">
            {t('thank_you_message', {
              position: positionName,
              company: organization.name,
            })}
          </p>
        </div>
        <div className="mx-auto flex w-full max-w-2xl flex-col gap-2">
          {interviewDetails.ratingReceived && (
            <div className="mx-auto flex items-center gap-2">
              <CircleCheckBig className="size-6 text-green-500" />
              <p className="text-base font-semibold text-foreground">
                {t('feedback_thanks')}
              </p>
            </div>
          )}
          {!interviewDetails.ratingReceived && (
            <>
              <span className="text-left text-lg font-semibold text-foreground">
                {t('feedback_title')}
              </span>
              <StarRating
                rating={rating}
                onChange={setRating}
                className="mx-auto"
                lowestLabel={t('feedback_very_bad')}
                highestLabel={t('feedback_very_good')}
              />
              <p className="text-left text-sm text-muted-foreground">
                {t('feedback_comment_label')}
              </p>
              <Textarea
                className="mt-1 w-full rounded-md border p-2"
                placeholder={t('feedback_comment_placeholder')}
                value={feedbackComment}
                onChange={(e) => setFeedbackComment(e.target.value)}
                maxLength={300}
              />
              <Button
                onClick={handleSubmit}
                className="ml-auto"
                disabled={!rating}
              >
                {t('feedback_submit')}
              </Button>
            </>
          )}
        </div>
        <div className="mx-auto flex w-full max-w-2xl gap-1.5 rounded-lg text-sm">
          <Trans
            t={t}
            components={{
              emailTag: (
                <a
                  href={`mailto:${organization.companyReachOutEmail}`}
                  className="w-fit text-blue-500"
                />
              ),
            }}
            values={{
              companyReachOutEmail: organization.companyReachOutEmail,
            }}
          >
            {t('interview_no_longer_active_description')}
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default InterviewComplete;
