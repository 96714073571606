import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { ChartType } from './types';
import { CustomerActivityGraphData } from '@/fetchers/useOrganization';
import { Badge } from '@/components/ui/badge';
export function ApplicationFlowChart({
  title,
  data,
  chartConfig,
  dataKey,
  secondDataKey,
  chartTooltipClassName,
  chartType,
  total,
}: {
  chartType: ChartType;
  chartConfig: ChartConfig;
  title: string;
  data: CustomerActivityGraphData[];
  dataKey: string;
  secondDataKey?: string;
  chartTooltipClassName?: string;
  total?: number;
}) {
  const chartByType = {
    [ChartType.Bar]: (
      <>
        <Bar
          dataKey={dataKey}
          fill={`var(--color-${dataKey})`}
          stroke={`var(--color-${dataKey})`}
          radius={[2, 2, 0, 0]}
        />
        {secondDataKey && (
          <Bar
            dataKey={secondDataKey}
            fill={`var(--color-${secondDataKey})`}
            stroke={`var(--color-${secondDataKey})`}
            radius={[2, 2, 0, 0]}
          />
        )}
      </>
    ),
    [ChartType.Area]: (
      <>
        <Area
          dataKey={dataKey}
          type="monotone"
          fill={`var(--color-${dataKey})`}
          stroke={`var(--color-${dataKey})`}
        />
        {secondDataKey && (
          <Area
            dataKey={secondDataKey}
            type="monotone"
            fill={`var(--color-${secondDataKey})`}
            stroke={`var(--color-${secondDataKey})`}
          />
        )}
      </>
    ),
  };

  const mainChartByType = {
    [ChartType.Bar]: BarChart,
    [ChartType.Area]: AreaChart,
  };

  const MainChart = mainChartByType[chartType];
  return (
    <Card>
      <CardHeader className="p-4">
        <CardTitle className="flex items-center gap-2">
          {title}
          {total && (
            <Badge
              variant="default"
              style={{
                backgroundColor: chartConfig[dataKey].color,
              }}
            >
              {total}
            </Badge>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0 pr-4">
        <ChartContainer config={chartConfig}>
          <MainChart
            accessibilityLayer
            data={data}
            margin={{
              left: -8,
              top: 1,
            }}
          >
            <CartesianGrid />
            <YAxis tickLine={false} allowDecimals={false} />
            <XAxis dataKey="date" tickLine={false} />
            <ChartTooltip
              cursor={false}
              accessibilityLayer
              content={
                <ChartTooltipContent
                  accessibilityLayer
                  indicator="line"
                  className={chartTooltipClassName}
                />
              }
            />
            {chartByType[chartType]}
          </MainChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
