import { format, subDays } from 'date-fns';
import { DateFilter, IntervalFilter } from './types';

export const dateFilterOptions = [
  { label: 'All', value: DateFilter.All },
  { label: 'Last 7 days', value: DateFilter.Last7Days },
  { label: 'Last 30 days', value: DateFilter.Last30Days },
  { label: 'Last 90 days', value: DateFilter.Last90Days },
];

export const intervalFilterOptions = [
  { label: 'Daily', value: IntervalFilter.Daily },
  { label: 'Weekly', value: IntervalFilter.Weekly },
  { label: 'Monthly', value: IntervalFilter.Monthly },
  { label: 'Yearly', value: IntervalFilter.Yearly },
];

export const startEndDateByDateFilter = {
  [DateFilter.All]: {
    startDate: undefined,
    endDate: undefined,
  },
  [DateFilter.Last7Days]: {
    startDate: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  [DateFilter.Last30Days]: {
    startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  [DateFilter.Last90Days]: {
    startDate: format(subDays(new Date(), 90), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
};
