import {
  CustomerActivityGraphData,
  useAdminGetCustomerActivity,
} from '@/fetchers/useOrganization';
import { useMemo, useState } from 'react';
import { DataType, DateFilter, IntervalFilter } from './types';
import { ChartType } from './types';
import { useUpdateMarginWithGlobalAlert } from '@/hooks/useUpdateMarginWithGlobalAlert';
import { useAdminGetAllParentOrganizations } from '@/fetchers/useOrganization';
import {
  dateFilterOptions,
  intervalFilterOptions,
  startEndDateByDateFilter,
} from './constant';
import { format } from 'date-fns';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ApplicationFlowChart } from './ApplicationFlowChart';
import { Loader } from '../../loader';
import { sortBy } from 'lodash';

export const CustomerActivity = () => {
  const [chartType, setChartType] = useState<ChartType>(ChartType.Area);
  const containerRef = useUpdateMarginWithGlobalAlert();
  const [dateFilter, setDateFilter] = useState<DateFilter>(DateFilter.All);
  const [intervalFilter, setIntervalFilter] = useState<IntervalFilter>(
    IntervalFilter.None
  );
  const [selectedOrganization, setSelectedOrganization] = useState<
    number | null
  >(null);
  const { data: organizations = [], isFetching: isFetchingOrganizations } =
    useAdminGetAllParentOrganizations({
      enabled: true,
    });
  const { data: customerActivity, isFetching: isFetchingCustomerActivity } =
    useAdminGetCustomerActivity({
      organizationId: selectedOrganization ?? 0,
      startDate: startEndDateByDateFilter[dateFilter].startDate,
      endDate: startEndDateByDateFilter[dateFilter].endDate,
      interval: intervalFilter,
    });

  const applicantsData = customerActivity?.applicants;
  const interviewsSentData = customerActivity?.sentInterviews;
  const interviewsCompletedData = customerActivity?.completedInterviews;

  const totalApplicants =
    applicantsData?.reduce((acc, applicant) => acc + applicant.value, 0) ?? 0;
  const totalInterviewsSent =
    interviewsSentData?.reduce((acc, interview) => acc + interview.value, 0) ??
    0;
  const totalInterviewsCompleted =
    interviewsCompletedData?.reduce(
      (acc, interview) => acc + interview.value,
      0
    ) ?? 0;
  const applicantsGraphData: CustomerActivityGraphData[] =
    applicantsData?.map((applicant) => ({
      date: format(applicant.date, 'MMM d'),
      applicants: applicant.value,
    })) ?? [];

  const interviewsSentGraphData: CustomerActivityGraphData[] =
    interviewsSentData?.map((interview) => ({
      date: format(interview.date, 'MMM d'),
      sentInterviews: interview.value,
    })) ?? [];
  const interviewsCompletedGraphData: CustomerActivityGraphData[] =
    interviewsCompletedData?.map((interview) => ({
      date: format(interview.date, 'MMM d'),
      completedInterviews: interview.value,
    })) ?? [];

  const interviewsSentAndCompletedData = useMemo(() => {
    const combinedData = new Map();

    interviewsSentData?.forEach((interview) => {
      combinedData.set(interview.date, {
        date: interview.date,
        sentInterviews: interview.value,
        completedInterviews: 0,
      });
    });

    interviewsCompletedData?.forEach((interview) => {
      if (combinedData.has(interview.date)) {
        combinedData.get(interview.date).completedInterviews = interview.value;
      } else {
        combinedData.set(interview.date, {
          date: interview.date,
          sentInterviews: 0,
          completedInterviews: interview.value,
        });
      }
    });

    const result = Array.from(combinedData.values()).sort(
      // @ts-ignore
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    return result.map((data) => ({
      ...data,
      date: format(data.date, 'MMM d'),
    }));
  }, [interviewsSentData, interviewsCompletedData]);

  if (isFetchingOrganizations) {
    return <Loader />;
  }

  return (
    <div
      ref={containerRef}
      className="mb-[235px] flex flex-col gap-4 p-2 md:mb-[205px] xl:mb-[130px]"
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        {organizations && (
          <div className="flex w-full flex-col gap-2 lg:max-w-[50%] xl:max-w-[25%]">
            <Label>Select organization</Label>
            <Select
              value={selectedOrganization?.toString()}
              onValueChange={(value) => setSelectedOrganization(Number(value))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select an organization" />
              </SelectTrigger>
              <SelectContent>
                {sortBy(organizations, 'name').map((organization) => (
                  <SelectItem
                    key={organization.id}
                    value={organization.id.toString()}
                  >
                    {organization.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        <>
          <div className="flex min-w-[150px] flex-col gap-2">
            <Label>Filter by date</Label>
            <Select
              value={dateFilter}
              onValueChange={(value) => {
                setDateFilter(value as DateFilter);
                setIntervalFilter(IntervalFilter.None);
              }}
              disabled={!customerActivity}
            >
              <SelectTrigger>
                <SelectValue placeholder="Filter by date" />
              </SelectTrigger>
              <SelectContent>
                {dateFilterOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex min-w-[160px] flex-col gap-2">
            <Label>Filter by interval</Label>
            <Select
              value={intervalFilter}
              onValueChange={(value) => {
                setIntervalFilter(value as IntervalFilter);
                setDateFilter(DateFilter.All);
              }}
              disabled={!customerActivity}
            >
              <SelectTrigger>
                <SelectValue placeholder="Filter by interval" />
              </SelectTrigger>
              <SelectContent>
                {intervalFilterOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2">
            <Label>Chart type</Label>
            <Tabs
              value={chartType}
              onValueChange={(value) => setChartType(value as ChartType)}
            >
              <TabsList>
                <TabsTrigger
                  value={ChartType.Area}
                  disabled={!customerActivity}
                >
                  Area
                </TabsTrigger>
                <TabsTrigger value={ChartType.Bar} disabled={!customerActivity}>
                  Bar
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </>
      </div>
      {isFetchingCustomerActivity && (
        <Loader className="static h-[50vh] bg-transparent" />
      )}
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        {applicantsGraphData.length > 0 && (
          <ApplicationFlowChart
            chartType={chartType}
            title="Applied"
            chartConfig={{
              [DataType.Applicants]: {
                label: 'Applicants',
                color: 'hsl(var(--chart-1))',
              },
            }}
            data={applicantsGraphData}
            dataKey={DataType.Applicants}
            total={totalApplicants}
          />
        )}
        {interviewsSentGraphData.length > 0 && (
          <ApplicationFlowChart
            chartType={chartType}
            title="Interviews Sent"
            chartConfig={{
              [DataType.InterviewsSent]: {
                label: 'Interviews Sent',
                color: 'hsl(var(--chart-2))',
              },
            }}
            data={interviewsSentGraphData}
            dataKey={DataType.InterviewsSent}
            chartTooltipClassName="min-w-[150px]"
            total={totalInterviewsSent}
          />
        )}
        {interviewsCompletedGraphData.length > 0 && (
          <ApplicationFlowChart
            chartType={chartType}
            title="Interviews Completed"
            chartConfig={{
              [DataType.InterviewsCompleted]: {
                label: 'Interviews Completed',
                color: 'hsl(var(--chart-3))',
              },
            }}
            data={interviewsCompletedGraphData}
            dataKey={DataType.InterviewsCompleted}
            chartTooltipClassName="min-w-[185px]"
            total={totalInterviewsCompleted}
          />
        )}
        {interviewsSentAndCompletedData.length > 0 && (
          <ApplicationFlowChart
            chartType={chartType}
            title="Interviews Sent and Completed"
            chartConfig={{
              [DataType.InterviewsSent]: {
                label: 'Interviews Sent',
                color: 'hsl(var(--chart-2))',
              },
              [DataType.InterviewsCompleted]: {
                label: 'Interviews Completed',
                color: 'hsl(var(--chart-3))',
              },
            }}
            data={interviewsSentAndCompletedData}
            dataKey={DataType.InterviewsSent}
            secondDataKey={DataType.InterviewsCompleted}
            chartTooltipClassName="min-w-[185px]"
            total={totalInterviewsSent + totalInterviewsCompleted}
          />
        )}
      </div>
    </div>
  );
};
