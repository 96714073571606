import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { TriangleAlert, } from "lucide-react"

import { z } from "zod"
import { useForm } from "react-hook-form"
import { RequiredStar } from "../common/RequiredStar"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
} from "@/components/ui/form"
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"

const connectionSchema = z.object({
  username: z.string({
    required_error: "Email is required.",
  }),
  password: z.string({
    required_error: "Password is required.",
  })
})

type ConnectionValues = z.infer<typeof connectionSchema>



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function BreezyConnection({ info, onSubmit }: any) {
  const form = useForm<ConnectionValues>({
    resolver: zodResolver(connectionSchema),
  })

  function onSubmitHandler(data: ConnectionValues) {
    onSubmit(data)
  }

  return (
    <div className="w-full flex flex-col items-center space-y-6">
      <img
        src={info?.bigImageUrl}
        className="h-[80px] object-center my-4"
      />
      <Alert className="text-left ">
        <TriangleAlert className="h-4 w-4" />
        <AlertTitle>Administrator role required</AlertTitle>
        <AlertDescription>
          You must be an administrator of Breezy in order to link to Veton successfully.
        </AlertDescription>
      </Alert>


      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitHandler)} className="w-full space-y-4">
          <div className="space-y-2 w-full">
            {/* <Label htmlFor="name">Subdomain<RequiredStar /></Label> */}
            {/* <Input id="name" placeholder="Acme Inc." /> */}
            <FormField
              control={form.control}
              name='username'
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="">

                  <FormLabel aria-required>Email<RequiredStar /></FormLabel>
                  <FormDescription>

                  </FormDescription>
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>




          <div className="space-y-2 w-full">
            {/* <Label htmlFor="name">API Key<RequiredStar /></Label> */}
            {/* <Input id="name" placeholder="Your API Key" /> */}
            <FormField
              control={form.control}
              name='password'
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="">

                  <FormLabel aria-required>Password<RequiredStar /></FormLabel>
                  <FormDescription>

                  </FormDescription>
                  <FormControl>
                    <Input placeholder="Password" type='password' {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="self-end">
            <Button className="w-full">Connect</Button>
          </div>
        </form>
      </Form>



    </div>
  )
}