import { PositionCommunicationFormValues } from '@/lib/usePositionCommunicationForm';
import {
  disqualifiedSMSGerman,
  interviewCompletedSMSGerman,
  applicationSMSGerman,
} from './de';
import {
  applicationSMSEnglish,
  disqualifiedSMSEnglish,
  interviewCompletedSMSEnglish,
} from './en';
import {
  applicationSMSSpanish,
  disqualifiedSMSSpanish,
  interviewCompletedSMSSpanish,
} from './es';
import {
  applicationSMSFrench,
  disqualifiedSMSFrench,
  interviewCompletedSMSFrench,
} from './fr';
import {
  applicationSMSDutch,
  disqualifiedSMSDutch,
  interviewCompletedSMSDutch,
} from './nl';
import {
  applicationSMSTurkish,
  disqualifiedSMSTurkish,
  interviewCompletedSMSTurkish,
} from './tr';
import { Language } from '@/i18n';

export const smsTemplatesByLocale: Record<
  Language,
  Pick<
    PositionCommunicationFormValues,
    'afterApplication' | 'afterInterview' | 'disqualified'
  >
> = {
  [Language.DE]: {
    afterApplication: applicationSMSGerman,
    afterInterview: interviewCompletedSMSGerman,
    disqualified: disqualifiedSMSGerman,
  },
  [Language.EN_US]: {
    afterApplication: applicationSMSEnglish,
    afterInterview: interviewCompletedSMSEnglish,
    disqualified: disqualifiedSMSEnglish,
  },
  [Language.EN_GB]: {
    afterApplication: applicationSMSEnglish,
    afterInterview: interviewCompletedSMSEnglish,
    disqualified: disqualifiedSMSEnglish,
  },
  [Language.EN_AU]: {
    afterApplication: applicationSMSEnglish,
    afterInterview: interviewCompletedSMSEnglish,
    disqualified: disqualifiedSMSEnglish,
  },
  [Language.EN_NZ]: {
    afterApplication: applicationSMSEnglish,
    afterInterview: interviewCompletedSMSEnglish,
    disqualified: disqualifiedSMSEnglish,
  },
  [Language.ES]: {
    afterApplication: applicationSMSSpanish,
    afterInterview: interviewCompletedSMSSpanish,
    disqualified: disqualifiedSMSSpanish,
  },
  [Language.FR]: {
    afterApplication: applicationSMSFrench,
    afterInterview: interviewCompletedSMSFrench,
    disqualified: disqualifiedSMSFrench,
  },
  [Language.NL]: {
    afterApplication: applicationSMSDutch,
    afterInterview: interviewCompletedSMSDutch,
    disqualified: disqualifiedSMSDutch,
  },
  [Language.TR]: {
    afterApplication: applicationSMSTurkish,
    afterInterview: interviewCompletedSMSTurkish,
    disqualified: disqualifiedSMSTurkish,
  },
};
