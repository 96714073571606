import { MoreHorizontal } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"


type TProps = {
  items: any[],
  title: string,
  description: string
  tableHeaders: string[]
  itemKeyOrder?: string[]
  tableActions?: {
    title: string,
    onClick: (item: any) => void
  }[],
  tableFooter?: string
}

export function GenericTable({ items, title, description, tableHeaders, itemKeyOrder, tableActions, tableFooter }: TProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>
          {description}
        </CardDescription>
      </CardHeader>
      <CardContent className="h-[70vh] overflow-auto ">
        <Table>
          <TableHeader>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableHead key={index}>{header}</TableHead>
              ))}
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map(item => (
              <TableRow
                className="cursor-pointer"
                key={item.id}
              >
                {itemKeyOrder?.map((key, index) => (
                  <TableCell key={index}>{item[key]}</TableCell>
                ))}



                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button aria-haspopup="true" size="icon" variant="ghost">
                        <MoreHorizontal className="h-4 w-4" />
                        <span className="sr-only">Toggle menu</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel>Actions</DropdownMenuLabel>

                      {tableActions && tableActions.map((action, index) => (
                        <DropdownMenuItem
                          key={index}
                          onClick={() => action.onClick(item)}
                        >
                          {action.title}
                        </DropdownMenuItem>
                      ))}


                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </CardContent>
      {tableFooter && (
        <div className="text-xs text-muted-foreground">

          {tableFooter}

        </div>

      )}
      <CardFooter>
      </CardFooter>
    </Card>
  )
}

