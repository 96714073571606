import { cn } from '@/lib/utils';
import { LayersLoader } from './layers';
import './loader.css';

export function Loader(
  {
    variant = 'layers',
    className,
  }: { variant?: 'ball' | 'layers' | "transparent"; className?: string } = {
      variant: 'layers',
    }
) {
  if (variant === 'layers') {
    return <LayersLoader className={className} />;
  }

  return (
    <div className="h-[75vh]">
      <div className={cn("flex h-full w-full items-center justify-center", variant === "transparent" ? "bg-gray-200/50" : "bg-gray-200")}>
        <div />
      </div>
    </div>
  );
}
