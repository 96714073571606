import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  autoSendSelectOptions,
  INTERVIEW_LINK_CHARACTER_COUNT,
  interviewDelaySelectOptions,
  SMS_CHARACTER_PREVENT_INPUT_LIMIT,
} from './constants';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useFormContext } from 'react-hook-form';
import { PositionCommunicationFormValues } from '@/lib/usePositionCommunicationForm';
import { Loader } from '../../loader';
import { languages } from '@/constants/languages';
import { SelectSearchInput } from '../../select-search-input';
import { useParams, useSearch } from '@tanstack/react-router';
import AlertModal from '../../common/AlertModal';
import { emailTemplatesByLocale } from './email-templates';
import { smsTemplatesByLocale } from './sms-templates';
import { Language } from '@/i18n';
import {
  AutoSendOption,
  CommunicationMethod,
  InterviewDelay,
  MessageFields,
  SelectedCommunicationMessageTab,
} from './types';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { TPosition, useAIConfigForm } from '@/fetchers/usePosition';
import SMSPreview from './SMSPreview';
import { useTranslation } from 'react-i18next';
import EmailPreview from './EmailPreview';
import { AI_NAME_BY_VOICE_MODEL } from '../ai-config/constants';
import MessageField from './MessageField';
import { upperFirst } from 'lodash';
import { handleReplaceVariables } from './utils';
import { Eye } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';

export function PositionCommunicationForm({
  position,
}: {
  position?: TPosition;
}) {
  const { slug } = useParams({ strict: false });
  const { creating } = useSearch({ strict: false });
  const { control, watch, setValue, getValues, clearErrors, trigger } =
    useFormContext<PositionCommunicationFormValues>();
  const [focusedField, setFocusedField] = useState<MessageFields | null>(null);
  const { data: aiConfig } = useAIConfigForm({ slug });
  const voiceModel = aiConfig?.voiceModel;
  const selectedLanguage = watch('language');
  const aiName = voiceModel
    ? AI_NAME_BY_VOICE_MODEL[voiceModel][selectedLanguage]
    : '';

  const [languageSearchQuery, setLanguageSearchQuery] = useState('');
  const [languageChangeAlertModal, setLanguageChangeAlertModal] = useState<{
    open: boolean;
    selectedLanguage: Language | null;
  }>({
    open: false,
    selectedLanguage: null,
  });
  const [selectedCommunicationMessageTab, setSelectedCommunicationMessageTab] =
    useState<SelectedCommunicationMessageTab>(
      SelectedCommunicationMessageTab.INVITATION
    );
  const { selectedOrganization } = useUserDetailStore();
  const { i18n } = useTranslation();

  const autoInterview = watch('autoInterview');
  const isInitialized = watch('isInitialized');
  const interviewType = watch('interviewType');
  const selectedMethod = watch('selectedMethod');
  const isCommunicationMethodSMS = selectedMethod === CommunicationMethod.SMS;
  const isDisqualifiedCommunicationDisabled =
    watch('disqualifiedToggle') === 'disabled';
  const invitationMessage = watch('afterApplication');
  const completedMessage = watch('afterInterview');
  const disqualifiedMessage = watch('disqualified');
  const invitationSubject = watch('afterApplicationSubject');
  const completedSubject = watch('afterInterviewSubject');
  const disqualifiedSubject = watch('disqualifiedSubject');

  const communicationMessageBySelectedTab: Record<
    SelectedCommunicationMessageTab,
    string
  > = {
    [SelectedCommunicationMessageTab.INVITATION]: invitationMessage,
    [SelectedCommunicationMessageTab.COMPLETED]: completedMessage,
    [SelectedCommunicationMessageTab.DISQUALIFIED]: disqualifiedMessage,
  };

  const communicationSubjectBySelectedTab: Record<
    SelectedCommunicationMessageTab,
    string
  > = {
    [SelectedCommunicationMessageTab.INVITATION]: invitationSubject,
    [SelectedCommunicationMessageTab.COMPLETED]: completedSubject,
    [SelectedCommunicationMessageTab.DISQUALIFIED]: disqualifiedSubject,
  };

  const communicationMessage =
    communicationMessageBySelectedTab[selectedCommunicationMessageTab];
  const communicationSubject =
    communicationSubjectBySelectedTab[selectedCommunicationMessageTab];

  const communicationMessageTabs: {
    value: SelectedCommunicationMessageTab;
    label: string;
    disabled?: boolean;
  }[] = [
      {
        value: SelectedCommunicationMessageTab.INVITATION,
        label: 'Invitation',
      },
      {
        value: SelectedCommunicationMessageTab.COMPLETED,
        label: 'Completed',
      },
      {
        value: SelectedCommunicationMessageTab.DISQUALIFIED,
        label: 'Disqualified',
        disabled: isDisqualifiedCommunicationDisabled,
      },
    ];

  const handleInsertVariableToField = (
    variable: string,
    ref: React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement | null>
  ) => {
    if (!focusedField) return;
    let inputRef: HTMLTextAreaElement | HTMLInputElement | null = null;
    const currentValue = getValues(focusedField);
    inputRef = ref.current;

    const selectionStart = inputRef?.selectionStart ?? 0;
    const selectionEnd = inputRef?.selectionEnd ?? 0;

    let newValue =
      currentValue.substring(0, selectionStart) +
      variable +
      currentValue.substring(selectionEnd);

    const valueLength =
      focusedField === 'afterApplication'
        ? newValue.length + INTERVIEW_LINK_CHARACTER_COUNT
        : newValue.length;

    if (
      isCommunicationMethodSMS &&
      valueLength >= SMS_CHARACTER_PREVENT_INPUT_LIMIT
    ) {
      const limit =
        focusedField === 'afterApplication'
          ? SMS_CHARACTER_PREVENT_INPUT_LIMIT - INTERVIEW_LINK_CHARACTER_COUNT
          : SMS_CHARACTER_PREVENT_INPUT_LIMIT;
      newValue = newValue.slice(0, limit);
    }

    setValue(focusedField, newValue, {
      shouldDirty: true,
    });

    // Restore cursor position after React updates the DOM
    setTimeout(() => {
      const newCursorPosition = selectionStart + variable.length;
      inputRef?.focus();
      inputRef?.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleUpdateMessages = (
    language: Language,
    isCommunicationMethodSMS = false
  ) => {
    i18n.changeLanguage(language);
    const templates = isCommunicationMethodSMS
      ? smsTemplatesByLocale[language]
      : emailTemplatesByLocale[language];
    Object.entries(templates).forEach(([key, value]) => {
      setValue(key as keyof PositionCommunicationFormValues, value, {
        shouldDirty: true,
      });
    });
    clearErrors('afterApplication');
    clearErrors('afterInterview');
    clearErrors('disqualified');
    clearErrors('afterApplicationSubject');
    clearErrors('afterInterviewSubject');
    clearErrors('disqualifiedSubject');
  };

  const getPreviewSubject = () => {
    return handleReplaceVariables({
      text: communicationSubject,
      selectedOrganization,
      position,
      isPreview: false,
    });
  };

  const getPreviewMessage = () => {
    let message = communicationMessage;

    if (
      isCommunicationMethodSMS &&
      selectedCommunicationMessageTab ===
      SelectedCommunicationMessageTab.INVITATION
    ) {
      const linkPart =
        '<br><br><span style="color: rgb(59 130 246); text-decoration: underline;">https://link.vetonai.careers/GYkIZ6uxsO</span>';
      if (message) {
        message = message.concat(linkPart);
      } else {
        message.replace(linkPart, '');
      }
    }
    return handleReplaceVariables({
      text: message,
      isPreview: true,
      selectedOrganization,
      position,
    });
  };

  const previewMessage = getPreviewMessage();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  if (!isInitialized) {
    return <Loader className="static mx-auto h-[50vh] bg-transparent" />;
  }
  return (
    <>
      <AlertModal
        open={languageChangeAlertModal.open}
        onClose={() =>
          setLanguageChangeAlertModal({ open: false, selectedLanguage: null })
        }
        title="Change interview language?"
        description="Changing the interview language will remove your communication messages and replace them with default messages in the new language. Would you like to proceed?"
        confirmText="Continue"
        cancelText="Cancel"
        onProceed={() => {
          if (!languageChangeAlertModal.selectedLanguage) return;
          setValue('language', languageChangeAlertModal.selectedLanguage, {
            shouldDirty: true,
          });
          handleUpdateMessages(
            languageChangeAlertModal.selectedLanguage,
            isCommunicationMethodSMS
          );
          setLanguageChangeAlertModal({
            open: false,
            selectedLanguage: null,
          });
          setLanguageSearchQuery('');
        }}
      />

      <Dialog open={previewModalOpen} onOpenChange={setPreviewModalOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>
              Interview {upperFirst(selectedCommunicationMessageTab)}{' '}
              {isCommunicationMethodSMS ? 'SMS' : 'Email'} Preview
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 max-h-[70vh] overflow-auto">
            {isCommunicationMethodSMS ? (
              <SMSPreview message={previewMessage} />
            ) : (
              <EmailPreview
                message={previewMessage}
                subject={getPreviewSubject()}
                position={position}
                interviewType={interviewType}
                aiName={aiName}
                selectedCommunicationMessageTab={selectedCommunicationMessageTab}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Update the form container to match other tabs */}
      <div className="flex flex-col gap-6">

        <FormField
          control={control}
          name="language"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Interview language</FormLabel>
              <FormDescription
                style={{
                  marginTop: 2,
                }}
              >
                Email/SMS communication and AI interviews will be in the
                selected language.
              </FormDescription>
              <FormControl>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    if (creating) {
                      field.onChange(value);
                      setLanguageSearchQuery('');
                      handleUpdateMessages(
                        value as Language,
                        isCommunicationMethodSMS
                      );
                    } else {
                      setLanguageChangeAlertModal({
                        open: true,
                        selectedLanguage: value as Language,
                      });
                    }
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select language" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectSearchInput
                      value={languageSearchQuery}
                      onChange={setLanguageSearchQuery}
                      placeholder="Search language"
                    />
                    {languages
                      .filter((language) =>
                        language?.name
                          .toLowerCase()
                          .includes(languageSearchQuery.toLowerCase())
                      )
                      .map((language) => (
                        <SelectItem
                          key={language.locale}
                          value={language.locale}
                        >
                          {language.flag} {language.name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="selectedMethod"
          render={({ field }) => (
            <FormItem>
              <div className="flex w-full items-center justify-between">
                <FormLabel>Preferred communication method</FormLabel>
                <FormControl>
                  <Tabs
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value);
                      handleUpdateMessages(
                        selectedLanguage,
                        value === CommunicationMethod.SMS
                      );
                    }}
                  >
                    <TabsList>
                      <TabsTrigger value={CommunicationMethod.EMAIL}>
                        Email
                      </TabsTrigger>
                      <TabsTrigger value={CommunicationMethod.SMS}>
                        SMS
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />

              <FormDescription>
                SMS option only available for US numbers.
              </FormDescription>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="interviewType"
          render={({ field }) => (
            <FormItem>
              <div className="flex w-full items-center justify-between">
                <FormLabel>Preferred interview method</FormLabel>
                <FormControl>
                  <Tabs value={field.value} onValueChange={field.onChange}>
                    <TabsList>
                      <TabsTrigger value="phone">Phone Call</TabsTrigger>
                      <TabsTrigger value="video">Video Call</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormDescription>
                Whether you prefer the AI to conduct interviews via phone or
                video call.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="autoInterview"
          render={({ field }) => (
            <FormItem>
              <div className="flex w-full items-center justify-between">
                <FormLabel>Auto send interviews</FormLabel>
                <FormControl>
                  <Tabs
                    value={field.value ? 'enabled' : 'disabled'}
                    onValueChange={(val) => field.onChange(val === 'enabled')}
                  >
                    <TabsList>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                      <TabsTrigger value="enabled">Enabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>
              <FormDescription>
                Automatically send interview requests to applicants.
              </FormDescription>

              <FormMessage />
            </FormItem>
          )}
        />

        {autoInterview && (
          <FormField
            control={control}
            name="autoSendOption"
            render={({ field }) => (
              <FormItem>
                <div className="flex w-full items-center justify-between">
                  <FormLabel className="w-full">Auto Send to</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl className="w-[180px]">
                      <SelectTrigger>
                        <SelectValue placeholder="Auto send option" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.keys(autoSendSelectOptions).map((key) => (
                        <SelectItem key={key} value={key}>
                          {autoSendSelectOptions[key as AutoSendOption]}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <FormDescription>
                  Choose to send all applicants or only good fits.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {autoInterview && (
          <FormField
            control={control}
            name="interviewDelay"
            render={({ field }) => (
              <FormItem>
                <div className="flex w-full items-center justify-between">
                  <FormLabel className="w-full">Interview Delay</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl className="w-[140px]">
                      <SelectTrigger>
                        <SelectValue placeholder="Select interview delay" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.keys(interviewDelaySelectOptions).map((key) => (
                        <SelectItem key={key} value={key}>
                          {interviewDelaySelectOptions[key as InterviewDelay]}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <FormDescription>
                  How soon after applying would you like to invite applicants
                  to perform their interview
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormField
          control={control}
          name="disqualifiedToggle"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Tabs
                  value={field.value}
                  onValueChange={(value) => {
                    if (value === 'disabled') {
                      clearErrors(['disqualified', 'disqualifiedSubject']);
                      const templates = (
                        isCommunicationMethodSMS
                          ? smsTemplatesByLocale[selectedLanguage]
                          : emailTemplatesByLocale[selectedLanguage]
                      ) as PositionCommunicationFormValues;
                      setValue('disqualified', templates.disqualified);
                      if ('disqualifiedSubject' in templates) {
                        setValue(
                          'disqualifiedSubject',
                          templates.disqualifiedSubject
                        );
                      }
                    } else {
                      trigger(['disqualified', 'disqualifiedSubject']);
                    }
                    field.onChange(value);
                  }}
                >
                  <div className="flex w-full items-center justify-between">
                    <FormLabel>Disqualified communication</FormLabel>
                    <TabsList>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                      <TabsTrigger value="enabled">Enabled</TabsTrigger>
                    </TabsList>
                  </div>
                  <FormDescription>
                    Automatically send a message to disqualified applicants.
                  </FormDescription>
                </Tabs>
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <div className="relative space-y-2 mt-4">
          <div className="flex items-center justify-between">
            <FormLabel>Communication messages</FormLabel>
          </div>

          <div className='relative'>
            <Tabs
              value={selectedCommunicationMessageTab}
              onValueChange={(value) =>
                setSelectedCommunicationMessageTab(
                  value as SelectedCommunicationMessageTab
                )
              }
            >
              <TabsList>
                {communicationMessageTabs.map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    disabled={tab.disabled}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
              </TabsList>

              <TabsContent value={SelectedCommunicationMessageTab.INVITATION}>
                <MessageField
                  messageFieldName="afterApplication"
                  subjectFieldName="afterApplicationSubject"
                  focusedField={focusedField}
                  onEditorFocus={() => {
                    if (focusedField) {
                      setFocusedField(null);
                    }
                  }}
                  onFieldFocus={(field) => {
                    setFocusedField(field);
                  }}
                  onInsertVariableToField={handleInsertVariableToField}
                />
              </TabsContent>
              <TabsContent value={SelectedCommunicationMessageTab.COMPLETED}>
                <MessageField
                  messageFieldName="afterInterview"
                  subjectFieldName="afterInterviewSubject"
                  focusedField={focusedField}
                  onEditorFocus={() => {
                    if (focusedField) {
                      setFocusedField(null);
                    }
                  }}
                  onFieldFocus={(field) => {
                    setFocusedField(field);
                  }}
                  onInsertVariableToField={handleInsertVariableToField}
                />
              </TabsContent>
              <TabsContent
                value={SelectedCommunicationMessageTab.DISQUALIFIED}
                className={
                  isDisqualifiedCommunicationDisabled
                    ? 'pointer-events-none'
                    : undefined
                }
              >
                <MessageField
                  messageFieldName="disqualified"
                  subjectFieldName="disqualifiedSubject"
                  focusedField={focusedField}
                  onEditorFocus={() => {
                    if (focusedField) {
                      setFocusedField(null);
                    }
                  }}
                  onFieldFocus={(field) => {
                    setFocusedField(field);
                  }}
                  onInsertVariableToField={handleInsertVariableToField}
                />
              </TabsContent>
            </Tabs>

            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                // Prevent form submission
                e.preventDefault();
                setPreviewModalOpen(true);
              }}
              className="flex items-center gap-1 absolute top-0 right-0"
              type="button" // Explicitly set type to button
            >
              <Eye className="h-4 w-4" /> Preview
            </Button>
          </div>
        </div>
      </div>

    </>
  );
}
