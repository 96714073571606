import { Markdown } from '@/components/core/common/Markdown';
import { Loader } from '@/components/core/loader';

import {
  ApplicationDetail,
  useGetApplication,
} from '@/fetchers/useApplication';
import {
  cn,
  getContrastColorFromBackgroundColor,
  parseSalaryToDisplay,
} from '@/lib/utils';
import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { useEffect, useRef } from 'react';
import generatePDF from 'react-to-pdf';
import logo from '@/assets/svg/logo-whitebg.svg';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useGetOrganizations } from '@/fetchers/useOrganization';
import { Route as RootRoute } from './__root';
import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/applications/constants';

export const Route = createRoute({
  path: '/o/$organizationId/positions/print/$slug/$step/$id',
  getParentRoute: () => RootRoute,
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CandidateInfo,
});

export function CandidateInfo() {
  const { id } = Route.useParams();
  const { selectedOrganization } = useUserDetailStore();

  const { data: application, isFetching } = useGetApplication({
    applicationId: Number(id),
  });
  const interview = application?.interviews?.[0];

  const { isFetching: isFetcthingOrganizations } = useGetOrganizations({
    enabled: true,
  });
  const reportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFetching || isFetcthingOrganizations) return;
    if (!selectedOrganization) return;
    if (!selectedOrganization?.color) return;
    if (!selectedOrganization?.logoUrl) return;
    if (!selectedOrganization?.name) return;
    if (!application) return;

    generatePDF(reportRef, {
      filename: `${application.name} Report - ${application.position.title}`,
      page: {
        format: 'a4',
        orientation: 'portrait',
      },
    }).then(() => {
      window.close();
    });
  }, [isFetching, isFetcthingOrganizations, selectedOrganization, application]);

  if (isFetching || isFetcthingOrganizations) {
    return <Loader />;
  }

  const title = `${application?.position.title} position at ${selectedOrganization?.name}`;
  return (
    <>
      <div
        ref={reportRef}
        style={{
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        <Header
          bgColor={selectedOrganization?.color || '#70e2ff'}
          logo={selectedOrganization?.logoUrl || ''}
          title={title}
        />

        <div className="h-full p-8">
          <div className="rounded-lg p-8 px-[170px]">
            <div className="mb-6 flex items-center justify-between">
              <div className="flex flex-col xl:flex-row xl:items-center">
                <p className="text-3xl font-bold">{application?.name}</p>
              </div>
            </div>

            <InformationTable application={application} />
          </div>

          {application?.resumeAnalysis && (
            <div className="rounded-lg p-8 px-[170px]">
              <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-bold">Resume Analysis</h2>
                <Badge className="uppercase">
                  {resumeVerdictValues[application?.resumeAnalysisVerdict]}
                </Badge>
              </div>
              <p>{application?.resumeAnalysis}</p>
            </div>
          )}

          {interview?.analysisSummary && (
            <div className="rounded-lg p-8 px-[170px]">
              <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-bold">Interview Summary</h2>

                {interview?.finalVerdict && (
                  <Badge className="uppercase">
                    {interviewVerdictValues[interview?.finalVerdict]}
                  </Badge>
                )}
              </div>
              <p>{interview?.analysisSummary}</p>
            </div>
          )}

          {interview?.analysis && (
            <div
              className="flex h-full flex-col rounded-lg p-8 px-[170px]"
              style={{
                height: 'fit-content',
              }}
            >
              <h2 className="mb-4 text-2xl font-bold">Interview Analysis</h2>
              <Markdown content={interview?.analysis} className="max-w-full" />
            </div>
          )}

          {interview?.additionalQuestionsAndAnswers && (
            <div className="rounded-lg p-8 px-[170px]">
              <div>
                {interview?.additionalQuestionsAndAnswers.map(
                  (question, idx) => (
                    <div key={idx} className="mb-4">
                      <h3 className="text-lg font-bold">{question.question}</h3>
                      <p>{question.answer}</p>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          <div className="z-10 mb-4 mt-6 block w-full">
            <a
              href="https://veton.ai"
              target="_blank"
              className="sticky bottom-0 left-0 flex h-[60px] w-full items-center justify-center border-t border-gray-100 p-4 text-lg"
            >
              Powered by{' '}
              <img
                alt="powered by veton.ai"
                src={logo}
                style={{ height: 25 }}
                className="ml-1 mt-4"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

function Badge({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'relative flex h-[26px] items-center justify-center rounded-full border border-black px-2',
        className
      )}
    >
      <div className="pb-4">{children}</div>
    </div>
  );
}

function InformationTable({
  application,
}: {
  application?: ApplicationDetail;
}) {
  if (!application) return null;
  return (
    <div className="mt-8 w-full space-y-8 break-all">
      {application?.email && (
        <div className="flex flex-col">
          <p>
            <span className="mr-4 font-semibold">Email</span>
          </p>
          <p>{handleUndefinedValue(application?.email)}</p>
        </div>
      )}

      {application?.phone && (
        <div className="flex flex-col">
          <p>
            <span className="mr-4 font-semibold">Phone</span>
          </p>
          <p>{handleUndefinedValue(application?.phone)}</p>
        </div>
      )}

      {application?.linkedinProfile && (
        <div className="flex flex-col">
          <p>
            <span className="mr-4 font-semibold">Linkedin Profile</span>
          </p>
          <p>{handleUndefinedValue(application?.linkedinProfile)}</p>
        </div>
      )}

      {application?.additionalInfo && (
        <div className="flex flex-col">
          <p>
            <span className="mr-4 font-semibold">Additional Information</span>
          </p>
          <p
            style={{
              wordBreak: 'break-word',
            }}
          >
            {handleUndefinedValue(application?.additionalInfo)}
          </p>
        </div>
      )}

      {application?.desiredSalary && application?.salaryCurrency && (
        <div className="flex flex-col">
          <p>
            <span className="mr-4 font-semibold">Desired Salary</span>
          </p>
          {application?.desiredSalary === 'undefined' ? (
            <p>Blank</p>
          ) : (
            <p>
              {parseSalaryToDisplay(
                Number(application?.desiredSalary),
                application?.salaryCurrency
              )}{' '}
              / {application?.salaryType}
            </p>
          )}
        </div>
      )}

      {application?.education && (
        <div className="flex flex-col">
          <p>
            <span className="mr-4 font-semibold">Education</span>
          </p>
          <p>{handleUndefinedValue(application?.education)}</p>
        </div>
      )}
    </div>
  );
}

function handleUndefinedValue(value: string) {
  if (value === 'undefined') {
    return '-';
  } else {
    return value;
  }
}

function Header({
  logo,
  title,
  bgColor = '#70e2ff',
}: {
  bgColor: string;
  logo: string;
  title: string;
}) {
  const color = getContrastColorFromBackgroundColor(bgColor);
  return (
    <div
      className="w-full py-4 pl-8"
      style={{
        background: bgColor,
        color: color,
      }}
    >
      <div className="flex">
        <div className="overflow-hidden rounded-xl">
          <img
            src={logo}
            alt="Company Logo"
            style={{
              height: '80px',
              aspectRatio: 1,
            }}
          />
        </div>

        <p className="ml-4 mt-4 text-2xl font-bold">{title}</p>
      </div>
    </div>
  );
}
