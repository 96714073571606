export enum PipelineFilterCategory {
  INTERVIEW_ANALYSIS = 'Interview analysis',
  RESUME_ANALYSIS = 'Resume analysis',
  PHONE_NUMBER_STATUS = 'Phone number',
  SMS_DELIVERABILITY = 'SMS deliverability',
}

export type PipelineFilterOption = {
  label: string;
  isSelected: boolean;
  category?: PipelineFilterCategory;
};

export type PipelineFilter = {
  category?: PipelineFilterCategory;
  options: PipelineFilterOption[];
};

export enum PhoneNumberStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
}

export enum SmsDeliverability {
  MOBILE_PHONE = 'Mobile phone',
  NON_MOBILE_PHONE = 'Non-mobile phone',
}

export enum PipelineStage {
  APPLIED = 'applied',
  INTERVIEW_SENT = 'interview_sent',
  INTERVIEW_COMPLETED = 'interview_completed',
  SHORTLISTED = 'shortlisted',
  DISQUALIFIED = 'disqualified',
}

export enum FinalVerdict {
  NOT_FIT = 'not_fit',
  MAYBE_FIT = 'maybe_fit',
  GOOD_FIT = 'good_fit',
  DISQUALIFIED = 'disqualified',
}

export enum ResumeAnalysisVerdict {
  INTERVIEW_RECOMMENDED = 'interview_recommended',
  INTERVIEW_POSSIBLY_RECOMMENDED = 'interview_possibly_recommended',
  INTERVIEW_NOT_RECOMMENDED = 'interview_not_recommended',
  RESUME_NOT_PROVIDED = 'resume_not_provided',
}

// NOT_LIKELY = "not_likely", "Not Likely"
// LIKELY = "likely", "Likely"
// HIGHLY_LIKELY = "highly_likely", "Highly Likely"
export enum CheatDetectionVerdict {
  NOT_LIKELY = 'not_likely',
  LIKELY = 'likely',
  HIGHLY_LIKELY = 'highly_likely',
}

export type PipelineBulkAction = {
  title: string;
  onClick: () => void;
  className?: string;
};

type ResumeAnalysisContent = {
  title: string;
  description: string;
};

type InterviewFocusArea = {
  title: string;
  description: string;
  suggestedQuestions: string[];
  reasoning: string;
};

export type TResumeAnalysis = {
  overallFit: ResumeAnalysisVerdict;
  skillsAnalysis: {
    skill: string;
    score: number;
  }[];
  workStyleAnalysis: ResumeAnalysisContent[];
  aiInsights: ResumeAnalysisContent[];
  interviewFocusAreas: InterviewFocusArea[];
};
