import { useState } from 'react';
import { Star } from 'lucide-react';
import { cn } from '@/lib/utils';

type StarRatingProps = {
  rating: number;
  onChange: (rating: number) => void;
  className?: string;
  lowestLabel?: string;
  highestLabel?: string;
};

function StarRating({
  rating,
  onChange,
  className,
  lowestLabel = 'Very bad',
  highestLabel = 'Very good',
}: StarRatingProps) {
  const [hoverRating, setHoverRating] = useState(0);

  const handleStarClick = (newRating: number) => {
    onChange(newRating);
  };

  const handleStarHover = (newRating: number) => {
    setHoverRating(newRating);
  };

  const handleStarLeave = () => {
    setHoverRating(0);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const isFilled = i <= (hoverRating || rating);
      stars.push(
        <Star
          key={i}
          size={34}
          className={`cursor-pointer transition-colors duration-200 ${isFilled ? 'fill-yellow-400 text-yellow-400' : 'text-muted-foreground'}`} // Apply fill color for filled stars
          onClick={() => handleStarClick(i)}
          onMouseEnter={() => handleStarHover(i)}
          onMouseLeave={handleStarLeave}
        />
      );
    }
    return stars;
  };

  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <div className="flex items-center gap-6">{renderStars()}</div>
      <div className="flex justify-between">
        <span className="text-sm text-muted-foreground">{lowestLabel}</span>
        <span className="text-sm text-muted-foreground">{highestLabel}</span>
      </div>
    </div>
  );
}

export default StarRating;
