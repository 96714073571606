import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from 'sonner';
import { useIsMutating } from '@tanstack/react-query';
import { TApplicationForm } from '@/fetchers/usePosition';
import { useUpdateNotificationSettingsMutation } from '@/fetchers/useUserDetails';
import StickyActionBar from '../sticky-action-bar';


const notificationFormSchema = z.object({
  applyingCandidates: z.enum(['none', 'all']),
  resumeAnalysisResults: z.enum(['none', 'good_fits_only', 'all']),
  interviewStarted: z.enum(['none', 'good_fits_only', 'all']),
  interviewAnalysisResults: z.enum(['none', 'good_fits_only', 'all']),
});

export type DisplayFormValues = z.infer<typeof notificationFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<DisplayFormValues> = {
  applyingCandidates: 'none',
  resumeAnalysisResults: 'none',
  interviewStarted: 'none',
  interviewAnalysisResults: 'none',
};

export function NotificationForm({
  notificationForm,
}: {
  notificationForm?: TApplicationForm;
}) {
  const update = useUpdateNotificationSettingsMutation();
  const isMutating = useIsMutating();

  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(notificationFormSchema),
    defaultValues: {
      ...defaultValues,
      // Use mock data for development
      ...notificationForm,
      // In production, use the actual data
      // ...notificationForm,
    },
  });

  console.log("#@#!#!", form.formState.isDirty)

  async function onSubmit(data: DisplayFormValues) {
    const { error } = await update({ data });
    if (error) {
      toast.error('Error updating settings');
      return;
    }
    toast.success('Settings updated successfully');
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 pb-20">
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="applyingCandidates"
            render={({ field }) => (
              <FormItem>
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Applying Candidates</FormLabel>
                  <FormControl>
                    <Tabs
                      defaultValue={field.value}
                      className=""
                      onValueChange={field.onChange}
                    >
                      <TabsList>
                        <TabsTrigger value="none">None</TabsTrigger>
                        <TabsTrigger value="all">All</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="resumeAnalysisResults"
            render={({ field }) => (
              <FormItem>
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Resume Analysis Results</FormLabel>
                  <FormControl>
                    <Tabs
                      defaultValue={field.value}
                      className=""
                      onValueChange={field.onChange}
                    >
                      <TabsList>
                        <TabsTrigger value="none">None</TabsTrigger>
                        <TabsTrigger value="good_fits_only">
                          Good Fits Only
                        </TabsTrigger>
                        <TabsTrigger value="all">All</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="interviewStarted"
            render={({ field }) => (
              <FormItem>
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Interview Started</FormLabel>
                  <FormControl>
                    <Tabs
                      defaultValue={field.value}
                      className=""
                      onValueChange={field.onChange}
                    >
                      <TabsList>
                        <TabsTrigger value="none">None</TabsTrigger>
                        <TabsTrigger value="good_fits_only">
                          Good Fits Only
                        </TabsTrigger>
                        <TabsTrigger value="all">All</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="interviewAnalysisResults"
            render={({ field }) => (
              <FormItem>
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Interview Completed</FormLabel>
                  <FormControl>
                    <Tabs
                      defaultValue={field.value}
                      className=""
                      onValueChange={field.onChange}
                    >
                      <TabsList>
                        <TabsTrigger value="none">None</TabsTrigger>
                        <TabsTrigger value="good_fits_only">
                          Good Fits Only
                        </TabsTrigger>
                        <TabsTrigger value="all">All</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <StickyActionBar
          isSaveDisabled={!form.formState.isDirty}
          onSave={form.handleSubmit(onSubmit)}
          isSaveLoading={isMutating > 0}
        />
      </form>
    </Form>
  );
}
