import { useUserDetailStore } from '@/fetchers/useUserDetails';
import vetonLogo from '@/assets/svg/logo-whitebg.svg';
import { Separator } from '@/components/ui/separator';
import { useTranslation } from 'react-i18next';
import { TPosition } from '@/fetchers/usePosition';
import { InterviewType, SelectedCommunicationMessageTab } from './types';

const EmailPreview = ({
  message,
  subject,
  position,
  interviewType,
  aiName,
  selectedCommunicationMessageTab,
}: {
  message: string;
  subject: string;
  position?: TPosition;
  interviewType: InterviewType;
  aiName: string;
  selectedCommunicationMessageTab: SelectedCommunicationMessageTab;
}) => {
  const { selectedOrganization } = useUserDetailStore();
  const { t } = useTranslation('translation', {
    keyPrefix: 'email',
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <span className="text-sm">
          <span className="font-semibold">Subject:</span> {subject}
        </span>
      </div>
      <div className="overflow-hidden border">
        <div className="flex items-center bg-gray-100 p-4">
          <img
            src={selectedOrganization?.logoUrl}
            className="h-[35px] w-[35px]"
          />
          <div className="ml-2 flex flex-col">
            <p className="text-base font-bold tracking-tight">
              {selectedOrganization?.name}
            </p>
            <span className="text-xs tracking-wider text-muted-foreground underline">
              {t('careers_page')}
            </span>
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-4 p-2">
          <div
            className="w-full text-sm"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />

          {selectedCommunicationMessageTab ===
            SelectedCommunicationMessageTab.INVITATION && (
            <>
              <Separator />
              <span className="flex self-start rounded-lg bg-black px-4 py-2 leading-5 text-white no-underline">
                {t('start_interview')}
              </span>
              <div className="pb-4">
                <h3 className="font-bold">{t('interview_details')}</h3>
                <p className="text-sm">
                  {t('role')}: {position?.title}
                </p>
                <p className="text-sm">
                  {t('interviewer')}: {aiName}
                </p>
                <p className="text-sm">
                  {t('interview_method')}: {t(`${interviewType}`)}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="mt-4 flex flex-col items-center gap-4 bg-gray-100 py-6">
          <div className="flex items-center gap-2">
            Powered by
            <img
              src={vetonLogo}
              alt="Veton.ai logo"
              className="h-auto w-[70px]"
            />
          </div>
          <span className="text-xs text-blue-500 underline">
            {t('unsubscribe')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailPreview;
