import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps } from 'react-hook-form';
import { TAIConfigForm } from '@/fetchers/usePosition';

const skillWeightSchema = z.object({
  name: z.string().min(1, 'Skill name is required'),
  weight: z.enum(['nice-to-have', 'important', 'very-important', 'critical']),
});

const positionAiConfigFormSchema = z.object({
  aiVoiceModelId: z.number().default(0),
  voiceModel: z.enum(['shimmer', 'echo', 'nova', 'onyx']).default('echo'),
  additionalQuestions: z
    .array(
      z.object({
        value: z.string(),
      })
    )
    .optional(),
  skillWeights: z.array(skillWeightSchema).optional(),
  restrictQuestions: z
    .enum(['enabled', 'disabled'])
    .default('disabled')
    .optional(),
  interviewTone: z
    .enum(['technical', 'nontechnical', 'balanced'])
    .default('balanced')
    .optional(),
  experienceLevelToggle: z
    .enum(['enabled', 'disabled'])
    .default('disabled')
    .optional(),
  candidateExperienceLevel: z
    .enum(['entry', 'mid', 'senior', 'leadership'])
    .optional(),
  evaluationCriteria: z.string().optional(),
  isInitialized: z.boolean().default(false).optional(),
});

export type PositionAIConfigFormValues = z.infer<
  typeof positionAiConfigFormSchema
>;

export const positionAiConfigFormDefaultValues: Partial<PositionAIConfigFormValues> =
  {
    voiceModel: 'echo',
    additionalQuestions: [{ value: '' }],
    skillWeights: [],
    restrictQuestions: 'disabled',
    interviewTone: 'balanced',
    experienceLevelToggle: 'disabled',
    evaluationCriteria: '',
  };

export const handleMapAIConfigDataToFormValues = (
  data: TAIConfigForm
): PositionAIConfigFormValues => {
  const remoteQuestions =
    data?.additionalQuestions?.map((value) => ({ value })) || [];
  const additionalQuestions =
    remoteQuestions?.length > 0
      ? remoteQuestions
      : positionAiConfigFormDefaultValues.additionalQuestions;

  return {
    aiVoiceModelId: data?.aiVoiceModelId,
    voiceModel:
      data?.voiceModel || positionAiConfigFormDefaultValues.voiceModel,
    additionalQuestions: additionalQuestions,
    restrictQuestions: data?.restrictQuestions ? 'enabled' : 'disabled',
    evaluationCriteria:
      data?.evaluationCriteria ||
      positionAiConfigFormDefaultValues.evaluationCriteria,
  };
};

export const usePositionAiConfigForm = (
  options?: UseFormProps<PositionAIConfigFormValues>
) => {
  return useForm<PositionAIConfigFormValues>({
    resolver: zodResolver(positionAiConfigFormSchema),
    mode: 'onChange',
    ...options,
  });
};
