import { Button } from '@/components/ui/button';

import { useParams } from '@tanstack/react-router';
import {
  ATSConnectionAuthMethod,
  ATSConnectionId,
} from '@/components/core/ats/types';

export function JobAdderConnection() {
  const { organizationId } = useParams({ strict: false });

  return (
    <>
      <div className="self-end">
        <Button
          onClick={() => {
            const redirectUri = `${window.location.origin}/organizations`;

            const state = encodeURIComponent(
              JSON.stringify({
                organizationId,
                integrationType: ATSConnectionId.JOBADDER,
                authMethod: ATSConnectionAuthMethod.OAUTH2,
                redirectUri,
              })
            );



            const url = `https://id.jobadder.com/connect/authorize?client_id=2o6f2btbkstu3io75d5ismjmqu&scope=read+write+offline_access&write&redirect_uri=${redirectUri}&response_type=code&state=${state}`

            window.open(url, '_blank');
          }}
          className="w-full"
        >
          Connect
        </Button>
      </div>
    </>
  );
}
