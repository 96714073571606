import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import { Check, AlertCircle, Lightbulb, User, BookOpen } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { PolarRadiusAxis, Radar } from 'recharts';
import { PolarAngleAxis } from 'recharts';
import { PolarGrid } from 'recharts';
import { RadarChart } from 'recharts';
import { TResumeAnalysis } from './types';
import { resumeVerdictValues } from './constants';
import { BetaBadge } from '@/components/ui/beta-badge';

const DetailedResumeAnalysis = ({ data }: { data: TResumeAnalysis }) => {
  // Create default values array with all accordion items open
  const defaultOpenAccordionValues = data.interviewFocusAreas.map(
    (_, index) => `area-${index}`
  );

  return (
    <div className="flex flex-col space-y-6 p-4">
      <div className='flex justify-between'>
        <BetaBadge className='' />
        <Badge className="text-md self-end">{resumeVerdictValues?.[data.overallFit]}</Badge>
      </div>
      <RadarChart
        className="mx-auto"
        width={600}
        height={300}
        data={data.skillsAnalysis}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="skill" />
        <PolarRadiusAxis angle={30} domain={[0, 100]} />
        <Radar
          name="Score"
          dataKey="score"
          stroke="#2563eb"
          fill="#2563eb"
          fillOpacity={0.6}
        />
      </RadarChart>

      <div className="space-y-6">
        {/* Work Style Analysis Section */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <User className="h-5 w-5" />
              Work Style Analysis
            </CardTitle>
            <CardDescription>
              Key work style characteristics based on resume analysis
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {data.workStyleAnalysis.map((item, index) => (
                <Card key={index} className="border-l-4 border-blue-500">
                  <CardHeader className="pb-2">
                    <CardTitle className="text-lg">{item.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-sm text-gray-600">
                      {item.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* AI Insights Section */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Lightbulb className="h-5 w-5" />
              AI Insights
            </CardTitle>
            <CardDescription>
              Intelligent observations based on resume analysis
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {data.aiInsights.map((insight, index) => (
                <div key={index} className="flex gap-3">
                  <div className="mt-0.5">
                    <Check className="h-5 w-5 text-green-500" />
                  </div>
                  <div>
                    <h4 className="text-md font-semibold">{insight.title}</h4>
                    <p className="text-sm text-gray-600">
                      {insight.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Interview Focus Areas Section */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <BookOpen className="h-5 w-5" />
              Interview Focus Areas
            </CardTitle>
            <CardDescription>
              Recommended areas to explore during the interview
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Accordion
              type="multiple"
              className="space-y-2"
              defaultValue={defaultOpenAccordionValues}
            >
              {data.interviewFocusAreas.map((area, index) => (
                <AccordionItem
                  key={index}
                  value={`area-${index}`}
                  className="rounded-lg border p-2"
                >
                  <AccordionTrigger className="hover:no-underline">
                    <div className="flex items-center gap-2">
                      <AlertCircle className="h-4 w-4 text-amber-500" />
                      <span>{area.title}</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-2 pt-3">
                    <p className="mb-3 text-sm text-gray-600">
                      {area.description}
                    </p>
                    <div className="mb-3">
                      <h4 className="mb-2 text-sm font-medium">
                        Suggested Questions:
                      </h4>
                      <ul className="list-disc space-y-1 pl-5">
                        {area.suggestedQuestions.map((question, qIndex) => (
                          <li key={qIndex} className="text-sm">
                            {question}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="rounded-md bg-gray-50 p-3">
                      <h4 className="mb-1 text-sm font-medium">Reasoning:</h4>
                      <p className="text-xs text-gray-600">
                        {area.reasoning}
                      </p>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DetailedResumeAnalysis;
