import { Loader } from '@/components/core/loader';
import { Link, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import {
  CalendarCheck,
  Edit,
  FileText,
  ListChecks,
  Send,
  UploadIcon,
  UserX,
} from 'lucide-react';
import {
  useBulkShortlistMutation,
  useBulkUploadApplicants,
  useBulkUploadResume,
  useSendInterviewMutation,
} from '@/fetchers/useApplication';
import { useQueryClient } from '@tanstack/react-query';
import { Button, buttonVariants } from '@/components/ui/button';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useApplicants } from '@/lib/useApplicants';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { TPosition, useGetPositionTimeSaved } from '@/fetchers/usePosition';
import { NewApplicantDialog } from './new-applicant-dialog';
import { useExport } from '@/lib/useExport';

import { format, parseISO } from 'date-fns';
import { ImportApplicantDialog } from './ImportApplicantDialog';
import { SearchInput } from '../search-input';
import { Breadcrumb } from '../breadcrumb';
import Pipeline from './Pipeline';
import { Alert } from '../alert';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { getIsPositionDraft } from '@/lib/getIsPositionDraft';
import { RouterParams } from '@/main';
import { getIsPositionClosed } from '@/lib/getIsPositionClosed';
import usePositionStatusUpdateModal from '@/hooks/usePositionStatusUpdateModal';
import { Badge } from '@/components/ui/badge';
import TimeSavedBreakdown from '../time-saved-breakdown';
import { useParsedTimeSaved } from '@/lib/useParsedTimeSaved';
import { FinalVerdict, PipelineStage } from './types';
import {
  disqualifiedFilters,
  interviewFilters,
  interviewVerdictValues,
  phoneNumberStatusFilters,
  resumeFilters,
  resumeVerdictValues,
  smsDeliverabilityFilters,
} from './constants';
import SelectedFilters from './SelectedFilters';
import {
  restrictToFirstScrollableAncestor,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import useDisqualifyCandidateModal from '@/hooks/useDisqualifyCandidateModal';

type ApplicationsProps = {
  position: TPosition;
};

const initialBulkSelectionActive: Record<PipelineStage, boolean> = {
  [PipelineStage.APPLIED]: false,
  [PipelineStage.INTERVIEW_SENT]: false,
  [PipelineStage.INTERVIEW_COMPLETED]: false,
  [PipelineStage.DISQUALIFIED]: false,
  [PipelineStage.SHORTLISTED]: false,
};

export function Applications({ position }: ApplicationsProps) {
  const isPositionOpen = getIsPositionOpen(position);
  const isPositionDraft = getIsPositionDraft(position);
  const isPositionClosed = getIsPositionClosed(position);
  const { organizationId, slug } = useParams({
    strict: false,
  }) as RouterParams;
  const [searchQuery, setSearchQuery] = useState('');
  const [uploading, setUploading] = useState(false);
  const [bulkSelectionActiveByStage, setBulkSelectionActiveByStage] = useState(
    initialBulkSelectionActive
  );
  const queryClient = useQueryClient();
  const { PositionStatusUpdateModal, setIsPositionStatusUpdateModalOpen } =
    usePositionStatusUpdateModal(position);
  const { handleOpenDisqualifyCandidateModal, DisqualifyCandidateModal } =
    useDisqualifyCandidateModal();
  const bulkShortlistMutation = useBulkShortlistMutation();
  const upload = useBulkUploadApplicants({
    slug,
  });
  const { data: timeSaved } = useGetPositionTimeSaved({
    positionId: position.id,
    organizationId,
  });
  const parsedTimeSaved = useParsedTimeSaved(timeSaved);

  const uploadResumes = useBulkUploadResume();

  const { selectedOrganization } = useUserDetailStore();
  const sendInterviewMutation = useSendInterviewMutation();

  const { exportToExcel } = useExport();

  const { applicants, isFetching } = useApplicants({
    searchQuery,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 0,
        distance: 3,
        tolerance: 10,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        distance: 3,
        tolerance: 10,
      },
    }),

    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        distance: 3,
        tolerance: 10,
      },
    })
  );

  const appliedCandidates = applicants?.filter(
    (applicant) => !applicant.shortlisted && !applicant.interview
  );

  const interviewSentCandidates = applicants?.filter(
    (applicant) =>
      !applicant.shortlisted &&
      applicant.interview &&
      !applicant.interview.completedAt &&
      applicant.interview?.finalVerdict !== FinalVerdict.DISQUALIFIED
  );

  const interviewCompletedCandidates = applicants?.filter(
    (applicant) =>
      !applicant.shortlisted &&
      applicant.interview?.completedAt &&
      applicant.interview?.finalVerdict !== FinalVerdict.DISQUALIFIED
  );

  const shortlistedCandidates = applicants?.filter(
    (applicant) => applicant.shortlisted
  );

  const disqualifiedCandidates = applicants?.filter(
    (applicant) =>
      applicant.interview?.finalVerdict === FinalVerdict.DISQUALIFIED
  );

  function handleDragEnd(event: DragEndEvent) {
    if (!event.over?.id) return;

    if (
      event.over.id === PipelineStage.INTERVIEW_SENT &&
      event.active.data.current?.stage !== PipelineStage.APPLIED
    ) {
      return;
    }

    if (
      event.active.data.current?.stage === PipelineStage.SHORTLISTED &&
      event.over.id !== PipelineStage.DISQUALIFIED
    ) {
      return;
    }

    switch (event.over.id) {
      case PipelineStage.INTERVIEW_SENT:
        sendInterviewMutation({
          applicationId: Number(event.active.id),
        });
        break;
      case PipelineStage.SHORTLISTED:
        bulkShortlistMutation({
          applicationIds: [Number(event.active.id)],
          shortlisted: true,
        });
        break;
      case PipelineStage.DISQUALIFIED:
        {
          handleOpenDisqualifyCandidateModal(Number(event.active.id));
        }
        break;
      default:
        break;
    }
  }

  function handleExportClicked() {
    let excelData = applicants.map((applicant) => {
      const application = applicant?.application;
      const interviews = applicant?.application?.interviews;
      return {
        'Application ID': `${application?.id}`,

        Email: application?.email,
        Name: application?.name,
        Phone: application?.phone,
        'Applied Date': application?.createdAt &&
          format(parseISO(application?.createdAt), 'yyyy-MM-dd HH:mm'),

        interviews: interviews?.map((interview) => ({
          id: `${interview?.id}`,
          finalVerdict: interview?.finalVerdict,
          createdAt: interview?.createdAt &&
            format(parseISO(interview?.createdAt), 'yyyy-MM-dd HH:mm'),
          lastReminderSentAt: interview?.lastReminderSentAt &&
            format(parseISO(interview?.lastReminderSentAt), 'yyyy-MM-dd HH:mm'),
          completedAt:
            interview?.completedAt &&
            format(parseISO(interview?.completedAt), 'yyyy-MM-dd HH:mm'),
          startedAt:
            interview?.startedAt &&
            format(parseISO(interview?.startedAt), 'yyyy-MM-dd HH:mm'),
          analysis: interview?.analysisSummary,
          type: interview?.assistantType
            ?.replace?.('mock-', '')
            ?.split(' ')
            ?.map(
              (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
            )
            ?.join(' '),
        })),
        'Resume Analysis Verdict':
          resumeVerdictValues[application?.resumeAnalysisVerdict],
        'Resume Analysis': application?.resumeAnalysis,
        'Candidate Details URL': `${window.location.origin}/organizations/${organizationId}/positions/manage/${slug}/applications/${application?.id}`,
        Archived: application?.archived ? 'Yes' : 'No',
      };
    });

    excelData.forEach((applicant, index) => {
      // Get the first interview data for date columns
      const firstInterview = applicant.interviews?.[0];
      // @ts-ignore
      excelData[index]['Interview Sent Date'] = firstInterview?.createdAt || '';
      // @ts-ignore
      excelData[index]['Last Reminder Sent Date'] = firstInterview?.lastReminderSentAt || '';
      // @ts-ignore
      excelData[index]['Interview Completed Date'] = firstInterview?.completedAt || '';

      applicant.interviews?.forEach((interview) => {
        excelData[index] = {
          ...excelData[index],
          [`${interview?.type} ID`]: `${interview?.id}`,
          [`${interview?.type} Completed At`]: interview?.completedAt,
          [`${interview?.type} Verdict`]: interview?.finalVerdict
            ? interviewVerdictValues[interview?.finalVerdict]
            : '',
          [`${interview?.type} Started At`]: interview?.startedAt,
          [`${interview?.type} Analysis`]: interview?.analysis,
        };
      });
    });

    excelData = excelData.map((applicant) => {
      const newApplicant = { ...applicant };
      // @ts-ignore
      delete newApplicant.interviews;
      return newApplicant;
    });

    const fileName = `${position.title} ${selectedOrganization?.name ? 'at ' + selectedOrganization?.name : ''}`;
    exportToExcel(excelData, fileName);
  }

  const exportButton = (
    <Button
      variant={'outline'}
      onClick={handleExportClicked}
      disabled={!isPositionOpen}
    >
      <UploadIcon className="mr-1 size-4" />
      Export
    </Button>
  );

  const exportButtonTooltipText = `${isPositionDraft ? 'Publish' : 'Reopen'} the position to export applicants`;

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      {PositionStatusUpdateModal}
      {DisqualifyCandidateModal}
      <div className="flex h-full flex-col">
        <Breadcrumb
          items={[
            {
              title: position.title ?? '',
              href: `/organizations/${organizationId}/positions/manage/${slug}/${selectedOrganization?.integrationName ? 'communication' : 'details'}`,
            },
            {
              title: 'Applications',
            },
          ]}
        />
        {isPositionDraft && (
          <Alert
            variant="outline"
            severity="info"
            description={
              <>
                This position is currently in <strong>Draft</strong> status.
                Once everything is ready,{' '}
                <Link
                  to="/organizations/$organizationId/positions/manage/$slug/$step"
                  params={{
                    organizationId,
                    slug,
                    step: 'preview',
                  }}
                  className="font-medium underline"
                >
                  publish
                </Link>{' '}
                the position and start interviewing applicants with Veton AI.
              </>
            }
            className="mb-4"
          />
        )}
        {isPositionClosed && (
          <Alert
            variant="outline"
            severity="info"
            description={
              <>
                This position is currently in <strong>Closed</strong> status and
                it is not visible on the careers page.{' '}
                <a
                  role="button"
                  className="cursor-pointer font-medium underline"
                  onClick={() => setIsPositionStatusUpdateModalOpen(true)}
                >
                  Reopen
                </a>{' '}
                the position to continue interviewing applicants.
              </>
            }
            className="mb-4"
          />
        )}
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-4 md:gap-0">
            <div className="flex flex-col items-start gap-2 lg:flex-row lg:items-center">
              <h1 className="line-clamp-2 font-bold md:text-xl">{`${position.title} Position ${selectedOrganization?.name ? 'at ' + selectedOrganization?.name : ''}`}</h1>
              {parsedTimeSaved?.totalDuration && (
                <Tooltip>
                  <TooltipTrigger>
                    <Badge
                      variant="secondary"
                      className="animate-pulse rounded-full border-primary bg-primary-light text-primary"
                      style={{
                        animationIterationCount: 1,
                        animationDuration: '0.5s',
                        animationTimingFunction: 'ease-in',
                      }}
                    >
                      <span className="text-sm">
                        {parsedTimeSaved?.totalDuration} saved
                      </span>
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent className="p-4">
                    <TimeSavedBreakdown
                      title="Time saved for this position"
                      timeSaved={timeSaved}
                    />
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
            <Link
              to={`/organizations/$organizationId/positions/manage/$slug/$step`}
              params={{
                organizationId,
                slug,
                step: selectedOrganization?.integrationName
                  ? 'communication'
                  : 'details',
              }}
              className={buttonVariants({ variant: 'outline' })}
            >
              <Edit className="mr-2 size-4" />
              Edit Position
            </Link>
          </div>

          <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-0">
            <div className="flex gap-2">
              {!selectedOrganization?.integrationName && (
                <NewApplicantDialog position={position} />
              )}

              {!selectedOrganization?.integrationName && (
                <div>
                  {!uploading ? (
                    <ImportApplicantDialog
                      onSelectFile={async (files, spreadsheet) => {
                        if (spreadsheet) {
                          await upload({
                            // @ts-ignore
                            file: files,
                            slug,
                            organizationId,
                          });
                        } else {
                          await uploadResumes({
                            // @ts-ignore
                            files,
                            slug,
                            organizationId,
                          });
                        }
                      }}
                      onUploadCompleted={() => {
                        setUploading(false);
                        queryClient.invalidateQueries({
                          queryKey: ['applications', 'admin', slug],
                        });
                      }}
                      position={position}
                    />
                  ) : (
                    <Button isLoading disabled variant={'outline'}>
                      Uploading
                    </Button>
                  )}
                </div>
              )}

              <Tooltip>
                {!isPositionOpen ? (
                  <TooltipTrigger asChild>{exportButton}</TooltipTrigger>
                ) : (
                  exportButton
                )}
                <TooltipContent>{exportButtonTooltipText}</TooltipContent>
              </Tooltip>
            </div>
            <SearchInput value={searchQuery} onChange={setSearchQuery} />
          </div>
          <SelectedFilters />
        </div>
        <div className="-ml-4 -mr-4 mt-4 flex h-full flex-col overflow-x-scroll p-0.5 pl-4 pr-4">
          <div className="grid h-full min-w-[1250px] grid-cols-5 gap-4 md:min-w-[1750px]">
            <DndContext
              onDragEnd={handleDragEnd}
              sensors={sensors}
              modifiers={[
                restrictToFirstScrollableAncestor,
                restrictToWindowEdges,
              ]}
            >
              <Pipeline
                stage={PipelineStage.APPLIED}
                Icon={FileText}
                items={[...appliedCandidates].sort(
                  (a, b) =>
                    new Date(b.application.createdAt).getTime() -
                    new Date(a.application.createdAt).getTime()
                )}
                filters={[
                  resumeFilters,
                  phoneNumberStatusFilters,
                  smsDeliverabilityFilters,
                ]}
                searchQuery={searchQuery}
                bulkSelectionActive={
                  bulkSelectionActiveByStage[PipelineStage.APPLIED]
                }
                onChangeBulkSelectionActive={(active) => {
                  setBulkSelectionActiveByStage({
                    ...initialBulkSelectionActive,
                    [PipelineStage.APPLIED]: active,
                  });
                }}
              />
              <Pipeline
                stage={PipelineStage.INTERVIEW_SENT}
                items={[...interviewSentCandidates].sort(
                  (a, b) =>
                    new Date(b.interview!.createdAt!).getTime() -
                    new Date(a.interview!.createdAt!).getTime()
                )}
                Icon={Send}
                searchQuery={searchQuery}
                filters={[
                  resumeFilters,
                  phoneNumberStatusFilters,
                  smsDeliverabilityFilters,
                ]}
              />
              <Pipeline
                stage={PipelineStage.INTERVIEW_COMPLETED}
                items={[...interviewCompletedCandidates].sort(
                  (a, b) =>
                    new Date(b.interview!.completedAt!).getTime() -
                    new Date(a.interview!.completedAt!).getTime()
                )}
                Icon={CalendarCheck}
                filters={[
                  interviewFilters,
                  resumeFilters,
                  phoneNumberStatusFilters,
                  smsDeliverabilityFilters,
                ]}
                searchQuery={searchQuery}
                bulkSelectionActive={
                  bulkSelectionActiveByStage[PipelineStage.INTERVIEW_COMPLETED]
                }
                onChangeBulkSelectionActive={(active) => {
                  setBulkSelectionActiveByStage({
                    ...initialBulkSelectionActive,
                    [PipelineStage.INTERVIEW_COMPLETED]: active,
                  });
                }}
              />
              <Pipeline
                stage={PipelineStage.SHORTLISTED}
                Icon={ListChecks}
                items={[...shortlistedCandidates].sort(
                  (a, b) =>
                    new Date(b.application.updatedAt).getTime() -
                    new Date(a.application.updatedAt).getTime()
                )}
                filters={[
                  interviewFilters,
                  resumeFilters,
                  phoneNumberStatusFilters,
                  smsDeliverabilityFilters,
                ]}
                searchQuery={searchQuery}
                bulkSelectionActive={
                  bulkSelectionActiveByStage[PipelineStage.SHORTLISTED]
                }
                onChangeBulkSelectionActive={(active) => {
                  setBulkSelectionActiveByStage({
                    ...initialBulkSelectionActive,
                    [PipelineStage.SHORTLISTED]: active,
                  });
                }}
              />
              <Pipeline
                stage={PipelineStage.DISQUALIFIED}
                Icon={UserX}
                items={[...disqualifiedCandidates].sort(
                  (a, b) =>
                    new Date(b.interview!.updatedAt).getTime() -
                    new Date(a.interview!.updatedAt).getTime()
                )}
                filters={[disqualifiedFilters]}
                searchQuery={searchQuery}
              />
            </DndContext>
          </div>
        </div>
      </div>
    </>
  );
}
