import { createRoute } from '@tanstack/react-router';
import { Route as adminPanelRoute } from './admin-panel';
import { CustomerActivity } from '@/components/core/admin/customer-activity';

export const Route = createRoute({
  path: '/customer-activity',
  getParentRoute: () => adminPanelRoute,
  component: CustomerActivityPage,
});

function CustomerActivityPage() {
  return <CustomerActivity />;
}
