import AuthenticationPage from '@/pages/authentication/page';
import { SignIn } from '@clerk/clerk-react';
import { ErrorComponent, createRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { Route as RootRoute } from './__root';
import { useOrganizationStore } from '@/fetchers/useOrganization';
import { useEffect } from 'react';

const searchSchema = z.object({
  redirect_url: z.string().optional(),
});

export const Route = createRoute({
  path: '/signin',
  getParentRoute: () => RootRoute,
  component: SignInPage,
  validateSearch: searchSchema,
  errorComponent: ({ error }) => {
    console.log('error @signin', error);
    return <ErrorComponent error={error} />;
  },
});

function SignInPage() {
  const { redirect_url } = Route.useSearch();

  useEffect(() => {
    useOrganizationStore.persist.clearStorage();
  }, []);

  return (
    <AuthenticationPage>
      <SignIn
        path="/signin"
        signUpUrl="/signup"
        redirectUrl={redirect_url || '/organizations'}
        appearance={{
          // layout: {
          //   termsPageUrl: "/terms",
          //   privacyPageUrl: "/privacy",
          // },
          variables: { colorPrimary: 'black' },

          // elements: {
          //   footer: {
          //     display: 'none',
          //   },
          // }
        }}
      />
    </AuthenticationPage>
  );
}
