import { fetcher, fetchPaginatedData } from '@/lib/fetcher';
import {
  convertToCamelCase,
  convertToSnakeCase,
  handleQueryError,
  parseAPIError,
} from '@/lib/utils';
import { useAuth } from '@clerk/clerk-react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { Organization } from './useUserDetails';
import { toast } from 'sonner';
import { useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { Language } from '@/i18n';
import { TimeSaved } from './types';
import {
  AutoSendOption,
  CommunicationMethod,
  InterviewDelay,
  InterviewType,
} from '@/components/core/position/communication/types';
export enum PositionStatus {
  Draft = 1,
  Open = 2,
  Closed = 3,
}

export type TPosition = {
  id: number;
  slug: string;
  status: PositionStatus;
  applicants: TUser[];
  organization: Organization;
  title: string;
  internalId?: string;
  remote: boolean;
  location?: string;
  state?: string;
  city?: string;
  type?: string;
  educationLevel?: string;
  experienceLevel?: string;
  salaryMin: number | null;
  salaryMax: number | null;
  salaryCurrency?: string;
  salaryType?: string;
  tags: string[] | null;
  content?: string;
  createdAt: string;
  organizationSlug?: string;
  applicantCount?: number;
  customFields?: {
    [key: string]: string;
  };
};

enum EOption {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  DISABLED = 'disabled',
}

enum ERequiredOnly {
  REQUIRED = 'required',
}

export type TApplicationForm = {
  name: ERequiredOnly;
  email: ERequiredOnly;
  phone: ERequiredOnly;
  address: EOption;
  linkedinProfile: EOption;
  resume: EOption;
  education: EOption;
  coverLetter: EOption;
  additionalInfo: EOption;
  desiredSalary: EOption;
  position: string;
  applyToAllPositions?: boolean
};
export type TCommunicationForm = {
  language: Language;
  selectedMethod: CommunicationMethod;
  afterApplication: string;
  afterInterview: string;
  position: string;
  autoSendOption: AutoSendOption;
  interviewType: InterviewType;
  interviewDelay: InterviewDelay;
  disqualified: string;
  autoInterview: boolean;
  afterApplicationSubject: string;
  afterInterviewSubject: string;
  disqualifiedSubject: string;
  applyToAllPositions?: boolean
};

export type VoiceModel = 'echo' | 'shimmer' | 'nova' | 'onyx';
// export type ExperienceLevel = 'entry' | 'mid' | 'senior' | 'leadership';
// export type SkillWeight = "Nice to have" | "Important" | "Critical/Must Have"

export type TAIConfigForm = {
  id: number;
  aiVoiceModelId: number;
  voiceModel: VoiceModel;
  additionalQuestions: string[];
  restrictQuestions: boolean;
  organization: number;
  createdAt: string;
  updatedAt: string;
  // interviewTone: InterviewTone;
  // experienceLevelToggle: boolean;
  // candidateExperienceLevel: ExperienceLevel;
  evaluationCriteria: string;
  applyToAllPositions?: boolean
  // skillWeights: {
  //   skill: string;
  //   weight: SkillWeight
  // }[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TUser = any;

export function usePositionQuery({
  enabled = true,
  slug,
}: {
  enabled?: boolean;
  slug?: string;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['position', slug],
    queryFn: async () =>
      fetcher
        .get<TPosition>(`/positions/public/${slug}?preview=false`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => res.data)
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!slug,
  });
}

export function usePositionsQuery({
  enabled = true,
  organizationId,
  slug,
}: {
  enabled?: boolean;
  organizationId?: string;
  slug?: string;
}) {
  const { getToken } = useAuth();
  return useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['positions', organizationId, slug],
    queryFn: async ({ pageParam = 1 }) => {
      const token = await getToken();
      let url = `/positions?organization_id=${organizationId}&slug`;
      if (slug) {
        url = url + `=${slug}`;
      }
      return fetchPaginatedData<TPosition>({
        url,
        token,
        pageParam,
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: !!enabled && !!organizationId,
  });
}

export function usePaginatedPositionsQuery({
  enabled = true,
  organizationId,
  slug,
}: {
  enabled?: boolean;
  organizationId?: string;
  slug?: string;
}) {
  const [allPositions, setAllPositions] = useState<TPosition[]>([]);
  const { data, isFetching, fetchNextPage, hasNextPage, ...rest } =
    usePositionsQuery({
      enabled,
      organizationId,
      slug,
    });

  useEffect(() => {
    if (!data) return;

    setAllPositions((prevPositions) => {
      const newPositions = data.pages.flatMap((page) => page.data);
      const uniquePositions = [
        ...new Map(
          [...prevPositions, ...newPositions].map((item) => [item.slug, item])
        ).values(),
      ];
      return uniquePositions;
    });

    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, hasNextPage, fetchNextPage]);

  return {
    data: allPositions,
    isFetching: hasNextPage || isFetching,
    ...rest,
  };
}

export function useUpdatePositionMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
      slug,
    }: {
      data: Partial<TPosition>;
      organizationId: number;
      slug: string;
    }) => {
      return await fetcher
        .patch(
          `/positions/${slug}`,
          {
            data: convertToSnakeCase(data, { ignore: ['internalId'] }),
            organization_id: organizationId,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return {
            data: convertToCamelCase(res.data) as TPosition,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['positions'],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useClonePositionMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      positionId,
      title,
    }: {
      organizationId: string;
      positionId: number;
      title: string;
    }) => {
      return await fetcher
        .post(
          `/positions/${positionId}/clone?organization_id=${organizationId}`,
          {
            title,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return {
            data: convertToCamelCase(res.data) as TPosition,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['positions'],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useCreatePositionMutation() {
  const queryClient = useQueryClient();

  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
    }: {
      data: Partial<TPosition>;
      organizationId: number;
    }) => {
      const { internalId, ...rest } = data;

      return await fetcher
        .post(
          `/positions/`,
          {
            data: {
              ...convertToSnakeCase(rest),
              internalId,
            },
            organization_id: organizationId,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => ({
          data: convertToCamelCase(r.data) as TPosition,
          error: null,
        }))
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: () => {
      // invalidate
      queryClient.invalidateQueries({
        queryKey: ['positions'],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useApplicationForm({
  enabled = true,
  slug,
}: {
  enabled?: boolean;
  slug?: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['application-form', slug],
    queryFn: async () =>
      fetcher
        .get<TApplicationForm>(`/positions/${slug}/application-form`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const applicationForm = convertToCamelCase(res.data);

          return applicationForm as unknown as TApplicationForm;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!slug,
  });
}

export function useCommunicationForm({
  enabled = true,
  slug,
}: {
  enabled?: boolean;
  slug?: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['communication-form', slug],
    queryFn: async () =>
      fetcher
        .get<TCommunicationForm>(`/positions/${slug}/communication-form`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const applicationForm = convertToCamelCase(res.data);

          return applicationForm as unknown as TCommunicationForm;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!slug,
  });
}

export function useUpdateCommunicationFormMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
      slug,
    }: {
      data: Partial<TCommunicationForm>;
      organizationId: string;
      slug: string;
    }) => {
      return await fetcher
        .patch(
          `/positions/${slug}/communication-form?organization_id=${organizationId}`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const communicationForm = convertToCamelCase(res.data);

          return {
            data: communicationForm as unknown as TCommunicationForm,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries({
        queryKey: ['communication-form', slug],
      });
      queryClient.invalidateQueries({
        queryKey: ['ai-config', slug],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useUpdateApplicationFormMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
      slug,
    }: {
      data: Partial<TApplicationForm>;
      organizationId: string;
      slug: string;
    }) => {
      return await fetcher
        .patch(
          `/positions/${slug}/application-form?organization_id=${organizationId}`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const applicationForm = convertToCamelCase(res.data);

          return {
            data: applicationForm as unknown as TApplicationForm,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries({
        queryKey: ['application-form', slug],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useGetPublicPositions({
  organizationSlug,
}: {
  organizationSlug?: string;
}) {
  return useQuery({
    queryKey: ['positions', organizationSlug],
    queryFn: async () =>
      fetcher
        .get<TPosition[]>(
          `/positions/public?organization_slug=${organizationSlug}`
        )
        .then((res) => {
          const positions = convertToCamelCase(res.data);

          return positions as unknown as TPosition[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!organizationSlug,
  });
}

export function useGetPublicPosition({ slug }: { slug: string }) {
  // @ts-ignore
  const { preview = false } = useSearch({ strict: false });

  return useQuery({
    retry: 0,
    queryKey: ['position', slug, preview],
    queryFn: async () =>
      fetcher
        .get<TPosition & { applicationFrom: TApplicationForm }>(
          `/positions/public/${slug}?preview=${preview}`
        )
        .then((res) => {
          const position = convertToCamelCase(res.data);

          return position as unknown as TPosition & {
            applicationFrom: TApplicationForm;
          };
        })
        .catch((error) => {
          const status = error?.response?.status?.toString();
          if (status === '404') {
            handleQueryError(error);
            return null;
          } else {
            throw new Error(error);
          }
        }),
  });
}

export function useAIConfigForm({
  enabled = true,
  slug,
}: {
  enabled?: boolean;
  slug?: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['ai-config', slug],
    queryFn: async () =>
      fetcher
        .get<TAIConfigForm>(`/positions/${slug}/ai-config`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const aiConfigForm = convertToCamelCase(res.data);

          return aiConfigForm as unknown as TAIConfigForm;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!slug,
  });
}

export function useUpdateAIConfigFormMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
      slug,
    }: {
      data: Partial<TAIConfigForm>;
      organizationId: string;
      slug: string;
    }) => {
      return await fetcher
        .patch(
          `/positions/${slug}/ai-config?organization_id=${organizationId}`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const aiConfigForm = convertToCamelCase(res.data);

          return {
            data: aiConfigForm as unknown as TAIConfigForm,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { slug }) => {
      queryClient.invalidateQueries({
        queryKey: ['ai-config', slug],
      });
    },
  });

  return mutation.mutateAsync;
}

export type HiringMember = {
  id: number;
  user: {
    email: string;
    username: string;
    id: number;
    fullName: string;
    imageUrl: string;
  };
};

// Fetch hiring team members
export function useHiringTeamMembers({
  enabled = true,
  slug,
  organizationId,
}: {
  enabled?: boolean;
  slug?: string;
  organizationId?: string;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['hiring-team-members', slug, organizationId],
    queryFn: async () => {
      const token = await getToken();
      return fetcher
        .get<HiringMember[]>(
          `/positions/${slug}/hiring-team?organization_id=${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as HiringMember[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    enabled: !!enabled && !!slug && !!organizationId,
  });
}

// Add hiring team member
export function useAddHiringTeamMember() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({
      member,
      organizationId,
      slug,
    }: {
      member: string;
      organizationId: string;
      slug: string;
    }) => {
      const token = await getToken();
      return fetcher
        .post(
          `/positions/${slug}/hiring-team?organization_id=${organizationId}`,
          convertToSnakeCase({ member }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return {
            data: convertToCamelCase(res.data) as HiringMember,
            error: null,
          };
        })
        .catch((error) => {
          console.log('error', error);
          toast.error('Failed to add team member');
          return {
            data: null,
            error: 'Something went wrong',
          };
        });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['hiring-team-members', variables?.slug],
      });
    },
  });

  return mutation.mutateAsync;
}

// Remove hiring team member
export function useRemoveHiringTeamMember() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({
      email,
      organizationId,
      slug,
    }: {
      email: string;
      organizationId: string;
      slug: string;
    }) => {
      const token = await getToken();
      return fetcher
        .delete(
          `/positions/${slug}/hiring-team/${email}?organization_id=${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          return {
            data: { success: true },
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Failed to remove team member');
          return {
            data: null,
            error: e?.response?.data?.detail || 'An error occurred',
          };
        });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['hiring-team-members', variables?.slug],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useGetPositionTimeSaved({
  enabled = true,
  positionId,
  organizationId,
}: {
  enabled?: boolean;
  positionId: number;
  organizationId: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['position-time-saved', positionId, organizationId],
    queryFn: async () =>
      fetcher
        .get<TimeSaved>(
          `/positions/${positionId}/human-hours-saved?organization_id=${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as TimeSaved;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!positionId && !!organizationId,
  });
}
