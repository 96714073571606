import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import CreatableSelect from 'react-select/creatable';
import states from 'states-us';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from 'sonner';

import { Input } from '@/components/ui/input';

import { RequiredStar } from '../../common/RequiredStar';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { countries } from '@/constants/countries';
import { Switch } from '@/components/ui/switch';
import {
  TPosition,
  useCreatePositionMutation,
  useUpdatePositionMutation,
} from '@/fetchers/usePosition';
import { useNavigate } from '@tanstack/react-router';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import {
  educationLevelValues,
  experienceLevelValues,
  typeValues,
} from './constants';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { usePositionManageStickyActionBar } from '@/hooks/usePositionManageStickyActionBar';
import { useDescriptionFormStore } from '@/lib/useDescriptionFormStore';
import { SelectSearchInput } from '../../select-search-input';

const displayFormSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  remote: z.boolean().default(true),
  internalId: z.string().optional(),
  type: z.string().default(typeValues[0].value),
  location: z.string().optional().default('Worldwide'),
  state: z.string().optional(),
  city: z.string().optional(),
  educationLevel: z.string().optional(),
  experienceLevel: z.string().optional(),
  salaryMin: z.string().nullable(),
  salaryMax: z.string().nullable(),
  salaryCurrency: z.string().optional().default('USD'),
  salaryType: z.string().optional().default('Yearly'),
  tags: z.array(z.string()).optional(),
});

export type TDetailsForm = z.infer<typeof displayFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<TDetailsForm> = {
  title: '',
  remote: true,
  internalId: '',
  type: 'Full-time',
  location: 'Worldwide',
  state: '',
  city: '',
  educationLevel: '',
  experienceLevel: '',
  salaryMin: '',
  salaryMax: '',
  salaryCurrency: 'USD',
  salaryType: 'Yearly',
  tags: [],
};

export function DetailsForm({ position }: { position?: TPosition }) {
  const createPosition = useCreatePositionMutation();
  const updatePosition = useUpdatePositionMutation();
  const { selectedOrganization } = useUserDetailStore();
  const navigate = useNavigate();
  const [locationSearchQuery, setLocationSearchQuery] = useState('');
  const isEdit = !!position;

  const form = useForm<TDetailsForm>({
    resolver: zodResolver(displayFormSchema),
    defaultValues,
  });
  const {
    getValues,
    reset,
    control,
    handleSubmit,
    resetField,
    watch,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = form;

  const { isValid: isDescriptionFormValid } = useDescriptionFormStore();

  useEffect(() => {
    const sub = watch(({ salaryMin, salaryMax }) => {
      if (
        (salaryMin !== '0' && !salaryMin) ||
        (salaryMax !== '0' && !salaryMax)
      ) {
        return;
      }
      if (Number(salaryMax) < Number(salaryMin)) {
        setError('salaryMax', {
          message:
            'Maximum salary cannot be lower than or equal to minimum salary.',
        });
      } else {
        clearErrors('salaryMax');
      }
    });

    return () => {
      sub.unsubscribe();
    };
  });

  useEffect(() => {
    if (position) {
      reset({
        ...position,
        salaryMin:
          position.salaryMin !== null && position.salaryMin >= 0
            ? position.salaryMin.toString()
            : '',
        salaryMax:
          position.salaryMax !== null && position.salaryMax >= 0
            ? position.salaryMax.toString()
            : '',
        tags: position.tags ?? [],
      });
    }
  }, [position, reset]);

  const currentLocationValue = getValues().location;

  const stateOptions = states.map((state) => ({
    value: state.abbreviation,
    label: state.name,
  }));

  async function onSubmit(payload: TDetailsForm) {
    if (!selectedOrganization?.id) return;

    let response;
    if (isEdit) {
      response = await updatePosition({
        data: {
          ...payload,
          salaryMin: payload.salaryMin
            ? Math.floor(Number(payload.salaryMin))
            : null,
          salaryMax: payload.salaryMax
            ? Math.floor(Number(payload.salaryMax))
            : null,
        },
        organizationId: selectedOrganization.id,
        slug: position.slug,
      });
    } else {
      response = await createPosition({
        data: {
          ...payload,
          salaryMin: payload.salaryMin
            ? Math.floor(Number(payload.salaryMin))
            : undefined,
          salaryMax: payload.salaryMax
            ? Math.floor(Number(payload.salaryMax))
            : undefined,
        },
        organizationId: selectedOrganization.id,
      });
    }

    const { data, error } = response;

    if (data) {
      toast.success(`Position ${isEdit ? 'updated' : 'created'} successfully!`);
      if (!isEdit) {
        navigate({
          to: '/organizations/$organizationId/positions/manage/$slug/$step',
          params: {
            organizationId: String(selectedOrganization.id),
            // @ts-ignore
            slug: data?.slug,
            step: 'description',
          },
          search: {
            creating: true,
          },
          ignoreBlocker: true,
        });
      }
    } else {
      toast.error('Something went wrong. Please try again.', {
        description: error,
      });
    }
  }

  const { StickyActionBar } = usePositionManageStickyActionBar({
    isFormDirty: isDirty,
    position,
    onSave: handleSubmit(onSubmit),
    isPublishDisabled: !position?.content && !isDescriptionFormValid,
    publishDisabledReason:
      'Please complete the position description to publish',
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty && isEdit,
    onSave: handleSubmit(onSubmit),
  });
  return (
    <>
      {UnsavedChangesAlertModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex space-x-4">
            <FormField
              control={control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel aria-required>
                    Position Title
                    <RequiredStar />
                  </FormLabel>
                  <FormDescription></FormDescription>
                  <FormControl>
                    <Input placeholder="Job Title" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Position Type</FormLabel>
                <FormDescription>
                  The type of position you are hiring for.
                </FormDescription>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select the position type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {typeValues.map(({ value, label }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="remote"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Remote available</FormLabel>
                  <FormDescription>
                    Select if you accept remote applications
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Location</FormLabel>
                <FormDescription>The location of the job</FormDescription>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);

                    resetField('city');
                    resetField('state');
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select the position location" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectSearchInput
                      value={locationSearchQuery}
                      onChange={setLocationSearchQuery}
                      placeholder="Search location"
                    />
                    <SelectItem value="Worldwide">🌎 Worldwide</SelectItem>
                    <SelectItem value="Hybrid">Hybrid</SelectItem>
                    {countries
                      .filter((country) =>
                        country?.name
                          .toLowerCase()
                          .includes(locationSearchQuery.toLowerCase())
                      )
                      .map((country) => (
                        <SelectItem
                          key={country?.code}
                          value={`${country?.flag} ${country?.name}`}
                        >
                          {country.flag} {country?.name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>

                <FormMessage />
              </FormItem>
            )}
          />

          {currentLocationValue !== 'Worldwide' &&
            currentLocationValue !== 'Hybrid' && (
              <div className="flex w-full gap-4">
                {currentLocationValue === '🇺🇸 United States' && (
                  <FormField
                    control={control}
                    name="state"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>State</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a state" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {stateOptions.map(({ value, label }) => (
                              <SelectItem key={value} value={value}>
                                {label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <FormField
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter city" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}

          <FormField
            control={control}
            name="educationLevel"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Education Level</FormLabel>
                <FormDescription>
                  The minimum education level required for the job.
                </FormDescription>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select the education level" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {educationLevelValues.map(({ value, label }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="experienceLevel"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Experience Level</FormLabel>
                <FormDescription>
                  The minimum experience required for the job.
                </FormDescription>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select experience level" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {experienceLevelValues.map(({ value, label }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-col items-center md:flex-row">
            <div className="mr-4 flex items-start justify-center">
              <FormField
                control={control}
                name="salaryMin"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Minimum Salary</FormLabel>
                    <FormControl>
                      <Input
                        value={field.value ?? ''}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value === '') {
                            return field.onChange('');
                          }
                          if (isNaN(Number(value))) return;
                          field.onChange(value);
                        }}
                        placeholder="60000"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className="text-bold mt-7 self-center px-2">-</p>
              <FormField
                control={control}
                name="salaryMax"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Maximum Salary</FormLabel>
                    <FormControl>
                      <Input
                        value={field.value ?? ''}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value === '') {
                            return field.onChange('');
                          }
                          if (isNaN(Number(value))) return;
                          field.onChange(value);
                        }}
                        placeholder="80000"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div
              className={cn(
                'mt-2 flex w-full gap-4 md:mt-8 md:w-fit md:items-center',
                (errors.salaryMax || errors.salaryMin) && 'md:mt-1'
              )}
            >
              <FormField
                control={control}
                name="salaryType"
                render={({ field }) => (
                  <FormItem>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select experience level" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={'Yearly'}>Yearly</SelectItem>
                        <SelectItem value={'Monthly'}>Monthly</SelectItem>
                        <SelectItem value={'Hourly'}>Hourly</SelectItem>
                      </SelectContent>
                    </Select>
                    {/* <FormDescription>Description</FormDescription> */}
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name="salaryCurrency"
                render={({ field }) => (
                  <FormItem>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select experience level" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {/* @ts-ignore */}
                        {Intl?.supportedValuesOf('currency')?.map(
                          //@ts-ignore
                          (currency) => (
                            <SelectItem key={currency} value={currency}>
                              {currency}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <FormField
            control={control}
            name="internalId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Internal ID</FormLabel>
                <FormDescription>
                  Optional ID to keep track of this field in your ATS or other
                  internal systems
                </FormDescription>
                <FormControl>
                  <Input {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          {/* handle tags */}
          <FormField
            control={control}
            name="tags"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Tags</FormLabel>
                <FormDescription>
                  Add tags to help categorize your job post.
                </FormDescription>
                <FormControl className="remove-input-txt-border">
                  <CreatableSelect
                    isClearable
                    isMulti
                    value={
                      field.value?.map((tag) => ({ value: tag, label: tag })) ||
                      []
                    }
                    options={
                      field.value?.map((tag) => ({ value: tag, label: tag })) ||
                      []
                    }
                    onChange={(tags) => {
                      field.onChange(tags.map((tag) => tag.value));
                    }}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          {StickyActionBar}
        </form>
      </Form>
    </>
  );
}
