import breezy_big from '@/assets/images/ats/big/breezy_big.png';
import greenhouse_big from '@/assets/images/ats/big/greenhouse_big.png';
import jazzhr_big from '@/assets/images/ats/big/jazzhr_big.png';
import jobdiva_big from '@/assets/images/ats/big/jobdiva_big.svg';
import lever_big from '@/assets/images/ats/big/lever_big.png';
import workable_big from '@/assets/images/ats/big/workable_big.png';
import recruiterflow_big from '@/assets/images/ats/big/recruiterflow_big.png';
import zoho_big from '@/assets/images/ats/big/zoho_big.svg';
import manatal_big from '@/assets/images/ats/big/manatal_big.webp';
import crelate_big from '@/assets/images/ats/big/crelate_big.svg';
import jobadder_big from '@/assets/images/ats/big/jobadder_big.svg';
import loxo_big from '@/assets/images/ats/big/loxo_big.png';

import crelate_small from '@/assets/images/ats/small/crelate_small.jpeg';
import breezy_small from '@/assets/images/ats/small/breezy_small.jpg';
import greenhouse_small from '@/assets/images/ats/small/greenhouse_small.jpeg';
import jazzhr_small from '@/assets/images/ats/small/jazzhr_small.jpg';
import lever_small from '@/assets/images/ats/small/lever_small.jpg';
import workable_small from '@/assets/images/ats/small/workable_small.jpg';
import recruiterflow_small from '@/assets/images/ats/small/recruiterflow_small.png';
import zoho_small from '@/assets/images/ats/small/zoho_small.webp';
import ceipal_small from '@/assets/images/ats/small/ceipal_small.jpeg';
import manatal_small from '@/assets/images/ats/small/manatal_small.jpeg';
import jobdiva_small from '@/assets/images/ats/small/jobdiva_small.jpeg';
import jobadder_small from '@/assets/images/ats/small/jobadder_small.jpeg';
import loxo_small from '@/assets/images/ats/small/loxo_small.jpeg';
import ashby_small from '@/assets/images/ats/small/ashby_small.jpeg';
import {
  ATSConnection,
  ATSConnectionAuthMethod,
  ATSConnectionId,
} from '@/components/core/ats/types';

export const ATS_CONNECTIONS: ATSConnection[] = [
  {
    id: ATSConnectionId.WORKABLE,
    name: 'Workable ATS',
    authMethod: ATSConnectionAuthMethod.SUBDOMAIN_API_KEY,
    active: true,
    imageUrl: workable_small,
    bigImageUrl: workable_big,
  },
  {
    id: ATSConnectionId.ZOHO_RECRUIT,
    name: 'Zoho Recruit ATS',
    authMethod: ATSConnectionAuthMethod.OAUTH2,
    active: true,
    imageUrl: zoho_small,
    bigImageUrl: zoho_big,
  },
  {
    id: ATSConnectionId.BREEZY,
    name: 'Breezy ATS',
    authMethod: ATSConnectionAuthMethod.USERNAME_PASSWORD,
    active: true,
    imageUrl: breezy_small,
    bigImageUrl: breezy_big,
  },
  {
    id: ATSConnectionId.RECRUITER_FLOW,
    name: 'RecruiterFlow ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: true,
    imageUrl: recruiterflow_small,
    bigImageUrl: recruiterflow_big,
  },
  {
    id: ATSConnectionId.MANATAL,
    name: 'Manatal ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: true,
    imageUrl: manatal_small,
    bigImageUrl: manatal_big,
  },
  {
    id: ATSConnectionId.CRELATE,
    name: 'Crelate ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: true,
    imageUrl: crelate_small,
    bigImageUrl: crelate_big,
  },

  {
    id: ATSConnectionId.JOBADDER,
    name: 'JobAdder ATS',
    authMethod: ATSConnectionAuthMethod.OAUTH2,
    active: true,
    imageUrl: jobadder_small,
    bigImageUrl: jobadder_big,
  },
  {
    id: ATSConnectionId.CEIPAL,
    name: 'Ceipal ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: false,
    imageUrl: ceipal_small,
    bigImageUrl: '',
  },
  // {
  //   id: ATSConnectionId.JAZZ_HR,
  //   name: 'JazzHR ATS',
  //   authMethod: ATSConnectionAuthMethod.API_KEY,
  //   active: false,
  //   imageUrl: jazzhr_small,
  //   bigImageUrl: jazzhr_big,
  // },

  {
    id: ATSConnectionId.GREENHOUSE,
    name: 'Greenhouse ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: true,
    imageUrl: greenhouse_small,
    bigImageUrl: greenhouse_big,
  },
  {
    id: ATSConnectionId.JOBDIVA,
    name: 'JobDiva ATS',
    authMethod: ATSConnectionAuthMethod.EMAIL_PASSWORD_API_KEY,
    active: true,
    imageUrl: jobdiva_small,
    bigImageUrl: jobdiva_big,
  },
  {
    id: ATSConnectionId.TEMPWORKS,
    name: 'TempWorks ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: false,
    imageUrl: '',
    bigImageUrl: '',
  },
  {
    id: ATSConnectionId.LEVER,
    name: 'Lever ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: false,
    imageUrl: lever_small,
    bigImageUrl: lever_big,
  },
  {
    id: ATSConnectionId.JAZZ_HR,
    name: 'JobDiva ATS',
    authMethod: ATSConnectionAuthMethod.EMAIL_PASSWORD_API_KEY,
    active: false,
    imageUrl: jazzhr_small,
    bigImageUrl: jazzhr_big,
  },
  {
    id: ATSConnectionId.LOXO,
    name: 'Loxo ATS',
    authMethod: ATSConnectionAuthMethod.EMAIL_PASSWORD_API_KEY,
    active: true,
    imageUrl: loxo_small,
    bigImageUrl: loxo_big,
  },
  {
    id: ATSConnectionId.ASHBY,
    name: 'Ashby ATS',
    authMethod: ATSConnectionAuthMethod.API_KEY,
    active: false,
    imageUrl: ashby_small,
    bigImageUrl: ashby_small,
  },
];
