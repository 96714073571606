import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { fetcher } from "@/lib/fetcher";
import { Organization, useUserDetailStore } from "./useUserDetails";
import {
  convertToCamelCase,
  convertToSnakeCase,
  handleQueryError,
} from "@/lib/utils";
import { useParams } from "@tanstack/react-router";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CONNECTIONS } from "@/components/core/position/details/constants";

// enum plan
// FREE = "free"
// BASIC = "basic"
// STANDARD = "standard"
// PREMIUM = "premium"
// BASIC_ANNUAL = "basic_annual"
// STANDARD_ANNUAL = "standard_annual"
// PREMIUM_ANNUAL = "premium_annual"
// DEMO = "demo"
// ADMIN = "admin"

export enum Plan {
  FREE = "free",
  BASIC = "basic",
  STANDARD = "standard",
  PREMIUM = "premium",
  BASIC_ANNUAL = "basic_annual",
  STANDARD_ANNUAL = "standard_annual",
  PREMIUM_ANNUAL = "premium_annual",
  DEMO = "demo",
  ADMIN = "admin",
}

export const ATS_CONNECTION_ALLOWED_PLANS = [
  Plan.STANDARD,
  Plan.PREMIUM,
  Plan.BASIC_ANNUAL,
  Plan.STANDARD_ANNUAL,
  Plan.PREMIUM_ANNUAL,
  Plan.DEMO,
  Plan.ADMIN,
];

export type ParentOrganization = {
  id: number;
  name: string;
  logoUrl: string;
  memberId: number;
  memberRole: string;
  webUrl: string;
  plan: Plan;
};

export type TeamMember = {
  id: number;
  email: string;
  role: string;
  username: string;
  fullName?: string;
  imageUrl?: string;
};

export function isAtsAvailableForPlan(plan: Plan) {
  return ATS_CONNECTION_ALLOWED_PLANS.includes(plan);
}

export function useUpdateOrganization() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { setSelectedOrganization } = useUserDetailStore();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      parentOrganizationId,
      payload,
    }: {
      organizationId: number;
      parentOrganizationId: number;
      payload: {
        name: string;
        logo?: File;
        color?: string;
        companyDescription?: string;
        slug?: string;
        companyReachOutEmail: string;
      };
    }) => {
      const formData = new FormData();
      const { logo, ...rest } = payload;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const snakePayload: any = convertToSnakeCase(rest);

      if (logo) {
        formData.append("logo", logo);
      }
      Object.keys(snakePayload).forEach((key) => {
        formData.append(key, snakePayload[key]);
      });

      return await fetcher
        .post<Organization>(
          `/organizations/${organizationId}?parent_organization_id=${parentOrganizationId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(r.data) as unknown as Organization;
          setSelectedOrganization(data);
          queryClient.invalidateQueries({
            queryKey: ["user-details"],
          });
          queryClient.invalidateQueries({
            queryKey: ["admin", "organizations"],
          });

          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useGetPublicOrganization({
  organizationName,
  organizationSlug,
}: {
  organizationName?: string;
  organizationSlug?: string;
}) {
  return useQuery({
    retry: 0,
    queryKey: ["public-organization", organizationName ?? organizationSlug],
    queryFn: async () => {
      let url = `/organizations?`;

      if (organizationName) {
        url += `organization_name=${organizationName}`;
      } else if (organizationSlug) {
        url += `organization_slug=${organizationSlug}`;
      }

      return fetcher
        .get<Organization>(url)
        .then((res) => {
          const organization = convertToCamelCase(res.data);

          return organization as unknown as Organization;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },

    enabled: !!organizationName || !!organizationSlug,
  });
}

export function useGetOrganizations({ enabled = true }: { enabled?: boolean }) {
  const { getToken } = useAuth();
  // @ts-ignore
  const { organizationId } = useParams({ strict: false });
  // const { data: details } = useUserDetails();
  const { setSelectedOrganization } = useUserDetailStore();

  const { selectedParentOrganization, setSelectedParentOrganization } =
    useOrganizationStore();

  return useQuery({
    queryKey: ["admin", "organizations", selectedParentOrganization?.memberId],
    queryFn: async () => {
      return fetcher
        .get<Organization[]>(
          `/admin/organizations?membership_id=${selectedParentOrganization?.memberId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const organizations = res.data
            .map((organization) => convertToCamelCase(organization))
            .map((o) => ({
              ...o,
              integrationName: CONNECTIONS?.find(
                (c) => c.id === o?.integrationName
              )?.name,
            })) as unknown as Organization[];

          if (organizationId) {
            const selectedOrganization = organizations.find(
              (org) => org.id === Number(organizationId)
            );
            selectedOrganization &&
              setSelectedOrganization(selectedOrganization);
          }

          return organizations;
        })
        .catch((error) => {
          setSelectedParentOrganization(undefined);
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled && !!selectedParentOrganization?.memberId,
  });
}

export function useGetMemberships({ enabled = true }: { enabled?: boolean }) {
  const { getToken } = useAuth();
  const { selectedParentOrganization, setSelectedParentOrganization } =
    useOrganizationStore();

  return useQuery({
    queryKey: ["parent", "memberships"],
    queryFn: async () => {
      return fetcher
        .get<ParentOrganization[]>(`/parent/organizations/memberships`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const organizations = res.data.map((organization) =>
            convertToCamelCase(organization)
          ) as unknown as ParentOrganization[];

          if (!selectedParentOrganization) {
            setSelectedParentOrganization(organizations?.[0]);
          } else {
            const selected = organizations.find(
              (org) => org.id === selectedParentOrganization?.id
            );

            if (selected) {
              setSelectedParentOrganization(selected);
            }
          }

          return organizations;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled,
  });
}

export function useGetTeamList() {
  const { getToken } = useAuth();
  const { selectedParentOrganization } = useOrganizationStore();

  return useQuery({
    queryKey: ["parent", "team-list", selectedParentOrganization?.id],
    queryFn: async () => {
      return fetcher
        .get<TeamMember[]>(
          `/parent/organizations/${selectedParentOrganization?.id}/memberships`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const organizations = res.data.map((organization) =>
            convertToCamelCase(organization)
          ) as unknown as TeamMember[];

          return organizations;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: !!selectedParentOrganization?.id,
  });
}

export function useInviteNewTeamMember() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { selectedParentOrganization } = useOrganizationStore();

  const mutation = useMutation({
    mutationFn: async ({
      email,
      role,
      message,
    }: {
      email: string;
      role: string;
      message?: string;
    }) => {
      return fetcher
        .post<TeamMember>(
          `/parent/organizations/${selectedParentOrganization?.id}/memberships`,
          {
            email: email?.toLowerCase(),
            role,
            message,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const member = convertToCamelCase(res.data) as unknown as TeamMember;

          queryClient.invalidateQueries({
            queryKey: ["parent", "team-list", selectedParentOrganization?.id],
          });

          return member;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useToggleTeamMemberRole() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { selectedParentOrganization } = useOrganizationStore();

  const mutation = useMutation({
    mutationFn: async ({
      memberId,
      role,
      remove,
    }: {
      memberId: number;
      role?: string;
      remove?: boolean;
    }) => {
      return fetcher
        .patch<TeamMember>(
          `/parent/organizations/${selectedParentOrganization?.id}/memberships/${memberId}`,
          {
            role,
            remove,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const member = convertToCamelCase(res.data) as unknown as TeamMember;

          queryClient.invalidateQueries({
            queryKey: ["parent", "team-list", selectedParentOrganization?.id],
          });

          return member;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useCreateParentOrganization() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      name,
      logo,
      webUrl,
    }: {
      name: string;
      logo?: File;
      webUrl?: string;
    }) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("web_url", webUrl || "");
      if (logo) {
        formData.append("logo", logo);
      }

      return fetcher
        .post<ParentOrganization>(`/parent/organizations/`, formData, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const organization = convertToCamelCase(
            res.data
          ) as unknown as ParentOrganization;

          // setSelectedParentOrganization(organization);

          queryClient.invalidateQueries({
            queryKey: ["parent", "memberships"],
          });

          return organization;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useUpdateParentOrganization() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { selectedParentOrganization } = useOrganizationStore();
  const mutation = useMutation({
    mutationFn: async ({
      name,
      logo,
      webUrl,
    }: {
      name: string;
      logo?: File;
      webUrl?: string;
    }) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("web_url", webUrl || "");

      if (logo) {
        formData.append("logo", logo);
      }

      return fetcher
        .post<ParentOrganization>(
          `/parent/organizations/${selectedParentOrganization?.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          const organization = convertToCamelCase(
            res.data
          ) as unknown as ParentOrganization;

          queryClient.invalidateQueries({
            queryKey: ["parent", "memberships"],
          });

          queryClient.invalidateQueries({
            queryKey: [
              "admin",
              "organizations",
              selectedParentOrganization?.memberId,
            ],
          });

          return organization;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export const useOrganizationStore = create<{
  selectedParentOrganization?: ParentOrganization;
  setSelectedParentOrganization: (
    parentOrganization?: ParentOrganization
  ) => void;
}>()(
  persist(
    (set) => ({
      selectedParentOrganization: undefined,
      setSelectedParentOrganization: (
        parentOrganization?: ParentOrganization
      ) => set({ selectedParentOrganization: parentOrganization }),
    }),
    {
      name: "organization-store",
    }
  )
);

export function useGetMergeToken({
  organizationId,
}: {
  organizationId: number;
}) {
  const { getToken } = useAuth();

  return useQuery({
    retry: 1,
    queryKey: ["merge-token", organizationId],
    queryFn: async () => {
      const url = `/admin/organizations/${organizationId}/create-link-token`;

      return fetcher
        .get<{ token: string; integration_name: string }>(url, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const organization = convertToCamelCase(res.data);

          console.log("useGetMergeToken RES", res?.data, organization);

          return organization as unknown as {
            token: string;
            integrationName: string;
          };
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },

    enabled: !!organizationId,
  });
}

export function useUpdateMergeToken() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      mergePublicToken,
      integrationName,
    }: {
      organizationId: number;
      mergePublicToken?: string;
      integrationName?: string;
    }) => {
      return await fetcher
        .post<Organization>(
          `/admin/organizations/${organizationId}/merge-connect`,
          {
            token: mergePublicToken,
            integration_name: integrationName,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(r.data) as unknown as Organization;
          // queryKey: ["admin", "organizations", selectedParentOrganization?.memberId],
          queryClient.invalidateQueries({
            queryKey: ["admin", "organizations"],
          });
          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

type IntegrationResponse = { message: string; integrationId: number };

export function useATSConnect() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      credentials,
      organizationId,
      authMethod,
      integrationType,
    }: {
      credentials: Record<string, string>;
      organizationId: number;
      authMethod: string;
      integrationType: string;
    }) => {
      return await fetcher
        .post<IntegrationResponse>(
          `/ats/connect`,
          {
            organization_id: organizationId,
            integration_type: integrationType,
            auth_method: authMethod,
            credentials: credentials,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(
            r.data
          ) as unknown as IntegrationResponse;
          // queryKey: ["admin", "organizations", selectedParentOrganization?.memberId],
          queryClient.invalidateQueries({
            queryKey: ["admin", "organizations"],
          });
          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useATSDisconnect() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      integrationType,
    }: {
      organizationId?: number;
      integrationType?: string;
    }) => {
      if (!organizationId || !integrationType) {
        throw new Error("Invalid organizationId or integrationType");
      }
      return await fetcher
        .delete(
          `/ats/disconnect/${integrationType}?organization_id=${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(
            r.data
          ) as unknown as IntegrationResponse;
          // queryKey: ["admin", "organizations", selectedParentOrganization?.memberId],
          queryClient.invalidateQueries({
            queryKey: ["admin", "organizations"],
          });
          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

type TBilling = {
  interviewTotal: number;
  applicationTotal: number;
  portalUrl?: string;
  currentPlan?: string;
  amount: number;
  isMonthly: boolean;
  maxInterviewAllowed: number;
  nextRenewalDate: string;
  atsIntegration: boolean;
  standing: "headsup" | "warning" | "critical" | "normal";
  planStatus?: string;
};
export function useGetBilling({ enabled = true }: { enabled?: boolean }) {
  const { getToken } = useAuth();
  const { selectedParentOrganization } = useOrganizationStore();

  return useQuery({
    queryKey: ["parent", "billing", selectedParentOrganization?.id],
    queryFn: async () => {
      return fetcher
        .get<TBilling>(
          `/parent/organizations/${selectedParentOrganization?.id}/billing`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as unknown as TBilling;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: !!selectedParentOrganization?.id && enabled,
  });
}

export type TAdminDashboard = {
  id: number;
  applicationId: number;
  completedAt: string;
  organizationName: string;
  reminderCount: number;
  positionTitle: string;
  finalVerdict: string;
  applicantName: string;
  applicantEmail: string;
  organizationId: number;
  slug: string;
};

export function useGetAdminDashboard({
  enabled = true,
}: {
  enabled?: boolean;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["admin-panel", "dashboard"],
    queryFn: async () => {
      return fetcher
        .get<TAdminDashboard>(`/admin/organizations/dashboard`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          return convertToCamelCase(res.data) as unknown as TAdminDashboard[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled,
  });
}

export function useGetAdminDashboardOrganization({
  enabled = true,
  organizationId: organizationId,
}: {
  enabled?: boolean;
  organizationId: number;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["admin-panel", "dashboard", organizationId],
    queryFn: async () => {
      return fetcher
        .get<TAdminDashboard>(
          `/admin/organizations/dashboard/${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as unknown as TAdminDashboard[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled && !!organizationId,
  });
}

type TAdminDashboardOrganization = {
  id: number;
  name: string;
  plan: Plan;
  completedInterviewCount: number;
  stripeCustomerId?: string;
};
export function useAdminGetAllParentOrganizations({
  enabled = true,
}: {
  enabled?: boolean;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["admin-panel", "dashboard", "parent_organizations"],
    queryFn: async () => {
      return fetcher
        .get<TAdminDashboardOrganization>(
          `/admin/organizations/dashboard/parent_organizations`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(
            res.data
          ) as unknown as TAdminDashboardOrganization[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled,
  });
}

export function useAdminTeamMemberUpdate() {
  const { getToken } = useAuth();
  const { setSelectedParentOrganization } = useOrganizationStore();

  const mutation = useMutation({
    mutationFn: async ({
      parentOrganizationId,
      action,
    }: {
      parentOrganizationId: number;
      action?: string;
    }) => {
      return fetcher
        .patch(
          `/admin/organizations/dashboard/parent/${parentOrganizationId}/team`,
          {
            action,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          setSelectedParentOrganization();
          window.location.reload();

          return res.data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useAdminParentOrganizationPlanUpdate() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      parentOrganizationId,
      plan,
      stripeCustomerId,
    }: {
      parentOrganizationId: number;
      stripeCustomerId?: string;
      plan?: string;
    }) => {
      return fetcher
        .patch(
          `/admin/organizations/dashboard/parent/${parentOrganizationId}/plan`,
          {
            plan,
            stripe_customer_id: stripeCustomerId,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          queryClient.invalidateQueries({
            queryKey: ["admin-panel", "dashboard"],
          });

          return res.data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}
