export enum DateFilter {
  All = 'all',
  Last7Days = 'last7days',
  Last30Days = 'last30days',
  Last90Days = 'last90days',
}

export enum DataType {
  Applicants = 'applicants',
  InterviewsSent = 'sentInterviews',
  InterviewsCompleted = 'completedInterviews',
}

export enum ChartType {
  Area = 'area',
  Bar = 'bar',
}

export enum IntervalFilter {
  None = '',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}
