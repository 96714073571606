import { Loader } from '@/components/core/loader';
import { createFileRoute, ErrorComponent, Link, Outlet, useParams } from '@tanstack/react-router'
import { AccountFormView } from '@/components/core/position/account'
import { AppearanceFormView } from '@/components/core/position/appearance'
import DisplayFormView from '@/components/core/position/display';
import { NotificationsFormView } from '@/components/core/position/notifications';
import { ProfileFormView } from '@/components/core/position';
import { DetailsFormView } from '@/components/core/position/details';
import DescriptionFormView from '@/components/core/position/description';
import ApplicationFormView from '@/components/core/position/application';
import CommunicationFormView from '@/components/core/position/communication';
import PreviewFormView from '@/components/core/position/preview';
import PositionCreationLayout from '@/components/core/position/layout'
import { TPosition, usePaginatedPositionsQuery } from '@/fetchers/usePosition';
import { useMemo } from 'react';
import { Applications } from '@/components/core/applicatiions';
import AIConfigFormView from '@/components/core/position/ai-config';
import { Button } from '@/components/ui/button';
import HiringTeamFormView from '@/components/core/position/hiring-team';



export const Route = createFileRoute('/organizations/$organizationId/positions/manage/$slug/$step')({
  errorComponent: ({ error }) => {
    console.log("error @positions page", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  },
  component: NewPositionView,
})


function NewPositionView() {

  // @ts-ignore
  const { step, slug, organizationId, id } = useParams({
    strict: false
  })
  // const { selectedOrganization } = useUserDetailStore()

  const { data: positions, isFetching: isFetchingPositions } = usePaginatedPositionsQuery({
    organizationId: Number(organizationId),
    enabled: !!organizationId,
    slug
  })

  const position = positions?.[0]

  const positionWithoutEmptyValues = useMemo(() => {
    let pos = Object.keys(position || {}).reduce((acc, key) => {
      // @ts-ignore
      if (position[key] !== undefined && position[key] !== null) {
        // @ts-ignore
        acc[key] = position[key]
      }
      return acc
    }, {}) as TPosition | undefined

    if (!pos?.slug) {
      pos = undefined
    }

    return pos
  }, [position])

  if (!organizationId || (!positions && isFetchingPositions)) {
    return (
      <Loader />
    )
  }


  if (step === "applications") {
    return id ? <Outlet /> : <Applications position={position} />
  }

  return (
    <PositionCreationLayout
      title="Update Position"
      subtitle='Manage your position update process and application settings.'
      organizationId={Number(organizationId)} slug={slug}
      position={position}
      CustomActions={[
        <Link
          to="/organizations/$organizationId/positions/manage/$slug/$step"
          params={{
            step: "applications",
          }}>
          <Button variant='outline' >Applications</Button>
        </Link>
      ]}
    >
      {step === "details" && <DetailsFormView position={positionWithoutEmptyValues} />}
      {step === "description" && <DescriptionFormView position={positionWithoutEmptyValues} />}
      {step === "application" && <ApplicationFormView position={positionWithoutEmptyValues} />}
      {step === "communication" && <CommunicationFormView position={positionWithoutEmptyValues} />}
      {step === "ai-config" && <AIConfigFormView position={positionWithoutEmptyValues} />}
      {step === "hiring-team" && <HiringTeamFormView position={positionWithoutEmptyValues} />}
      {step === "preview" && <PreviewFormView position={positionWithoutEmptyValues} />}
      {step === "profile" && <ProfileFormView />}
      {step === "account" && <AccountFormView />}
      {step === "appearance" && <AppearanceFormView />}
      {step === "display" && <DisplayFormView />}
      {step === "notifications" && <NotificationsFormView />}
    </PositionCreationLayout>

  )
}