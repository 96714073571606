import { useRef, useEffect, useState } from 'react';
import Cropper from 'cropperjs';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import 'cropperjs/dist/cropper.css';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PhotoIcon } from '@heroicons/react/24/solid';

type ImageCropperProps = {
  onChange: (file: File) => void;
  aspectRatio?: number;
  value?: string | File;
  layoutClasses?: string;
};

export const ImageCropper = ({
  onChange,
  aspectRatio = 1,
  value,
  layoutClasses = '',
}: ImageCropperProps) => {
  const [open, setOpen] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [imageUrl, setImageUrl] = useState(value);

  useEffect(() => {
    return () => {
      cropper?.destroy();
    };
  }, [cropper]);

  const initializeCropper = () => {
    if (!imageRef.current) return;
    cropper?.destroy();
    setCropper(
      new Cropper(imageRef.current, {
        aspectRatio: aspectRatio,
        viewMode: 1,
        autoCropArea: 1,
      })
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      setOpen(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && imageRef.current) {
          imageRef.current.src = e.target.result as string;
          initializeCropper();
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (cropper && fileInputRef.current && fileInputRef.current.files) {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const fileName =
          fileInputRef?.current?.files?.[0]?.name || 'cropped-image.png';
        const fileExt = fileName.slice(fileName.lastIndexOf('.'));
        const newFileName = `cropped${fileExt}`;
        if (blob) {
          const file = new File([blob], newFileName, { type: 'image/png' });
          onChange(file);
          setImageUrl(URL.createObjectURL(file));
          setOpen(false);
        }
      }, 'image/png');
    }
  };

  return (
    <>
      <div className={cn('group relative w-full', layoutClasses)}>
        {imageUrl && typeof imageUrl === 'string' && (
          <div className="absolute inset-0 z-0 h-full w-full overflow-hidden">
            <img
              className="z-0 h-full w-full rounded object-cover"
              src={imageUrl}
            />
          </div>
        )}

        <div className="relative flex h-full w-full items-center justify-center rounded-lg border border-dashed border-gray-900/25">
          <div className="flex flex-col items-center justify-center">
            <PhotoIcon
              className={cn(
                'mx-auto size-16',
                imageUrl ? 'hidden' : 'text-gray-300'
              )}
            />
            <Button
              className={cn(imageUrl && 'opacity-0 group-hover:opacity-100')}
              variant={imageUrl ? 'default' : 'outline'}
              onClick={(event) => {
                event.preventDefault();
                fileInputRef?.current?.click?.();
              }}
            >
              {imageUrl ? 'Change' : 'Upload'}
            </Button>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Image</DialogTitle>
              <DialogDescription>Crop your image as desired</DialogDescription>
            </DialogHeader>
            <div>
              <img
                ref={imageRef}
                alt="Please pick an image to crop"
                style={{ maxWidth: '100%' }}
              />
            </div>
            <DialogFooter>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button onClick={handleSave}>Save</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ImageCropper;
