import { Loader } from '@/components/core/loader';
import { CONNECTIONS } from '@/components/core/position/details/constants';
// import { ParentOgranizationCreationView } from '@/components/core/parent-organization';
import { Sources } from '@/components/core/sources';
import { useATSConnect, useGetMemberships, useGetOrganizations } from '@/fetchers/useOrganization';

import { useUserDetails, useUserDetailStore } from '@/fetchers/useUserDetails';
import AuthenticationPage from '@/pages/authentication/page';
import DashboardPage from '@/pages/dashboard/page';
import { createFileRoute, ErrorComponent, Outlet, redirect, useNavigate, useParams } from '@tanstack/react-router'
import { useEffect, useRef } from 'react';
import 'regenerator-runtime/runtime'
import { toast } from 'sonner';
import { z } from "zod";


const integrationStateSchema = z.object({
  state: z.union([z.string(), z.object({
    organizationId: z.string().optional(),
    integrationType: z.string().optional(),
    authMethod: z.string().optional(),
    redirectUri: z.string().optional(),
  })]).optional(),
  location: z.string().optional(),
  code: z.string().optional(),
})



export const Route = createFileRoute('/organizations')({
  beforeLoad: ({ context, location }) => {
    const isSignedIn = context?.auth?.isSignedIn;
    if (!isSignedIn) {
      throw redirect({
        to: '/signin',
        search: {
          redirect_url: location.href,
        },
      })
    }
  },
  validateSearch: integrationStateSchema,
  component: OrganizationsComponent,

  errorComponent: ({ error }) => {
    console.log("error @organizations", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  }
})

function OrganizationsComponent() {
  useUserDetails()
  const navigate = useNavigate()
  // @ts-ignore
  const { organizationId } = useParams({ strict: false })
  const { state: oauthState, location, code } = Route.useSearch()

  // const { isFetching } = useUserDetails()
  const { data: userDetails, isFetching: isFetchingUserDetails } = useUserDetails()
  const { data: memberships, isFetching: isFetchingMemberships } = useGetMemberships({ enabled: !isFetchingUserDetails && !!userDetails?.id })

  const { data: organizations, isFetching } = useGetOrganizations({
    enabled: !isFetchingMemberships
  })
  const { selectedOrganization } = useUserDetailStore()
  const atsConnect = useATSConnect()
  const connecting = useRef(false)

  useEffect(() => {
    if (connecting.current) return
    if (!oauthState || !code || !location) return
    try {
      // @ts-ignore
      const { organizationId, integrationType, authMethod, redirectUri } = oauthState;

      const redirectUrl = new URL(redirectUri);
      // const clientId = redirectUrl.searchParams.get('one') || '';
      // const clientSecret = redirectUrl.searchParams.get('two') || '';

      console.log('location', location)
      let domain = "com"
      if (location === "eu") domain = "eu"
      if (location === "cn") domain = "com.cn"
      if (location === "jp") domain = "jp"
      if (location === "au") domain = "com.au"
      if (location === "in") domain = "in"


      connecting.current = true
      atsConnect({
        authMethod,
        integrationType,
        organizationId: Number(organizationId),
        credentials: {
          auth_code: code,
          domain,
          redirect_uri: redirectUrl.origin + redirectUrl.pathname, // Remove the query parameters
          // client_id: clientId,
          // client_secret: clientSecret,
        },
      }).then(() => {
        console.log("ATS Connected")
        const integrationName = CONNECTIONS.find((connection) => connection.id === integrationType)?.name
        toast.success(`${integrationName} Connected Successfully`)
      }).finally(() => {
        connecting.current = false
      })
    } catch {
      console.log("error parsing state")
    }
  }, [oauthState, location, code])

  useEffect(() => {
    if (!navigate || isFetching) return

    if (selectedOrganization?.id && !organizationId) {
      navigate({
        to: `/organizations/$organizationId/positions/list`,
        params: {
          organizationId: `${selectedOrganization.id}`
        }
      })
    }
  }, [organizationId, navigate, isFetching, selectedOrganization])


  useEffect(() => {
    if (isFetchingMemberships) return
    if (!memberships || memberships.length === 0) {
      const script = document.createElement('script');
      script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [memberships, isFetchingMemberships]);

  if (isFetching || isFetchingUserDetails || isFetchingMemberships) {
    return <Loader />
  }


  if (!memberships || memberships.length === 0) {
    return (
      // <ParentOgranizationCreationView />
      <AuthenticationPage whiteBg>
        <div >
          <h1 className="text-3xl font-bold text-center">Book a demo to get started </h1>

        </div>
        <div className="meetings-iframe-container w-full" data-src="https://meetings.hubspot.com/peter1234?embed=true"></div>
      </AuthenticationPage>
    )
  }

  if (!organizationId) {
    return (
      <DashboardPage>
        <Sources sources={organizations || []} />
      </DashboardPage>
    )
  }

  return (
    <DashboardPage>
      <Outlet />
    </DashboardPage>
  )
}